<template>
  <v-app>
    <AsideMenu
      v-if="$route.name != 'login' && $route.name != 'chat_page' && $route.name != 'signup_page'"
    ></AsideMenu>
    <AppHeader
      v-if="$route.name != 'login' && $route.name != 'chat_page' && $route.name != 'signup_page'"
    ></AppHeader>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style scoped></style>
<script>
import AppHeader from "./layout/header.vue";
import AsideMenu from "./layout/AsideMenu.vue";

export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    AppHeader,
    AsideMenu,
  },
};
</script>
