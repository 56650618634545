<template>
  <div>
    <v-card outlined class="mb-10">
      <v-card-title>{{ keys.project_file_page_products_title }}</v-card-title>
      <!--/ card title -->
      <v-card-text v-for="(product, i) in products" :key="i">
        <v-tabs class="mb-3" v-model="product.tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="product.tabs"
            v-for="(lang, s) in  $store.getters.languages"
            :key="s"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="product.tabs">
          <v-tab-item
            v-for="(lang, s) in $store.getters.languages"
            :key="s"
          >
            <v-row
              :class="[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' },
              ]"
              class="align-center"
            >
              <v-col cols="12" class="text-end mt-3" v-if="product.tabs == 0">
                <v-btn
                  @click="
                    $emit('delete_item', {
                      type: 'products',
                      item_index: i,
                      id: product.id,
                    })
                  "
                  fab
                  x-small
                  color="error"
                  depressed
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-col>
              <!--/ col (delete)-->

              <v-col cols="6" sm="4" v-if="product.tabs == 0">
                <v-select
                  :error="product.errors.company && product.tabs == 0"
                  :append-icon="
                    product.errors.company && product.tabs == 0
                      ? 'error_outline'
                      : ''
                  "
                  item-text="name_locale"
                  item-value="id"
                  :items="sales_companies"
                  :disabled="sales_companies.length == 0"
                  :loading="sales_companies.length == 0"
                  v-model="product.product_company_id"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.project_file_page_select_company }}
                  </template>
                  <template slot="prepend">
                    <v-btn
                      @click="dialog_company = true"
                      x-small
                      fab
                      depressed
                      color="primary"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                </v-select>
              </v-col>
              <!--/ select company (col)-->

              <v-col cols="6" sm="4">
                <v-text-field
                  :error="product.errors.product_name && product.tabs == 0"
                  :append-icon="
                    product.errors.product_name && product.tabs == 0
                      ? 'error_outline'
                      : ''
                  "
                  @keyup="
                    $emit('products_inputs', {
                      value: $event.target.value,
                      type: 'name',
                      locale: lang.locale,
                      index: i,
                    })
                  "
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                >
                  <template slot="label">
                    <strong class="red--text" v-if="product.tabs == 0"
                      >*</strong
                    >
                    {{ keys.project_file_page_product_name }}
                  </template>
                </v-text-field>
              </v-col>
              <!--/ product name-->

              <v-col cols="6" sm="4" v-if="product.tabs == 0">
                <v-text-field
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  @click="
                    $emit('attach', {
                      comp: 'products',
                      index: i,
                      prop: 'images',
                    })
                  "
                  :value="`${product.images.length}${keys.project_file_page_files_count}`"
                >
                  <template slot="prepend">
                    <v-btn
                      @click="
                        $emit('attach', {
                          comp: 'products',
                          index: i,
                          prop: 'images',
                        })
                      "
                      x-small
                      fab
                      depressed
                      color="primary"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <!--/ product image (col)-->

              <v-col cols="6" sm="4" v-if="product.tabs == 0">
                <v-text-field
                  :error="product.errors.date && product.tabs == 0"
                  :append-icon="
                    product.errors.date && product.tabs == 0
                      ? 'error_outline'
                      : ''
                  "
                  @click="dialog_date(i)"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :value="product.date"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.project_file_page_product_date }}
                  </template>
                </v-text-field>
              </v-col>
              <!--/ date (col)-->

              <v-col cols="6" sm="4" v-if="product.tabs == 0">
                <v-text-field
                  :error="product.errors.barcode && product.tabs == 0"
                  :append-icon="
                    product.errors.barcode && product.tabs == 0
                      ? 'error_outline'
                      : ''
                  "
                  @keyup="
                    $emit('products_inputs', {
                      value: $event.target.value,
                      type: 'barcode',
                      index: i,
                    })
                  "
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.project_file_page_product_barcode }}
                  </template>
                </v-text-field>
              </v-col>
              <!--/ product barcode-->

              <v-col cols="6" sm="4">
                <v-textarea
                  :error="product.errors.description && product.tabs == 0"
                  :append-icon="
                    product.errors.description && product.tabs == 0
                      ? 'error_outline'
                      : ''
                  "
                  @keyup="
                    $emit('products_inputs', {
                      value: $event.target.value,
                      type: 'description',
                      locale: lang.locale,
                      index: i,
                    })
                  "
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  rows="1"
                >
                  <template slot="label">
                    <strong class="red--text" v-if="product.tabs == 0"
                      >*</strong
                    >
                    {{ keys.project_file_page_product_details }}
                  </template>
                </v-textarea>
              </v-col>
              <!--/ product notes (col) -->
            </v-row>
            <!--/ row -->
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <!--/ card text -->
      <v-card-actions class="d-block text-end pa-8">
        <v-btn
          @click="$emit('new_item', { type: 'products' })"
          color="primary"
          outlined
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-actions>
      <!--/ card actions -->
    </v-card>
    <!--/ v card -->

    <!-- start  dialog date  -->
    <v-dialog v-model="modal" persistent width="290px">
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="save_date()"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
    <!--/ end dialog date  -->
  </div>
</template>

<script>
export default {
  props: ["keys", "products"],
  name: "products",
  data() {
    return {
      modal: false,
      product_index: -1,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {
    sales_companies() {
      return this.$store.state.pages_data.sales_companies || [];
    },
  },
  methods: {
    // sales companies
    get_sales_companies() {
      this.$store.dispatch("public__request", {
        config: {
          method: "get",
          url: "company/productCompany",
        },
        get: "sales_companies",
      });
    },
    // dialog date
    dialog_date(i) {
      this.modal = true;
      this.product_index = i;
    },
    save_date() {
      this.$emit("products_inputs", {
        value: this.date,
        type: "date",
        index: this.product_index,
      });
      this.$nextTick(() => {
        this.modal = false;
      });
    },
  },
  mounted() {
    this.get_sales_companies();
  },
};
</script>
