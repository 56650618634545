<template>
  <div>
    <v-dialog persistent max-width="500" :value="dialog">
      <v-card>
        <v-card-title>
          {{
            project.id
              ? keys.external_project_page_dialog_edit_title
              : keys.external_project_page_dialog_title
          }}
        </v-card-title>
        <!--/title -->
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-tabs class="mb-3" v-model="tabs" background-color="gray">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab
                v-model="tabs"
                v-for="(lang, i) in this.$store.getters.languages"
                :key="i"
              >
                {{ lang.native }}
              </v-tab>
            </v-tabs>
            <!--/tabs -->

            <v-tabs-items v-model="tabs">
              <v-tab-item
                v-for="(lang, i) in this.$store.getters.languages"
                :key="i"
              >
                <v-text-field
                  :rules="[(v) => !!v || 'مطلوب']"
                  v-model="project.name[lang.locale]"
                >
                  <template slot="label">
                    <strong v-if="tabs == 0" class="red--text">*</strong>
                    {{ keys.external_project_page_name }}
                  </template>
                </v-text-field>
                <!--/ project name -->
              </v-tab-item>
            </v-tabs-items>

            <v-switch v-model="project.is_active" :label="project.is_active ? keys.external_project_page_status_active : keys.external_project_page_status_inactive "></v-switch>
            <!--/ project status -->
          </v-form>
          <!--/ form -->
        </v-card-text>
        <!--/ card text -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            @click="save()"
            :disabled="save_load"
            :loading="save_load"
            small
            color="primary"
            >
            {{keys.external_project_page_dialog_save}}</v-btn
          >
          <v-btn @click="$emit('close_dialog')" small color="error" text
            >{{keys.external_project_page_dialog_close}}</v-btn
          >
        </v-card-actions>
        <!--/card actions -->
      </v-card>
      <!--/card -->
    </v-dialog>
    <!--/ dialog -->
  </div>
</template>
<script>
export default {
  props: ["project", "dialog", "save_load", "keys"],
  name: "Form",
  data() {
    return {
      tabs: 0,
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("save");
      }
    },
  },
};
</script>
