<template>
  <v-container>
    <Table
      :keys="keys"
      @edit_project="edit_project($event)"
      ref="table"
      :tbody="tbody"
      @open_dialog="dialog = true"
    ></Table>
    <!--/ table -->
    <Form
      :keys="keys"
      @save="save()"
      @close_dialog="close()"
      :dialog="dialog"
      :project="project"
      :save_load="save_load"
      ref="form"
    ></Form>
    <!--/ form -->
  </v-container>
  <!--/ container -->
</template>
<!--/ table -->

<script>
import Table from "@/components/external_project/table";
import Form from "@/components/external_project/form";
export default {
  name: "externalProject",
  data() {
    return {
      tbody: [],
      dialog: false,
      save_load: false,
      project: {
        is_active: true,
        name: {},
      },
    };
  },
  components: {
    Table,
    Form,
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.save_load = false;
      this.project = {
        is_active: true,
        name: {},
      };
      this.$refs.form.$refs.form.resetValidation();
    },
    edit_project(item) {
      this.project = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      this.save_load = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.project.id
              ? `company/externalProjects/${this.project.id}`
              : "company/externalProjects",
            method: this.project.id ? "put" : "post",
          },
          data: this.project,
        })
        .then((res) => {
          this.close();
          this.$refs.table.get_projects();
        });
    },
  },
};
</script>
