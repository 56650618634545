<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col cols="6" v-if="tabs == 0">
          <v-text-field :rules="rules.name" v-model="user.name">
            <template slot="label">
              <strong class="error--text">*</strong>
              الاسم بالكامل
            </template>
          </v-text-field>
        </v-col>
        <!--/col  name -->
        <v-col cols="6">
          <v-text-field
            :rules="tabs == 0 ? rules.job : []"
            v-model="user.job.ar"
          >
            <template slot="label">
              <strong v-if="tabs == 0" class="error--text">*</strong>
              الوظيفة
            </template>
          </v-text-field>
        </v-col>
        <!--/col  name -->

        <v-col cols="12" sm="6" v-if="tabs == 0">
          <v-text-field
            @keypress="email_exist = false"
            :rules="rules.email"
            v-model="user.email"
          >
            <template slot="label">
              <strong class="error--text">*</strong>
              البريد الالكتروني
            </template>
          </v-text-field>
        </v-col>
        <!--/col  email    -->

        <v-col cols="12" sm="6" v-if="tabs == 0">
          <v-text-field :rules="rules.country" v-model="user.country">
            <template slot="label">
              <strong class="error--text">*</strong>
              البلد
            </template>
          </v-text-field>
        </v-col>
        <!--/col  country    -->

        <v-col cols="12" sm="6" v-if="tabs == 0">
          <v-text-field :rules="rules.id_number" v-model="user.id_number">
            <template slot="label">
              <strong class="error--text">*</strong>
              رقم الهوية
            </template>
          </v-text-field>
        </v-col>
        <!--/col identifier -->

        <v-col cols="12" sm="6" v-if="tabs == 0">
          <v-text-field
            @keypress="phone_exist = false"
            :rules="rules.phone"
            v-model="user.phone"
          >
            <template slot="label">
              <strong class="error--text">*</strong>
              رقم الهاتف
            </template>
          </v-text-field>
        </v-col>
        <!--/col  phone  -->

        <v-col cols="12" sm="6" v-if="tabs == 0">
          <v-text-field
            :rules="rules.password"
            v-model="user.password"
            type="password"
          >
            <template slot="label">
              <strong class="error--text">*</strong>
              كلمة المرور
            </template>
          </v-text-field>
        </v-col>
        <!--/col  password -->
        <v-col cols="12" sm="6" v-if="tabs == 0">
          <v-text-field
            :rules="rules.c_password"
            v-model="user.confirm_pass"
            type="password"
          >
            <template slot="label">
              <strong class="error--text">*</strong>
              تآكيد كلمة المرور
            </template>
          </v-text-field>
        </v-col>
        <!--/col confirm password    -->
        <v-col>
          <v-btn
            x-large
            depressed
            :disabled="save_loading"
            :loading="save_loading"
            @click="signup()"
            color="primary"
            width="200"
            class="text-capitalize"
          >
            تسجيل</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar width="auto" right v-model="snackbar">
      כל הכבוד, נרשמת בהצלחה!
      <template v-slot:action="{ attrs }">
        <v-icon color="success" v-bind="attrs">done</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "userForm",
  data() {
    return {
      tabs: 0,
      save_loading: false,
      snackbar: false,
      email_exist: false,
      phone_exist: false,
      rules: {
        name: [
          (v) => !!v || "حقل مطلوب",
          (v) =>
            /^[آ-یA-z]{2,}( [آ-یA-z]{2,})+([آ-یA-z]|[ ]?)$/.test(v) ||
            "يجب ان يكون الاسم الاول و الثاني علي الاقل",
        ],
        job: [(v) => !!v || "حقل مطلوب"],
        country: [(v) => !!v || "حقل مطلوب"],
        id_number: [(v) => !!v || "حقل مطلوب"],
        email: [
          (v) => !!v || "مطلوب",
          (v) => /.+@.+\..+/.test(v) || "صيغة الاميل غير صحيحة",
          (v) => !this.email_problem || "موجود مسبقا",
        ],
        phone: [
          (v) => !!v || "حقل مطلوب",
          (v) => !this.phone_problem || "موجود مسبقا",
        ],
        password: [
          (v) => !!v || "حقل مطلوب",
          (v) =>
            !(v.toString().length < 8) ||
            "يجب ان يكون 8 حروف او ارقام علي الاقل",
        ],
        c_password: [(v) => !!this.not_mutch || "غير متطابقة"],
      },
      user: {
        name: "",
        email: "",
        password: "",
        phone: "",
        country: "",
        id_number: "",
        confirm_pass: "",
        job: {
          ar:""
        },
      },
    };
  },

  computed: {
    email_problem() {
      return this.email_exist;
    },
    phone_problem() {
      return this.phone_exist;
    },
    not_mutch() {
      return this.user.confirm_pass == this.user.password;
    },
  },
  methods: {
    signup() {
      if (this.$refs.form.validate()) {
        this.save_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "company/auth/register",
              method: "post",
            },
            data: this.user,
          })
          .then((res) => {
            let vm = this;
            this.snackbar = true;
            this.save_loading = false;
            setTimeout(() => {
              this.$emit("reg_done");
            }, 1000);
          })
          .catch((err) => {
            console.log(err.response);
            this.save_loading = false;
            if (err.response.data.errors.email) {
              this.email_exist = true;
            }
            if (err.response.data.errors.phone) {
              this.phone_exist = true;
            }
            this.$refs.form.validate();
          });
      }
    },
  },
};
</script>
