<template>
  <div class="chat">
    <v-container :class="container_padding" class="py-0">
      <v-card class="mt-3" elevation="1">
        <div class="d-flex">
          <contactList v-if="contacts_l"></contactList>
          <messages
            :files="files"
            @toggle_files_menu="files = !files"
          ></messages>
          <files @draw_emit="draw_emit($event)" v-if="files"></files>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import files from "@/components/chat/files.vue";
import messages from "@/components/chat/messages.vue";
import contactList from "@/components/chat/contact_list.vue";
export default {
  name: "chat",
  data() {
    return {
      file_draw: null,
      files: false,
      contacts_l: false,
      container_padding: "",
    };
  },
  components: {
    files,
    messages,
    contactList,
  },
  computed: {},
  methods: {
    draw_emit(ev) {
      this.files = ev;
    },
    window_changed() {
      if (window.innerWidth < 992) {
        this.container_padding = "px-0";
        this.contacts_l = false;
      } else {
        this.container_padding = "px-4";
        this.contacts_l = true;
      }
    },
  },
  created() {
    if (window.innerWidth < 992) {
      this.container_padding = "px-0";
      this.contacts_l = false;
    } else {
      this.container_padding = "px-4";
      this.contacts_l = true;
    }
    window.addEventListener("resize", this.window_changed);
  },
  destroyed() {
    window.removeEventListener("resize", this.window_changed);
  },
};
</script>

<style lang="scss" scoped>
.chat {
  position: relative;
  width: 100%;
  &:before {
    content: "";
    left: 0;
    top: -13px;
    width: 100%;
    height: 140px;
    background-color: #00a884;
    position: absolute;
  }
  .container {
    max-width: 1400px;
  }
}
</style>
