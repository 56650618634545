<template>
  <div>
    <v-skeleton-loader type="table" v-if="!data_loaded"> </v-skeleton-loader>

    <v-col cols="6" sm="4" v-if="data_loaded">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="date"
            persistent-hint
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          
          no-title
          type="month"
          @input="menu = false,date_changed($event)"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-card outlined v-if="data_loaded">
      <v-card-title class="d-flex">
        <span class="order-1"> {{ keys.external_project_page_title }}</span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.external_project_page_search"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>
        <v-btn
          @click="$emit('open_dialog')"
          depressed
          color="primary"
          class="order-3 order-sm-5"
        >
          {{ keys.external_project_page_add_btn }}</v-btn
        >
      </v-card-title>
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="tbody"
        :search="search"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
        </template>

        <template v-slot:[`item.is_active`]="{ item }">
          <v-chip
            x-small
            :color="item.is_active ? 'primary' : 'red lighten-2'"
            class="px-2 white--text"
          >
            {{
              item.is_active
                ? keys.external_project_page_status_active
                : keys.external_project_page_status_inactive
            }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="$emit('edit_project', item)" icon small>
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn icon small @click="get_delete_id(item.id)">
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!--/ table -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block">
          {{ keys.external_project_page_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_project()"
            depressed
            color="error"
          >
            {{ keys.external_project_page_delete_btn }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.external_project_page_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </div>
</template>

<script>
export default {
  name: "Table",
  props: ["keys"],
  data() {
    return {
      tbody: [],
      delete_id: null,
      delete_dialog: false,
      delete_loader: false,
      data_loaded: false,
      search: "",
      menu: false,
      date: new Date().toISOString().substr(0, 7),
    };
  },
  computed: {
    headers() {
      return [
        {
          align: "center",
          value: "created_at",
          text: this.keys.external_project_page_date,
        },
        {
          align: "center",
          value: "name_locale",
          text: this.keys.external_project_page_name,
        },
        {
          align: "center",
          value: "is_active",
          text: this.keys.external_project_page_status,
        },
        {
          align: "center",
          value: "total",
          text: this.keys.external_project_page_total,
        },
        {
          align: "center",
          value: "actions",
          text: this.keys.external_project_page_actions,
        },
      ];
    },
  },

  methods: {
    get_projects(date = "") {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/externalProjects?date=${date}`,
            method: "get",
          },
          data:{
            data: date
          }
        })
        .then((res) => {
          console.log(res);
          this.tbody = res.data;
          this.data_loaded = true;
        });
    },
    get_delete_id(id) {
      this.delete_dialog = true;
      this.delete_id = id;
    },
    delete_project() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/externalProjects/${this.delete_id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.delete_dialog = false;
          this.delete_loader = false;
          this.get_projects();
        });
    },

    date_changed(ev) {
      let full_date = `${ev}-01`;
      this.get_projects(full_date); 
    },
  },
  mounted() {
    this.get_projects();
  },
};
</script>
