import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";

import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";
// import the es6 version
import "zingchart/es6";
// import the component AFTER ZingChart since it is a DIRECT dependency
import zingchartVue from "zingchart-vue";
import "@/styles/main.scss";
Vue.component("zingchart", zingchartVue);
Vue.use(DatetimePicker);
Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

axios.defaults.baseURL = "https://app.kalko.cloud/api/v1";
axios.defaults.headers.post["Content-Type"] = "application/json";



new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
