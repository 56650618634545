<template>
  <div>
    <v-card elevation="0" outlined class="mb-5">
      <v-card-title class="border">
        {{ keys.project_page_details_genral_title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>

        <v-tabs
          @change="$emit('basic_info_tabs', $event)"
          class="mb-3"
          :value="tabs"
          background-color="gray"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab :value="tabs" v-for="(lang, la) in languages" :key="la">
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items :value="tabs">
          <v-tab-item v-for="(lang, la) in languages" :key="la">
            <v-row
              class="pt-4"
              :dir="lang.direction"
              :class="[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' },
              ]"
            >
              <v-col md="3" cols="6">
                <v-text-field
                  :prepend-icon="
                    errors.name_err && tabs == 0 ? 'error_outline' : ''
                  "
                  :error="errors.name_err && tabs == 0"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :value="name[lang.locale]"
                  @keyup="text_inputs($event.target.value, 'name', lang.locale)"
                >
                  <template slot="label">
                    <strong v-if="tabs == 0" class="red--text">*</strong>
                    {{ keys.project_page_details_project_name }}
                  </template>
                </v-text-field>
              </v-col>
              <!--/ col project name -->

              <v-col md="3" cols="6">
                <v-text-field
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :value="title[lang.locale]"
                  @keyup="
                    text_inputs($event.target.value, 'title', lang.locale)
                  "
                  :label="keys.project_page_details_project_title"
                ></v-text-field>
              </v-col>

              <!--/ col project title   -->

              <v-col v-if="tabs == 0" md="3" cols="6">
                <v-select
                  :prepend-icon="
                    errors.type_err && tabs == 0 ? 'error_outline' : ''
                  "
                  :error="errors.type_err && tabs == 0"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :items="[28, 2, 3]"
                  :value="project_type_id"
                  @input="$emit('project_id', $event)"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.project_page_details_project_type }}
                  </template>
                </v-select>
              </v-col>

              <!--/ col project type   -->

              <v-col v-if="tabs == 0" md="3" cols="12">
                <v-dialog
                  ref="dialog"
                  v-model="inputDate.modal"
                  :return-value.sync="inputDate.date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :prepend-icon="
                        errors.data_err && tabs == 0
                          ? 'error_outline'
                          : 'mdi-calendar'
                      "
                      :error="errors.data_err && tabs == 0"
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                      v-model="start_date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template slot="label">
                        <strong class="red--text">*</strong>
                        {{ keys.project_page_details_project_date }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="inputDate.date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="inputDate.modal = false"
                    >
                      {{ keys.project_page_date_close }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog[0].save(inputDate.date),
                          $emit('start_date', inputDate.date)
                      "
                    >
                      {{ keys.project_page_date_save }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <!--/ col start date    -->
              <v-col md="3" cols="12">
                <v-text-field
                  :value="description[lang.locale]"
                  @keyup="
                    text_inputs($event.target.value, 'description', lang.locale)
                  "
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :label="keys.project_page_details_project_note"
                ></v-text-field>
              </v-col>
              <!--/ col description  -->
              <!-- <v-col v-if="tabs == 0" cols="12" class="d-flex justify-end">
                <v-switch
                  v-model="swtichInp"
                  :label="switchLable"
                  color="primary"
                  hide-details
                  @change="$emit('switchInp')"
                ></v-switch>
              </v-col> -->
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <!--/ card -->
  </div>
</template>

<script>
export default {
  name: "basicInfo",
  props: [
    "name",
    "title",
    "description",
    "project_type_id",
    "start_date",
    "keys",
    "errors",
    "tabs",
  ],
  data() {
    return {
      pro_id: null,
      swtichInp: true,
      items: ["hassan", "ahmed", "taher", "saied", "asdas"],
      inputDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        modal: false,
      },
    };
  },
  computed: {
    languages() {
      return this.$store.getters.languages;
    },
    switchLable() {
      return this.swtichInp ? "الفحوصات متاحة" : "الفحصوصات مغلفة";
    },
    start_date_prop() {
      return start_date;
    },
  },
  methods: {
    text_inputs(value, input_name, locale) {
      this.$emit("update_text", {
        input_name: input_name,
        value: value,
        locale: locale,
      });
    },
  },
};
</script>
