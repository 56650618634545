<template>
  <div>
    <v-container>
      <v-card outlined elevation="0">
        <v-card-title>
          <v-text-field
            hide-details
            prepend-icon="mdi-magnify"
            single-line
            placeholder="بحث"
          ></v-text-field>
        </v-card-title>

        <v-card-title class="font-weight-bold"> البوم </v-card-title>
        <v-card-text>
          <v-row>
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              class="rounded-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-col md="3" cols="6" v-for="(v, i) in gallery" :key="i">
                  <v-card outlined elevation="0" v-bind="attrs" v-on="on">
                    <img
                      src="https://cdn.vuetifyjs.com/images/cards/house.jpg"
                      style="width: 100%"
                    />
                    <v-card-text>
                      <h4 class="text-h6">وصف الصورة</h4>

                      <p class="mb-0">50:20 . 2020</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
              <v-card class="rounded-0">
                <v-card-title>
                  <v-btn large icon @click="dialog = false">
                    <v-icon large>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-carousel  hide-delimiters height="auto" class="carousel" v-model="model">
                  <v-carousel-item v-for="(imgs, s) in gallery" :key="s">
                    <v-sheet height="100%" tile>
                      <v-container fill-height>
                        <v-layout align-center>
                          <v-flex>
                          <v-img class="d-block" :src="imgs"> </v-img>
                           <v-card-text class='px-0'>
                              <h2 class="h2-text mb-3">اسم الصورة</h2>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Quasi at, itaque voluptas
                                nulla totam quam id dolore quis deserunt
                                corporis enim ex non animi, eaque explicabo
                                neque perspiciatis voluptatum nam.
                              </p>
                            </v-card-text>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<style lang="scss" scope>
.v-carousel__controls {
  background-color: white !important;
  .theme--dark.v-btn.v-btn--icon {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.carousel {
  width: calc(100% - 30px) !important;
  margin: auto;
  @media (min-width: 992px) {
    width: 50% !important;
    margin: auto;
  }
  .v-window__prev, .v-window__next {
    top:calc(50% - 100px)
  }
}
</style>
<script>
export default {
  name: "alboum",
  data() {
    return {
      dialog: false,
      model: 0,
      gallery: [
        "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        "https://cdn.vuetifyjs.com/images/cards/house.jpg",
      ],
      items: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    };
  },
  computed: {
    carouselImg() {
      return this.gallery;
    },
  },
};
</script>
