<template>
  <div class="messages" :class="{ files_active: files && !mobile}">
    <header class="messages__header d-flex align-center justify-space-between">
      <div clasS="d-flex align-end" @click="$emit('toggle_files_menu')">
        <v-avatar size="40">
          <img src="https://placeimg.com/100/100/tech/any?1" />
        </v-avatar>

        <div>
          <h5 class="ms-2">Company name</h5>
          <p class="caption messages__online ms-2">Online</p>
        </div>
      </div>
      <!--/ div -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group v-model="model">
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title class="body-2">{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </header>
    <!--/ header -->
    <div class="messages__wrapper">
      <section v-scroll.self="onScroll" class="overflow-y-auto box">
        <div class="mt-3">
          <div class="box__date mb-3"><span>20/20/2020</span></div>
          <div class="message">
            <div class="mb-5 float-right">
              <v-sheet rounded color="#fff" elevation="1">
                <div class="message__text">
                  <span class="message__arrow">
                    <svg viewBox="0 0 8 13" width="8" height="13" class="">
                      <path
                        opacity=".13"
                        d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                      ></path>
                    </svg>
                  </span>

                  <p class="d-flex align-end">
                    hello how are u

                    <span class="caption message__time font-weight-medium ms-4"
                      >9:12 AM</span
                    >
                  </p>
                </div>
              </v-sheet>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="message message--own">
            <div class="mb-5 float-left" dir="ltr">
              <v-sheet rounded color="#d9fdd3" elevation="1">
                <div class="message__text">
                  <span class="message__arrow">
                    <svg viewBox="0 0 8 13" width="8" height="13" class="">
                      <path
                        opacity=".13"
                        fill="#0000000"
                        d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                      ></path>
                    </svg>
                  </span>

                  <p class="d-flex align-end">
                    <v-icon color="#667781" small>done_all</v-icon>
                    <span
                      class="caption message__time font-weight-medium me-2 ms-4"
                      >9:12 AM</span
                    >

                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Optio vero vitae labore, dolores cumque rem nesciunt natus
                    qui doloremque, laborum, non ducimus nam aliquid quis ex!
                    Hic veniam iure consequatur!
                  </p>
                </div>
              </v-sheet>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="message message--own">
            <div class="mb-5 float-left" dir="ltr">
              <v-sheet rounded color="#d9fdd3" elevation="1">
                <div class="message__text">
                  <span class="message__arrow">
                    <svg viewBox="0 0 8 13" width="8" height="13" class="">
                      <path
                        opacity=".13"
                        fill="#0000000"
                        d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                      ></path>
                    </svg>
                  </span>

                  <p class="d-flex align-end">
                    <v-icon color="#667781" small>done_all</v-icon>
                    <span
                      class="caption message__time font-weight-medium me-2 ms-4"
                      >9:12 AM</span
                    >

                    hassan ahemed
                  </p>
                </div>
              </v-sheet>
            </div>
            <div class="clearfix"></div>
          </div>

          <div v-for="a in 10" :key="a" class="message">
            <div class="mb-5 float-right">
              <v-sheet rounded color="#fff" elevation="1">
                <div class="message__text">
                  <span class="message__arrow">
                    <svg viewBox="0 0 8 13" width="8" height="13" class="">
                      <path
                        opacity=".13"
                        d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                      ></path>
                    </svg>
                  </span>
                  <div class="message__username">
                    <h4 class="font-weight-bold blue--text">Taher Ahmed</h4>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                      class="menu"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          class="pa-0 menu__btn"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item-group v-model="model">
                          <v-list-item
                            v-for="(item, index) in items"
                            :key="index"
                          >
                            <v-list-item-title class="body-2">{{
                              item
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>
                  <p class="d-flex align-end">
                    hello how are u

                    <span class="caption message__time font-weight-medium ms-4"
                      >9:12 AM</span
                    >
                  </p>
                </div>
              </v-sheet>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="message">
            <div class="mb-5 float-right">
              <v-sheet rounded color="#fff" elevation="1">
                <div class="message__text">
                  <span class="message__arrow">
                    <svg viewBox="0 0 8 13" width="8" height="13" class="">
                      <path
                        opacity=".13"
                        d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                      ></path>
                      <path
                        fill="currentColor"
                        d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                      ></path>
                    </svg>
                  </span>
                  <div class="message__username">
                    <h4 class="font-weight-bold blue--text">Taher Ahmed</h4>
                    <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                      class="menu"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          class="pa-0 menu__btn"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item-group v-model="model">
                          <v-list-item
                            v-for="(item, index) in items"
                            :key="index"
                          >
                            <v-list-item-title class="body-2">{{
                              item
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>

                  <v-alert
                    border="left"
                    colored-border
                    color="red"
                    elevation="0"
                    dense
                    class="message__reply mt-2"
                  >
                    <h5 class="red--text">Mohamed</h5>
                    Aliquam eu nunc. Fusce commodo aliquam arcu. In consectetuer
                  </v-alert>
                  <p class="d-flex align-end">
                    hello how are u

                    <span class="caption message__time font-weight-medium ms-4"
                      >9:12 AM</span
                    >
                  </p>
                </div>
              </v-sheet>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </section>
      <!--/ section -->

      <div class="send">
        <v-alert
          border="left"
          colored-border
          color="green"
          elevation="0"
          dense
          class="send__reply mt-2"
        >
          <h4 class="green--text">Mohamed</h4>
          Aliquam eu nunc. Fusce commodo aliquam arcu. In consectetuer
        </v-alert>

        <div class="d-flex align-center">
          <div>
            <v-btn color="#54656f" icon>
              <v-icon>emoji_emotions</v-icon>
            </v-btn>
            <v-btn color="#54656f" icon>
              <v-icon>attach_file</v-icon>
            </v-btn>
          </div>
          <div
            @focus="placeholder = ''"
            @blur="placeholder = placeholder_text"
            :data-placeholder="placeholder"
            contenteditable="true"
            class="send__box ms-2"
          ></div>
          <v-btn class="send__record ms-2" icon>
            <v-icon color="#54656f">mic</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "messages",
  props: ["files"],
  data() {
    return {
      model: null,
      items: ["الخروج من الجروب", "الغاء الدردشة", "حذف الدردشة"],
      placeholder_text: "ارسال رسالة",
      placeholder: "ارسال رسالة",
      scrollInvoked: 0,
      mobile: false,
    };
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
  },
  created() {
    let vm = this;
    if (innerWidth < 992) {
      vm.mobile = true;
    } else {
      vm.mobile = false;
    }
    window.onresize = function () {
      if (innerWidth < 992) {
        vm.mobile = true;
      } else {
        vm.mobile = false;
      }
    };
  },
};
</script>

<style scoped lang="scss">
.clearfix {
  clear: both;
}
.messages {
  // transition: all 0.2s ease;
  flex-basis: calc(100% - 300px);
  position: relative;
  &.files_active {
    flex-basis: calc(100% - 600px);
  }
  transition: flex-basis 0.1s ease-in-out;
  @media (max-width: 991px) {
    flex-basis: 100%;
  }
  &__online {
    color: #858585;
  }
  &__wrapper {
    // background-color: green;
    height: calc(100vh - 180px);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: #efeae2 url("../../assets/bg_chat.png") repeat;
      opacity: 0.4;
      width: 100%;
      height: 100%;
    }
  }
  &__header {
    background-color: #f0f2f5;
    padding: 10px 20px;
    position: relative;
    z-index: 2;
    > div {
      cursor: pointer;
      line-height: 1;
    }
  }
  .box {
    z-index: 1;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 250px);
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &__date {
      text-align: center;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #d8d8d8;
        left: 0;
        top: 12px;
      }
      span {
        color: #858585;
        font-size: 13px;
        background-color: #f8f8f8;
        position: relative;
        z-index: 1;
      }
    }
    .message {
      z-index: 1;
      position: relative;
      &__time {
        color: #667781;
        font-size: 11px !important;
        min-width: 45px;
      }
      &__text {
        padding: 10px;
        font-size: 14px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        position: relative;
        max-width: 280px;
        @media (min-width: 991px) {
          max-width: 350px;
        }
      }
      &__arrow {
        position: absolute;
        top: -2px;
        z-index: 100;
        display: block;
        width: 8px;
        height: 13px;
        color: #fff;
        right: -8px;
      }
      &--own {
        .message__arrow {
          left: -8px;
          right: auto;
          color: #d9fdd3;
        }
      }
      &__username {
        position: relative;
        .menu {
          &__btn {
            position: absolute !important;
            left: -4px;
            top: -5px;
          }
        }
      }
      &__reply {
        opacity: 0.7;
        font-size: 13px;
        background-color: #f5f6f6 !important;
      }
    }
  }
  .send {
    position: relative;
    z-index: 1;
    background-color: #f0f2f5;
    padding: 20px;
    &__box {
      background-color: #fff;
      min-height: 40px;
      border-radius: 20px;
      padding: 5px 20px;
      flex-grow: 1;
      &:before {
        content: attr(data-placeholder);
        display: inline-block;
        color: #808080;
        font-size: 13px;
        position: relative;
        top: 2px;
      }
    }
    &__btn {
      transform: rotate(-45deg);
    }
    &__reply {
      opacity: 0.7;
      font-size: 14px;
      background-color: #e5e7ea !important;
    }
  }
}
</style>
