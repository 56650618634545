<template>
  <v-container>
    <Stats
      @date_changed="date_changed($event)"
      :totals="stats_total"
      class="mb-5"
      :keys="keys"
      :data_loaded="data_loaded"
    ></Stats>
    <v-skeleton-loader type="table" v-if="!data_loaded"> </v-skeleton-loader>

    <Table :tbody="tbody" :keys="keys" :data_loaded="data_loaded"></Table>

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block">{{
          keys.work_times_page_delete_title
        }}</v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_time()"
            depressed
            color="error"
            >{{ keys.work_times_page_delete_btn }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.work_times_page_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
import Stats from "@/components/employees/times/stats";
import Table from "@/components/employees/times/table";
export default {
  name: "work_times",
  data() {
    return {
      delete_id: null,
      delete_dialog: false,
      delete_loader: false,
      data_loaded: false,
      search: "",
      tbody: [],
      totals: {},
      date:"",
    };
  },
  components: {
    Stats,
    Table,
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    stats_total() {
      return this.totals ? this.totals : [];
    },
    headers() {
      return [
        {
          value: "date",
          text: this.keys.work_times_page_date,
          align: "center",
        },
        {
          text: this.keys.work_times_page_project_name,

          value: "project_name",
          align: "center",
        },

        {
          value: "total",
          text: this.keys.work_times_page_total,
          align: "center",
        },
        {
          value: "actions",
          text: this.keys.work_times_page_actions,
          align: "center",
        },
      ];
    },
  },
  methods: {
    // get_delete_id(id) {
    //   this.delete_dialog = true;
    //   this.delete_id = id;
    // },
    // delete_time() {
    //   this.delete_loader = true;
    //   this.$store
    //     .dispatch("public__request", {
    //       config: {
    //         url: `company/project/times/${this.delete_id}`,
    //         method: "delete",
    //       },
    //     })
    //     .then((res) => {
    //       this.delete_dialog = false;
    //       this.delete_loader = false;
    //       this.get_times();
    //     });
    // },
    date_changed(date) {
      this.date = date;
      this.get_times()
    },
    get_times() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/project/times?date=${this.date}`,
            method: "get",
          },
        })
        .then((res) => {
          console.log(res);
          this.data_loaded = true;
          this.tbody = Object.assign([], res.data.tbody);

          Object.keys(res.data.totals).forEach((e, i) => {
            if (e == "payments") {
              this.$set(this.totals, "payed", res.data.totals[e].payed);
              this.$set(this.totals, "remainder", res.data.totals[e].remainder);
              this.$set(this.totals, "total_payment", res.data.totals[e].total);
            } else {
              this.$set(this.totals, e, res.data.totals[e].total);
              this.$set(this.totals, `${e}_count`, res.data.totals[e].count);
            }
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  created() {
    this.get_times();
  },
};
</script>
