var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.data_loaded)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.data_loaded)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"date","persistent-hint":"","prepend-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,718528362),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","type":"month"},on:{"input":function($event){_vm.menu = false,_vm.date_changed($event)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1):_vm._e(),(_vm.data_loaded)?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex"},[_c('span',{staticClass:"order-1"},[_vm._v(" "+_vm._s(_vm.keys.external_project_page_title))]),_c('v-spacer',{staticClass:"order-2"}),_c('v-text-field',{staticClass:"order-4 order-sm-3",attrs:{"append-icon":"mdi-magnify","label":_vm.keys.external_project_page_search,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"order-4 d-none d-sm-block"}),_c('v-btn',{staticClass:"order-3 order-sm-5",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('open_dialog')}}},[_vm._v(" "+_vm._s(_vm.keys.external_project_page_add_btn))])],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.tbody,"search":_vm.search,"footer-props":_vm.$store.state.dataTableFooterProps},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice(0, item.created_at.indexOf("T")))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-2 white--text",attrs:{"x-small":"","color":item.is_active ? 'primary' : 'red lighten-2'}},[_vm._v(" "+_vm._s(item.is_active ? _vm.keys.external_project_page_status_active : _vm.keys.external_project_page_status_inactive)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('edit_project', item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.get_delete_id(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center d-block"},[_vm._v(" "+_vm._s(_vm.keys.external_project_page_delete_title)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"loading":_vm.delete_loader,"depressed":"","color":"error"},on:{"click":function($event){return _vm.delete_project()}}},[_vm._v(" "+_vm._s(_vm.keys.external_project_page_delete_btn))]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.keys.external_project_page_close_btn))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }