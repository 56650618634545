import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";

//---------- start companies views
import Home from "../views/Home.vue";
import Live from "../views/Live.vue";
import Dashboard from "../views/dashboard";
import supplier from "../views/supplier";
import newSupplier from "../views/new-supplier.vue";
import projectBudget from "../views/project_budget.vue";
import invoices from "../views/invoices.vue";
import tasks from "../views/tasks.vue";
import gallery from "../views/gallery.vue";
import alboum from "../views/Alboum.vue";
import activity from "../views/activity.vue";
import telBook from "../views/Tel-book.vue";
import addInvoice from "../views/add-invoice.vue";
import progressTasks from "../views/progress-tasks.vue";
import setPrices from "../views/set_prices.vue";
import payments from "../views/payments.vue";
import invoiceRequest from "../views/invoice-request.vue";
import charts from "../views/charts.vue";
import staffDays from "../views/staff-days.vue";

//---start  companies
import companies from "@/views/companies/companies.vue";
import send_request from "@/views/companies/send_request.vue";
import add_services from "@/views/companies/add_services.vue";
import send_service_data from "@/views/companies/send_service_data.vue";
import services_data from "@/views/companies/services_data.vue";
import requests from "@/views/companies/requests.vue";
import order_report from "@/views/companies/order_report.vue";

//---End   companies

//--------- start projects
import Project from "@/views/projects/Project";
import projects from "@/views/projects/projects.vue";
import checkes from "@/views/projects/checks.vue";
import new_check from "@/views/projects/new_check.vue";
import projects_files from "@/views/projects/projects_files.vue";
import project_file from "@/views/projects/project_file.vue";
import sales_companies from "@/views/projects/sales_companies.vue";
import externalProject from "@/views/projects/external_project.vue";
//----------- End projects

import employees from "@/views/employees/Employees.vue";
import branches from "@/views/branches.vue";

//-------------------- Start  Employees
import Employee_profile from "@/views/employees/profile";
import work_times from "@/views/employees/work_times";
import times from "@/views/employees/times";
import gantt from "@/views/employees/gantt";
import employee_requests from "@/views/employees/requests";

// --------------------End employees

//global
import messages from "../views/messages.vue";
import chat from "@/views/chat.vue";

// Auth
import Login from "../views/Login.vue";
import signup from "@/views/signup.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/Home",
    name: "home_page",
    component: Home,
    meta: {
      title: "Home",
      requiresAuth: true,
    },
  },
  {
    path: "/branches",
    name: "c_branches_page",
    component: branches,
    meta: {
      title: "branches",
      requiresAuth: true,
    },
  },
  //------------ start  companies
  {
    path: "/companies",
    name: "c_companies_page",
    component: companies,
    meta: {
      title: "companies",
      requiresAuth: true,
    },
  },
  {
    path: "/send_request",
    name: "c_add_companies_page",
    component: send_request,
    meta: {
      title: "send_request",
      noHeader: false,
      requiresAuth: true,
    },
  },
  {
    path: "/requests",
    name: "c_requests",
    component: requests,
    meta: {
      title: "requests",
      noHeader: false,
      requiresAuth: true,
    },
  },

  {
    path: "/add_services",
    name: "c_add_services_page",
    component: add_services,
    meta: {
      title: "add_services",
      noHeader: false,
      requiresAuth: true,
    },
  },
  {
    path: "/send_service_data",
    name: "send_service_data_page",
    component: send_service_data,
    meta: {
      title: "send_service_data",
      requiresAuth: true,
    },
  },
  {
    path: "/services_data",
    name: "services_data_page",
    component: services_data,
    meta: {
      title: "services_data",
      requiresAuth: true,
    },
  },
  {
    path: "/order_report",
    name: "order_report_page",
    component: order_report,
    meta: {
      title: "services_data",
      requiresAuth: true,
    },
  },

  //------------ End  companies

  //----------- start projects

  {
    path: "/checkes",
    name: "checkes_page",
    component: checkes,
    meta: {
      title: "checkes",
      requiresAuth: true,
    },
  },
  {
    path: "/project",
    name: "project_page",
    component: Project,
    meta: {
      requiresAuth: true,
      title: "Project",
    },
  },
  {
    path: "/projects",
    name: "projects_page",
    component: projects,
    meta: {
      title: "projects",
      requiresAuth: true,
    },
  },

  {
    path: "/new_check",
    name: "new_check_page",
    component: new_check,
    meta: {
      title: "new_check",
      requiresAuth: true,
    },
  },
  {
    path: "/projects_files",
    name: "projects_files_page",
    component: projects_files,
    meta: {
      title: "projects_files",
      requiresAuth: true,
    },
  },
  {
    path: "/project_file",
    name: "project_file_page",
    component: project_file,
    meta: {
      title: "project_file",
      requiresAuth: true,
    },
  },

  {
    path: "/sales_companies",
    name: "sales_companies_page",
    component: sales_companies,
    meta: {
      title: "sales_companies",
      requiresAuth: true,
    },
  },

  {
    path: "/external_project",
    name: "external_project_page",
    component: externalProject,
    meta: {
      title: "external_project",
      requiresAuth: true,
    },
  },

  //------------ End projects

  //------------ Start Employees -
  {
    path: "/employee_profile",
    name: "employee_profile_page",
    component: Employee_profile,
    meta: {
      title: "Employee_profile",
      requiresAuth: true,
    },
  },
  {
    path: "/work_times",
    name: "work_times_page",
    component: work_times,
    meta: {
      title: "work_times",
      requiresAuth: true,
    },
  },
  {
    path: "/times",
    name: "times_page",
    component: times,
    meta: {
      title: "times",
      requiresAuth: true,
    },
  },
  {
    path: "/gantt",
    name: "gantt",
    component: gantt,
    meta: {
      title: "gantt",
      requiresAuth: true,
    },
  },
  {
    path: "/employee_requests",
    name: "employee_requests_page",
    component: employee_requests,
    meta: {
      title: "employee_requests",
      requiresAuth: true,
    },
  },

  //---------- End Employees

  {
    path: "/employees",
    name: "employees_page",
    component: employees,
    meta: {
      title: "employees",
      requiresAuth: true,
    },
  },
  {
    path: "/chat",
    name: "chat_page",
    component: chat,
    meta: {
      title: "chat",
    },
  },
  {
    path: "/Live",
    name: "Live",
    component: Live,
    meta: {
      title: "Live",
    },
  },

  {
    path: "/Supplier",
    name: "Supplier",
    component: supplier,
    meta: {
      title: "Supplier",
    },
  },
  {
    path: "/new-Supplier",
    name: "new-Supplier",
    component: newSupplier,
    meta: {
      title: "New Supplier",
    },
  },
  {
    path: "/project-budget",
    name: "project-budget",
    component: projectBudget,
    meta: {
      title: "Project budget",
    },
  },
  {
    path: "/set-prices",
    name: "setPrices",
    component: setPrices,
    meta: {
      title: "Set Prices",
    },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: invoices,
    meta: {
      title: "Invocies",
    },
  },
  {
    path: "/tasks",
    name: "tasks",
    component: tasks,
    meta: {
      title: "Tasks",
    },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: gallery,
    meta: {
      title: "Gallery",
    },
  },
  {
    path: "/alboum",
    name: "alboum",
    component: alboum,
    meta: {
      title: "Alboum",
    },
  },
  {
    path: "/activity",
    name: "activity",
    component: activity,
    meta: {
      title: "activity",
    },
  },
  {
    path: "/tel-book",
    name: "telBook",
    component: telBook,
    meta: {
      title: "telBook",
    },
  },
  {
    path: "/add-invoice",
    name: "addInvoice",
    component: addInvoice,
    meta: {
      title: "Add Invoices",
    },
  },
  {
    path: "/taskes-progress",
    name: "progressTasks",
    component: progressTasks,
    meta: {
      title: "taskes",
    },
  },
  {
    path: "/payments",
    name: "payments_page",
    component: payments,
    meta: {
      title: "payments",
      requiresAuth: true,
    },
  },
  {
    path: "/invoiceRequest",
    name: "invoiceRequest",
    component: invoiceRequest,
    meta: {
      title: "invoiceRequest",
      noHeader: true,
    },
  },
  {
    path: "/charts",
    name: "charts",
    component: charts,
    meta: {
      title: "charts",
      noHeader: false,
    },
  },
  {
    path: "/staffDays",
    name: "staffDays",
    component: staffDays,
    meta: {
      title: "staffDays",
      noHeader: false,
    },
  },

  {
    path: "/messages",
    name: "messages",
    component: messages,
    meta: {
      title: "messages",
      public: true,
    },
  },
  {
    path: "/signup",
    name: "signup_page",
    component: signup,
    meta: {
      title: "signup",
      requiresAuth: false,
    },
  },

  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      if (store.getters.user_role == "admin") {
        next("/employees");
      } else {
        next(`/employee_profile?employee=${store.getters.user_info.employee.id}`);
      }
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      store.dispatch("get_Static_Translate", to.name);
      next();
    }
  }
});
