<template>
  <div class="supplier">
    <v-container>
      <v-card elevation="0" outlined class="mb-5">
        <v-card-text>
          <v-row class="align-center">
            <v-col md="6" cols="12">
              <h2 class="title text-center text-md-start">
                احمد فتحي محايد | <span class="lighten-1--text">مورد</span>
              </h2>
            </v-col>

            <v-col
              md="6"
              cols="12"
              class="d-flex justify-center justify-md-end"
            >
              <v-menu offset-y :close-on-content-click="closeOnContentClick">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    color="blue"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    @click="closeOnContentClick = !closeOnContentClick"
                  >
                    <v-icon>call</v-icon>
                  </v-btn>
                </template>
                <v-list class="text-center">
                  <v-list-item outlined href="tel:021231231" link>
                    <v-list-item-title class="subtitle-2 font-weight-bold"
                      >اتصل الان</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="subtitle-2"
                      >02132123154</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu offset-y :close-on-content-click="closeOnContentClick2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    class="mx-2"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    @click="closeOnContentClick = !closeOnContentClick2"
                  >
                    <v-icon>whatsapp</v-icon>
                  </v-btn>
                </template>
                <v-list class="text-center">
                  <v-list-item href="tel:021231231" link>
                    <v-list-item-title class="subtitle-2 font-weight-bold"
                      >اتصل الان</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="subtitle-2"
                      >02132123154</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn      depressed fab small  to="#">
                <v-icon>badge</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <section>
        <v-row>
          <v-col lg="3" md="6" cols="6" v-for="(a, v) in analayze" :key="v">
            <v-card elevation="0" outlined class="analayze rounded-lg">
              <v-card-text>
                <div class="d-flex justify-space-between mb-5">
                  <h2 class="h3 analayze__title" :style="{ color: a.color }">
                    <span class="analayze__currency">₪</span>{{ a.money }}
                  </h2>
                  <v-icon class="analayze__icon" :style="{ color: a.color }">
                    circle</v-icon
                  >
                </div>
                <div class="d-flex justify-space-between">
                  <span class="analayze__percent">{{ a.percent }}%</span>
                  <h2 class="analayze__title analayze__title--text">
                    {{ a.text }}
                  </h2>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <v-card loading class="mb-10 mt-5" outlined elevation="0">
        <v-card-title>
          مدفوعات
          <v-spacer></v-spacer>
        </v-card-title>
        <v-simple-table class="pb-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-center"
                  v-for="(item, i) in table.headers"
                  :key="i"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr v-for="(item, i) in table.tbody" :key="i">
                <th class="gray fixedColumns">{{ item.date }}</th>
                <td>
                  {{ item.money }}
                </td>
                <td>
                  {{ item.payNumber }}
                </td>
                <td>
                  {{ item.payMethod }}
                </td>
                <td>
                  <span v-if="item.proof.prop">
                    <v-btn small icon to="/">
                      <v-icon small>visibility</v-icon>
                    </v-btn>
                  </span>
                  <span v-else>
                    <v-btn small icon to="/">
                      <v-icon small>history_edu</v-icon>
                    </v-btn>
                  </span>
                </td>
                <td>
                  {{ item.note }}
                </td>
                <td>
                  <span
                    class="font-weight-bold"
                    :class="[
                      { 'primary--text': item.status == 0 },
                      { 'red--text': item.status == 1 },
                    ]"
                  >
                    {{ item.status == 0 ? "مدفوع" : "غير مدفوع" }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>

tr:last-of-type td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
<script>
export default {
  name: "supplier",
  data() {
    return {
      closeOnContentClick: true,
      closeOnContentClick2: true,
      analayze: [
        {
          text: "باقي الدفع",
          percent: 30,
          color: "#00ffe7",
          money: 77,
        },
        {
          text: "تم الدفع",
          percent: 30,
          color: "#ffa500",

          money: 77,
        },
        {
          text: "اجمالي التكاليف",
          percent: 30,
          color: "#188320",
          money: 77,
        },
        {
          text: "اجمالي العقد",
          percent: 30,
          color: "red",
          money: 77,
        },
      ],
      table: {
        headers: [
          { text: " الوقت	" },
          { text: "المبلغ	" },
          { text: "رقم الدفعة" },
          { text: "طريق الدفع" },
          { text: "اثبات" },
          { text: "مبني ملاحظات		" },
          { text: "الحالة	" },
        ],
        tbody: [
          {
            date: "04:11:2021 - 15:34",
            money: 100,
            payNumber: 132,
            payMethod: "paypal",
            proof: {
              prop: true,
              link: "",
              pdf: "",
            },
            note: "lorem",
            status: 0,
          },
          {
            date: "04:11:2021 - 15:34",
            money: 42,
            payNumber: 236,
            payMethod: "paypal",
            proof: {
              prop: false,
              link: "",
              pdf: "",
            },
            note: "lorem",
            status: 1,
          },
        ],
      },
    };
  },
};
</script>
