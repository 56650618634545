<template>
  <div>
    <v-skeleton-loader type="table" v-if="!data_loaded"> </v-skeleton-loader>
    <v-card elevation="0" outlined class="mt-7" v-if="data_loaded">
      <v-card-title>
        {{ keys.employee_profile_page_days_table_title }}
        <v-spacer></v-spacer>
        <v-text-field
          hide-details
          v-model="search"
          :label="keys.employee_profile_page_table_search"
        ></v-text-field>
      </v-card-title>
      <!--/ card title -->
      <v-data-table
        mobile-breakpoint="0"
        :search="search"
        :items="tbody"
        :headers="headers"
        :footer-props="footer_props"
      ></v-data-table>
      <!-- / data table -->
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["keys", "tbody", "data_loaded"],
  name: "DaysTable",
  data() {
    return {
      search: "",
      footer_props: {
        showFirstLastPage: true,
        "items-per-page-options": [40, 50, 60],
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          align: "center",
          text: this.keys.employee_profile_page_days_table_date,
          value: "date",
        },
        {
          align: "center",
          text: this.keys.employee_profile_page_days_table_price,
          value: "price",
        },
      ];
    },
  },
};
</script>
