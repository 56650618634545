<template>
  <v-form ref="form">
    <v-skeleton-loader
      class="mb-5"
      v-if="!data_loaded"
      type="article"
    ></v-skeleton-loader>



    <v-card v-if="data_loaded" class="mb-5" outlined elevation="0">
      <v-card-title> {{ keys.times_page_title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in $store.getters.languages"
            :key="i"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(lang, i) in $store.getters.languages" :key="i">
            <v-row>
              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-select
                  v-model="project_times.type"
                  item-text="text"
                  item-value="value"
                  :items="types"
                  :rules="keys_trans ? rules.require_inp : ''"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.times_page_project_type }}
                  </template>
                  <!--/ label -->
                </v-select>
              </v-col>
              <!--/ col (select company ) -->
              <v-col
                cols="12"
                sm="3"
                v-if="project_times.type == 'internal' && tabs == 0"
              >
                <v-select
                  v-model="project_times.project_id"
                  item-text="name"
                  item-value="id"
                  :items="projects"
                  :rules="keys ? rules.require_inp : ''"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.times_page_select_project }}
                  </template>
                  <!--/ label -->
                </v-select>
              </v-col>
              <!--/ col (select company ) -->

              <v-col cols="12" sm="3" v-if="project_times.type == 'extrenal'">
                <v-select
                  v-model="project_times.project_id"
                  item-text="name_locale"
                  item-value="id"
                  :items="external_projects"
                  :rules="keys ? rules.require_inp : ''"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.times_page_select_project }}
                  </template>
                  <!--/ label -->
                </v-select>
              </v-col>
              <!--/ col (select company ) -->

              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="project_times.date"
                      persistent-hint
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    >
                      <template slot="label">
                        <strong class="red--text">*</strong>
                        {{ keys.times_page_date }}
                      </template>
                    </v-text-field>
                  </template>
                  <!--/ label -->
                  <v-date-picker
                    v-model="project_times.date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!--/ col (select date ) -->

              <v-col cols="12" sm="3">
                <v-textarea
                  v-model="project_times.notes[lang.locale]"
                  :label="keys.times_page_note"
                  rows="1"
                >
                </v-textarea>
              </v-col>
              <!--/ col الملاحظات -->
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <!--/ card text -->
    </v-card>
  </v-form>
  <!--/ card -->
</template>

<script>
export default {
  name: "BasicInfo",
  props: ["keys", "project_times", "data_loaded"],
  data() {
    return {
      tabs: 0,
      menu: false,
      external_projects:[],
      projects: [],
      time: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        company: "",
        notes: "",
        times: [],
      },
      rules: {
        require_inp: [(v) => !!v || "required"],
      },
    };
  },
  computed: {
    keys_trans() {
      return this.keys || [];
    },
    types() {
      return [
        {
          value: "internal",
          text: this.keys.times_page_project_status_internal,
        },
        {
          value: "extrenal",
          text: this.keys.times_page_project_status_external,
        },
      ];
    },
  },
  methods: {
    get_projects() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "company/projects",
          },
          get: "projects",
        })
        .then((res) => {
          this.projects = Object.assign([], res.data);
        });
    },
    get_external_projects() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/externalProjects`,
            method: "get",
          },
        })
        .then((res) => {
          this.external_projects = Object.assign([], res.data)
        });
    },
  },
  created() {
    this.get_projects();
    this.get_external_projects();
  },
};
</script>
