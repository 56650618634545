<template>
  <div>
    <v-container>
      <v-card elevation="0" outlined class="mb-5">
        <v-card-title>رقم الفاتورة 700 | أراباكس</v-card-title>
        <!--/title -->
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col md="3" sm="6" cols="12">
              <v-dialog
                ref="dialog"
                v-model="inputDate.modal"
                :return-value.sync="inputDate.date"
                persistent
                width="290px"
                @click:outside="closeDialog()"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="inputDate.date"
                    label="تاريخ الفاتورة"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="inputDate.date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="inputDate.modal = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(inputDate.date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
              <!-- / dialog -->
            </v-col>
            <!--/col -->
            <v-col md="3" sm="6" cols="12">
              <v-dialog
                ref="dialog"
                v-model="inputDate.modal"
                :return-value.sync="inputDate.date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="inputDate.date"
                    label="تاريخ الدفع"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="inputDate.date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="inputDate.modal = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(inputDate.date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!--/col -->
            <v-col md="3" sm="6" cols="12">
              <v-select :items="currency" label="العملة"></v-select>
            </v-col>
            <!--/col -->
            <v-col md="3" sm="6" cols="12">
              <v-select :items="items" label="لغة الفاتورة"></v-select>
            </v-col>
            <!--/col -->
          </v-row>
          <!--/row -->
        </v-card-text>
        <!--/card text -->
      </v-card>
      <!--/ card -->

      <!--/invoice basic info -->

      <v-card elevation="0" outlined class="mb-5">
        <v-tabs v-model="tab">
          <v-tab v-for="(item, i) in items" :key="i">
            <v-radio-group v-model="tab">
              <v-radio :label="item" :value="i"></v-radio>
            </v-radio-group>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item class="col-md-8 pa-0">
            <v-card flat>
              <v-card-text>
                <v-combobox class="pa-0" :items="usersBox"></v-combobox>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--/tab item -->

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="6" md="3">
                    <v-text-field
                      hide-details
                      label="اسم الزبون"
                    ></v-text-field>
                  </v-col>
                  <!--/col -->
                  <v-col cols="6" md="3">
                    <v-text-field
                      hide-details
                      label="الرقم التجاري"
                    ></v-text-field>
                  </v-col>
                  <!--/col -->
                  <v-col cols="6" md="3">
                    <v-select
                      hide-details
                      :items="items"
                      label="المدينة"
                    ></v-select>
                  </v-col>
                  <!--/col -->
                  <v-col cols="6" md="3">
                    <v-text-field
                      hide-details
                      label="رقم التليقون"
                    ></v-text-field>
                  </v-col>
                  <!--/col -->
                  <v-col cols="12" class="py-0">
                    <v-checkbox label="حفظ الزبون في القائمة"></v-checkbox>
                  </v-col>
                  <!--/col -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--/tab item -->
        </v-tabs-items>
      </v-card>
      <!--/customer info -->

      <v-card elevetion="0" class="mb-5" outlined>
        <v-card-title>ارسال الفاتورة</v-card-title>
        <v-card-text>
          <v-combobox
            :search-input.sync="search"
            v-model="comboModal"
            multiple
            :items="items"
            small-chips
            hide-selected
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "
                    <strong>{{ search }}</strong
                    >". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
          <p class="text-end">حد اقصي 5 ايميلات</p>
        </v-card-text>
      </v-card>
      <!--/send invoice -->

      <v-card elevation="0" outlined class="mb-5">
        <v-card-title>تفاصيل الخدمات والعناصر</v-card-title>
        <!--/title -->
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mb-5">
            <v-col cols="12" md="4" class="d-flex align-center nowrap">
              <v-select
                v-model="items[0]"
                :items="items"
                label="نوع الضريبة"
              ></v-select>
              <!--select -->
              <v-checkbox label="تصحيح الاجمالي" class="ms-4"></v-checkbox>
              <!--/ checbox -->
            </v-col>
            <!--/col -->
            <v-col cols="12" md="8" class="text-md-end text-center">
              <v-dialog max-width="400" v-model="addProductModal">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mt-3"
                    outlined
                  >
                    اضافة منتج
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="font-weight-bold">
                    اضافة منتج
                  </v-card-title>
                  <!--/card title -->
                  <v-card-text>
                    <v-select :items="['one', 'two']"></v-select>
                  </v-card-text>
                  <!--/card text-->
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn @click="addProductModal = false" color="red" text
                      >الغاء</v-btn
                    >
                  </v-card-actions>
                  <!--/card actions -->
                </v-card>
                <!--/card -->
              </v-dialog>
              <!--dialog -->
            </v-col>
            <!--/col -->
          </v-row>
          <!--/row -->
          <invoiceDetails></invoiceDetails>
        </v-card-text>
        <!--/card text -->
      </v-card>
      <!--/card -->
      <!--/services details -->

      <v-card elevation="0" outlined class="mb-5">
        <v-card-title>التعليقات والملخص</v-card-title>
        <!--/title -->
        <v-divider></v-divider>

        <v-card-text>
          <v-textarea class="mb-3" rows="2" label="ملاحظات"></v-textarea>
          <!--/textarea -->
          <v-textarea
            class="mb-3"
            rows="2"
            label="المحتوي من الجزء السفلي من الفاتورة"
          ></v-textarea>
          <!--/textarea -->
          <v-textarea
            class="mb-3"
            rows="2"
            label="محتوي اضافي في البريد الالكتروني للزبائن"
          ></v-textarea>
          <!--/textarea -->
        </v-card-text>
        <!-- cardtext -->
      </v-card>
      <!--/card -->

      <div class="text-center mb-10">
        <v-tooltip bottom nudge-bottom="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              large
              @click="inView = true"
              outlined
              color="primary"
              >معاينة</v-btn
            >
          </template>
          <span>معاينة</span>
        </v-tooltip>

        <v-tooltip bottom nudge-bottom="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              large
              outlined
              color="primary"
              class="mx-3"
              >حفظ المسودة</v-btn
            >
          </template>
          <span>حفظ</span>
        </v-tooltip>

        <v-tooltip bottom nudge-bottom="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" large color="primary" elevation="0"
              >إنتاج الوثائق</v-btn
            >
          </template>
          <span>إنتاج</span>
        </v-tooltip>
      </div>
    </v-container>

    <!--/container -->
    <invoiceView
      @close="inView = false"
      :inView="inView"
      v-if="inView"
    ></invoiceView>
  </div>
</template>

<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}
::v-deep {
  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
<script>
import invoiceDetails from "@/components/invoices/invoice-details.vue";
import invoiceView from "@/components/invoices/invoice-view.vue";
export default {
  name: "addInvoice",
  data() {
    return {
      addProductModal: null,
      currency: ["₪", "$"],

      currencyActive: 0,
      inView: false,
      search: null,
      comboModal: ["hassan.ahmed@mail.com"],
      inputDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        modal: false,
      },
      tab: null,
      items: ["عادي", "بدون ضريبة", "(ايلات و الخارج"],
      usersBox: ["hassan", "mohamed"],
    };
  },
  watch: {
    comboModal(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.comboModal.pop());
      }
    },
  },
  components: {
    invoiceDetails,
    invoiceView,
  },
};
</script>

<style></style>
