<template>
  <div>
      <!-- <pre>
        {{project.accessories}}
      </pre> -->

    <v-snackbar color="error" :timeout="3000" right v-model="alert">
      {{
        page_problem
          ? keys.prject_page_errors_problem
          : keys.prject_page_errors_empty
      }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs"> error </v-icon>
      </template>
    </v-snackbar>

    <v-container v-if="$route.query.project" class="swiper-con py-0">
      <swiper dir="rtl" class="swiper" :options="swiperOption">
        <swiper-slide :key="v" v-for="(a, v) in analayze">
          <v-card
            elevation="0"
            class="analayze rounded-lg"
            :style="{
              'border-color': routeName == a.link.toLowerCase() ? a.color : '',
            }"
          >
            <router-link  :to="{name:a.link, query:{id: $route.query.project}}" class="analayze__body d-block">
              <div class="d-flex justify-space-between mb-5">
                <h2 class="h3 analayze__title" :style="{ color: a.color }">
                  {{ a.money }}
                </h2>
                <v-icon class="analayze__icon" :style="{ color: a.color }">
                  circle</v-icon
                >
              </div>
              <div class="d-flex justify-space-between">
                <span class="analayze__percent">{{ a.percent }} %</span>
                <h2 class="analayze__title analayze__title--text">
                  {{ a.text }}
                </h2>
              </div>
            </router-link>
            <!--/ router link -->
          </v-card>
          <!--/card -->
        </swiper-slide>
        <!--/ swiper slide -->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <!--/end analazye carousel -->
    </v-container>

    <v-container v-if="$route.query.project && !edit_project">
      <v-skeleton-loader v-bind="attrs" type="article"> </v-skeleton-loader>
      <v-skeleton-loader v-bind="attrs" type="article,actions">
      </v-skeleton-loader>
    </v-container>

    <v-container v-if="edit_project">
      <v-card class="mb-2" elevation="0" outlined>
        <v-tabs v-model="tabVal" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab class="font-weight-bold">
            {{ keys.project_page_details_tab_name }}</v-tab
          >
          <v-tab class="font-weight-bold">
            {{ keys.project_page_accessories_tab_name }}
          </v-tab>
          <v-tab class="font-weight-bold"> القائمة </v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items class="transparent" v-model="tabVal">
        <v-tab-item>
          <basicInfo
            :name="project.name"
            :title="project.title"
            :description="project.description"
            :project_type_id="project.project_type_id"
            :start_date="project.start_date"
            @update_text="update_text($event)"
            @project_id="project_id($event)"
            @start_date="start_date($event)"
            :keys="keys"
            :errors="project.errors"
            :tabs="project.tabs"
            @basic_info_tabs="basic_info_tabs($event)"
          ></basicInfo>

          <buildings
            :buildings="project.buildings"
            :keys="keys"
            @update_tags="update_tags"
            @delete_tag="delete_tag($event)"
            @text_inputs="text_inputs($event)"
            @text_floors_inputs="text_floors_inputs($event)"
            @new_buidling="new_buidling"
            @new_floor="new_floor($event)"
            @delete_building="delete_building($event)"
            @delete_floor="delete_floor($event)"
          ></buildings>
        </v-tab-item>

        <v-tab-item>
          <accessoriesProject
            @accessories_inputs="accessories_inputs($event)"
            @new_acc="new_acc"
            :accessories="project.accessories"
            :keys="keys"
            @delete_accessories="delete_accessories($event)"
          >
          </accessoriesProject>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined elevation>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-dialog max-width="500" v-model="addPro">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn elevation="0" v-on="on" v-bind="attrs" color="primary"
                    >اضافة منتج</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title class="font-weight-bold">
                    اضافة منتج
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-tabs
                      class="mx-0 px-0"
                      v-model="productTab"
                      align-with-title
                    >
                      <v-tabs-slider color="yellow"></v-tabs-slider>
                      <v-tab> حالي </v-tab>
                      <v-tab> جديد </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="productTab">
                      <v-tab-item>
                        <v-select
                          :menu-props="{ top: true, offsetY: true }"
                          multiple
                          chips
                          :items="['one', 'two']"
                        ></v-select>
                      </v-tab-item>
                      <v-tab-item>
                        <v-row>
                          <v-col md="6" cols="12">
                            <v-text-field label="اسم المنتج"></v-text-field>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-text-field label="رقم المنتج"></v-text-field>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-text-field label="سعر المنتج"></v-text-field>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-text-field label="الكمية"></v-text-field>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-select
                              label="حالة المنتج"
                              :items="['متاح', 'غير متاح']"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="primary">اضافة</v-btn>
                    <v-btn text color="red">الغاء</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table :headers="list.headers" :items="list.body">
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  x-small
                  :class="[
                    { success: item.status == 0 },
                    { error: item.status == 1 },
                  ]"
                >
                  {{ item.status == 0 ? "متاح" : "غير متاح" }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon small>
                  <v-icon small @click="item">delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <div class="d-flex justify-end mt-5 mb-5">
        <div>
          <v-btn depressed large color="orange" class="white--text">
            {{ keys.project_page_save_project_delivery }}</v-btn
          >

          <v-btn
            depressed
            large
            color="primary"
            class="white--text ms-2"
            :disabled="save_loader"
            :loading="save_loader"
            @click="save()"
            >{{ keys.project_page_save }}</v-btn
          >
        </div>
      </div>
      <!--end tabs -->
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/main.scss";
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0;
}

::v-deep {
  .v-slide-group__prev {
    display: none !important;
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    @media (max-width: 580px) {
      width: 286px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 35px;
    height: 35px;
    background-color: #fff !important;
    @include radius(50%!important);
    @include shadow(menu);
    margin: 0 !important;
    overflow: hidden;
    top: calc(50% - 16px);
    color: #333;
    &::after {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  .swiper-button-next {
    right: -15px;
    @media (max-width: 1399px) {
      right: -5px;
    }
  }
  .swiper-button-prev {
    left: -15px;
    @media (max-width: 1399px) {
      left: -5px;
    }
  }

  .swiper-container-rtl {
    .swiper-button-next {
      right: auto;
      left: -15px;
      @media (max-width: 1399px) {
        left: -5px;
        right: auto;
      }
    }
    .swiper-button-prev {
      left: auto;
      right: -15px;
      @media (max-width: 1399px) {
        left: auto;
        right: -5px;
      }
    }
  }
}
</style>
<script>
import carousel from "v-owl-carousel";
import buildings from "@/components/project-details/buildings.vue";
import checkUp from "@/components/project-details/checkUp.vue";
import accessoriesProject from "@/components/project-details/Accessories_project.vue";
import basicInfo from "@/components/project-details/basicInfo.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Project",
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },

      edit_project: true,
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
      page_problem: false,
      alert: false,
      save_loader: false,
      project: {
        tabs: 0,
        errors: {
          name_err: false,
          type_err: false,
          data_err: false,
        },
        name: {},
        title: {},
        description: {},
        project_type_id: null,
        start_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),

        buildings: [
          {
            id: null,
            tabs: 0,
            observer: "",
            description: {},
            meter_inside: null,
            meter_outside: null,
            meter_stone_or_outside: null,
            tags: [],
            floors: [
              {
                space_err: false,
                floor_id: null,
                space: 0,
                roof_height: null,
              },
            ],
          },
        ],
        accessories: [],
      },

      swiperOption: {
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
        },
      },
      prop: null,
      tabVal: 0,
      swtichInp: true,
      addPro: false,
      productTab: 0,
      analayze: [
        {
          text: "التفاصيل",
          percent: 30,
          color: "#00ffe7",
          money: 77,
          link: "home",
        },
        {
          text: "التحليلات",
          percent: 30,
          color: "#ffa500",
          money: 77,
          link: "charts",
        },
        {
          text: "مستلزمان",
          percent: 30,
          color: "#188320",
          money: 77,
          link: "project-details2",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "red",
          money: 77,
          link: "project-details3",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#0997b7",
          money: 77,
          link: "project-details4",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#095200",
          money: 77,
          link: "project-details",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#857c17",
          money: 77,
          link: "project-details5",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#188320",
          money: 77,
          link: "project-details6",
        },
      ],

      list: {
        headers: [
          {
            text: "رقم",
            align: "center",
            value: "proNum",
          },
          {
            text: "سعر المنتج",
            align: "center",
            value: "price",
          },
          {
            text: "الكمية",
            align: "center",
            value: "quantity",
          },
          {
            text: "الحالة",
            align: "center",
            value: "status",
          },
          {
            text: "ملاحظات",
            align: "center",
            value: "note",
          },
          {
            text: "الاجمالي",
            align: "center",
            value: "total",
          },
          {
            text: "اجراءات",
            align: "center",
            value: "actions",
          },
        ],
        body: [
          {
            proNum: "12123",
            price: 1,
            quantity: 1,
            status: 0,
            note: "ملاحظات",
            total: 100,
          },
        ],
      },
    };
  },
  components: {
    carousel,
    buildings,
    checkUp,
    accessoriesProject,
    basicInfo,
    Swiper,
    SwiperSlide,
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    index(e) {
      return e;
    },
    routeName() {
      return this.$route.name.toLowerCase();
    },
    languages() {
      return this.$store.getters.languages;
    },
    default_lang() {
      return this.languages.find((l) => l.is_default);
    },
  },
  methods: {
    get_project() {
      let project_id = this.$route.query.project;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: `company/projects/${project_id}`,
          },
        })
        .then((res) => {
          console.log(res.data);

          this.project = Object.assign({}, res.data);
          this.edit_project = true;
        });
    },
    basic_info_tabs(ev) {
      this.project.tabs = ev;
    },
    // set name & description & title from basicInfo component
    update_text(ele) {
      this.$set(this.project[ele.input_name], ele.locale, ele.value);
    },
    // set project id from basicInfo component
    project_id(el) {
      this.$set(this.project, "project_type_id", el);
    },
    // set start date  id from basicInfo component
    start_date(el) {
      this.$set(this.project, "start_date", el);
    },
    // set start date  id from buidlings component
    text_inputs(ev) {
      if (ev.locale) {
        this.$set(
          this.project.buildings[ev.index][ev.prop_name],
          ev.locale,
          ev.value
        );
      } else {
        this.$set(this.project.buildings[ev.index], ev.prop_name, ev.value);
      }
    },
    // update tags from buildings component
    update_tags(ev) {
      let ind = this.project.buildings[ev.index].tags.length;
      if (ev.value != "") {
        this.$set(this.project.buildings[ev.index]["tags"], ind, {
          text: ev.value,
          color: this.colors[Math.floor(Math.random() * this.colors.length)],
        });
      }
    },
    // delete tags from buildings component
    delete_tag(ev) {
      let item_index = this.project.buildings[ev.buidling_index].tags.indexOf(
        ev.item
      );
      this.project.buildings[ev.buidling_index].tags.splice(item_index, 1);
    },

    //update floors text  from buildings component
    text_floors_inputs(ev) {
      this.$set(
        this.project.buildings[ev.b_index].floors[ev.f_index],
        ev.prop_name,
        ev.value
      );
    },
    // new building
    new_buidling() {
      const building = {
        observer: "",
        description: {},
        meter_inside: null,
        meter_outside: null,
        meter_stone_or_outside: null,
        tags: [],
        floors: [
          {
            floor_id: null,
            space: 0,
            roof_height: null,
          },
        ],
      };
      this.$set(
        this.project.buildings,
        this.project.buildings.length,
        building
      );
    },

    //--- delete_building
    delete_building(ev) {
      if (ev.building_id) {
        this.$store.dispatch("public__request", {
          config: {
            method: "delete",
            url: `company/projects/building/${ev.building_id}`,
          },
          data: this.project,
        });
      }
      this.project.buildings.splice(ev.building_index, 1);
    },
    new_floor(b_index) {
      const floor = {
        floor_id: null,
        space: 0,
        roof_height: null,
      };
      const floor_index = this.project.buildings[b_index].floors.length;
      this.$set(this.project.buildings[b_index].floors, floor_index, floor);
    },
    // delete floor
    delete_floor(ev) {
      if (ev.floor_id) {
        this.$store.dispatch("public__request", {
          config: {
            method: "delete",
            url: `company/projects/building/floor/${ev.floor_id}`,
          },
          data: this.project,
        });
      }
      this.project.buildings[ev.building_index].floors.splice(
        ev.floor_index,
        1
      );
    },

    // update  accessories inputs  from accsessories component
    accessories_inputs(ev) {

      if (ev.locale) {
        this.$set(
          this.project.accessories[ev.acc_index][ev.prop_name],
          ev.locale,
          ev.value
        );
      } else {
        this.$set(
          this.project.accessories[ev.acc_index],
          ev.prop_name,
          ev.value
        );
      }
    },
    // delete accessories
    delete_accessories(ev) {
      if (ev.acc_id) {
        this.$store.dispatch("public__request", {
          config: {
            method: "delete",
            url: `company/projects/accessory/${ev.acc_id}`,
          },
          data: this.project,
        });
      }
      this.project.accessories.splice(ev.acc_index, 1);
    },
    // add new accessory
    new_acc() {
      const acc = {
        amount_err: false,
        tyoe_err: false,
        start_dialog: false,
        end_dialog: false,
        tabs: 0,
        id: null,
        start_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        type: null,
        amount: null,
        total: null,
        add_total: null,
        description: {},
      };
      const acc_ind = this.project.accessories.length;
      this.$set(this.project.accessories, acc_ind, acc);
    },
    //details & buildings validation
    details_buildings_validation() {
      const errs = [];
      if (!this.project.name[this.default_lang.locale]) {
        this.project.errors.name_err = true;
        errs.push(0);
      } else {
        this.project.errors.name_err = false;
      }

      if (!this.project.project_type_id) {
        this.project.errors.type_err = true;
        errs.push(0);
      } else {
        this.project.errors.type_err = false;
      }

      if (!this.project.start_date) {
        this.project.errors.data_err = true;
        errs.push(0);
      } else {
        this.project.errors.data_err = false;
      }
      //----------- details validation

      this.project.buildings.forEach((e, i) => {
        e.floors.forEach((f, s) => {
          if (!f.space) {
            this.project.buildings[i].floors[s].space_err = true;
            errs.push(s);
          } else {
            this.project.buildings[i].floors[s].space_err = false;
          }
        });
      });

      if (errs.length == 0) {
        this.alert = false;
        return true;
      } else {
        this.alert = true;
        this.page_problem = false;
        this.tabVal = 0;
        this.project.tabs = 0;
        return false;
      }
    },
    // accessories validation
    accessories_validation() {
      const errs = [];
      this.project.accessories.forEach((e, i) => {
        if (!e.amount) {
          this.project.accessories[i].amount_err = true;
          this.project.accessories[i].tabs = 0;
          errs.push(i);
        } else {
          this.project.accessories[i].amount_err = false;
        }

        if (!e.type) {
          this.project.accessories[i].type_err = true;
          this.project.accessories[i].tabs = 0;
          errs.push(i);
        } else {
          this.project.accessories[i].type_err = false;
        }
      });

      if (errs.length == 0) {
        this.alert = false;
        return true;
      } else {
        this.alert = true;
        this.page_problem = false;
        this.tabVal = 1;
        return false;
      }
    },

    save() {
      if (
        this.details_buildings_validation() &&
        this.accessories_validation()
      ) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              method: "post",
              url: "company/projects",
            },
            data: this.project,
          })
          .then((res) => {
            let vm = this;
            setTimeout(() => {
              vm.$router.push({
                name: "projects_page",
              });
            }, 1500);
          })
          .catch((err) => {
            console.log(err.response);
            this.page_problem = true;
            this.save_loader = false;
          });
      }
    },
  },
  created() {
    if (this.$route.query.project) {
      this.edit_project = false;
      this.get_project();
    }
  },
};
</script>
