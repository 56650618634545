<template>
  <v-container>
    <v-card elevation="0" outlined>
      <v-card-title>
        دليل الهاتف
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="بحث"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <!--/title -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :mobile-breakpoint="0"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.activtyType`]="{ item }">
          <v-chip
            x-small
            class="white--text px-2"
            :class="[
              { 'red accent-1': item.activtyType == 0 },
              { primary: item.activtyType == 1 },
            ]"
          >
            {{ item.activtyType == 0 ? "مورد" : "مورد" }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick"
              >
                <v-icon small>call</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item outlined href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <!--/ list item -->
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
              <!--/ list item -->
            </v-list>
            <!--/ v list -->
          </v-menu>
          <!--/menu -->

          <v-menu offset-y :close-on-content-click="closeOnContentClick2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                class="mx-2"
                icon
                v-bind="attrs"
                v-on="on"
                @click="closeOnContentClick = !closeOnContentClick2"
              >
                <v-icon small>whatsapp</v-icon>
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item href="tel:021231231" link>
                <v-list-item-title class="subtitle-2 font-weight-bold"
                  >اتصل الان</v-list-item-title
                >
              </v-list-item>
              <!--/ list item -->
              <v-list-item>
                <v-list-item-title class="subtitle-2"
                  >02132123154</v-list-item-title
                >
              </v-list-item>
              <!--/ list item -->
            </v-list>
            <!--/ v list -->
          </v-menu>
          <!--/menu -->

          <v-btn fab small icon :to="{ name: 'Home', query: { id: item.id } }">
            <v-icon small>badge</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!--/data table -->
    </v-card>
    <!--/card -->
  </v-container>
  <!--/container -->
</template>

<script>
export default {
  name: "telBook",
  data() {
    return {
      closeOnContentClick: true,
      closeOnContentClick2: true,
      search: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "الأسم", value: "name" },
        { text: "رقم الهاتف", value: "phone" },
        { text: "نوع الشخص", value: "activtyType" },
        { text: " البريد الالكتروني", value: "mail" },
        { text: "اجراءات", value: "actions", sortable: false },
      ],
      desserts: [
        {
          id: "1",
          name: "حسن احمد",
          phone: "حسن احمد",
          activtyType: 0,
          mail: "موظف",
        },
        {
          id: "21",
          name: "حسن احمد",
          phone: "حسن احمد",
          activtyType: 1,
          mail: "موظف",
        },
      ],
    };
  },
};
</script>
