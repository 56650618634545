<template>
  <v-container>
    <v-snackbar color="error" :timeout="3000" right v-model="alert">
      {{ keys.c_add_services_page_errors_required }}

      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs"> error </v-icon>
      </template>
    </v-snackbar>

    <v-skeleton-loader type="article,actions">
    </v-skeleton-loader>

    <div >
      <h3 class="mb-4">
        {{
          $route.query.id
            ? keys.c_add_services_page_edit_title
            : keys.c_add_services_page_add_title
        }}
        ({{ contact.name }})
      </h3>
      <v-card
        elevation="0"
        class="mb-5"
        outlined
        v-for="(service, i) in services"
        :key="i"
      >
        <v-card-title>
          <v-row class="align-end">
            <v-col cols="5" sm="4">
              <v-select
                :items="prices"
                item-text="type"
                item-value="id"
                class="me-4"
                hide-details
                :error="service.price_err"
                v-model="service.price_id"
                @change="service_changed(i)"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ keys.c_add_services_page_choose_service }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="5" sm="4">
              <v-select
                :items="contact.branches"
                item-text="name"
                item-value="branch_id"
                class="me-4"
                :error="service.branch_err"
                v-model="service.branch_ids"
                hide-details
                multiple
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ keys.c_add_services_page_choose_branches }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="2" sm="4" class="text-end">
              <v-btn
                @click="get_delete_index(service.id, i)"
                depressed
                color="error"
                fab
                x-small
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <!--/ card title -->
        <v-card-text>
          <v-list>
            <v-list-item class="justify-center" v-if="service.alert">
              <v-alert
                class="text-center"
                color="error"
                border-left
                dense
                outlined
              >
                {{ keys.c_add_services_page_errors_empty_row }}
              </v-alert>
            </v-list-item>
            <template v-for="(row, ind) in service.details">
              <v-list-item :key="ind">
                <v-list-item-content class="blue-grey--text darken-2--text">
                  {{ row.description[default_lang] }}
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex">
                    <v-btn @click="edit_row(row, i, ind)" icon small>
                      <v-icon small>edit</v-icon>
                    </v-btn>
                    <v-btn @click="delete_row(i, ind, row.id)" icon small>
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <div class="text-end">
            <v-btn
              @click="new_row(i, service.price_id)"
              color="blue-grey lighten-1"
              outlined
              depressed
              >{{ keys.c_add_services_page_new_row }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </div>

    <!--/ card -->
    <div class="d-flex justify-space-between my-5">
      <v-btn @click="new_service()" outlined color="primary" depressed>{{
        keys.c_add_services_page_add_service
      }}</v-btn>
      <v-btn
        color="primary"
        :loading="save_servie_loader"
        :disabled="save_servie_loader"
        depressed
        v-if="services.length != 0"
        @click="save_service()"
        >{{ keys.c_add_services_page_save }}</v-btn
      >
    </div>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title> {{ dialog_title }} </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs class="mb-3" v-model="tabs" background-color="gray">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              v-model="tabs"
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              {{ lang.native }}
            </v-tab>
          </v-tabs>
          <!--/tabs -->

          <v-tabs-items v-model="tabs">
            <v-tab-item
              v-for="(lang, i) in this.$store.getters.languages"
              :key="i"
            >
              <v-row class="pb-3">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="edit_item.description[lang.locale]"
                    hide-details
                    :error="tabs == 0 ? err_default_lang : null"
                  >
                    <template slot="label">
                      <strong v-if="tabs == 0" class="error--text">*</strong>
                      {{ keys.c_add_services_page_popup_desc }}
                    </template>
                  </v-text-field>
                  <p v-if="tabs == 0 && err_default_lang" class="error--text">
                    مطلوب
                  </p>
                </v-col>
                <v-col v-show="tabs == 0" cols="12" sm="6">
                  <v-text-field
                    hide-details
                    v-model.number="edit_item.price"
                    type="number"
                    min="0"
                    label="price"
                    :error="err_price"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.c_add_services_page_popup_price }}
                    </template>
                  </v-text-field>
                  <p v-if="err_price" class="error--text">مطلوب</p>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <div class="mb-5" v-for="(add, i) in edit_item.additions" :key="i">
            <h4 class="mb-3">{{ add.name }}</h4>
            <v-card elevation="0" color="blue-grey lighten-5">
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(child, s) in add.children"
                    :key="s"
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      hide-details
                      v-model.number="child.price"
                      min="0"
                      type="number"
                      :error="child.err"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ child.name }}
                      </template>
                    </v-text-field>
                    <p v-if="child.err" class="error--text">مطلوب</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="save_row()" depressed color="primary" small>{{
            keys.c_add_services_page_popup_add_btn
          }}</v-btn>
          <v-btn @click="close()" depressed small color="error" text>
            {{ keys.c_add_services_page_popup_close_btn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.c_add_services_page_delete_popup_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn @click="delete_service()" depressed color="error">{{
            keys.c_add_services_page_delete_popup_delete_btn
          }}</v-btn>
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.c_add_services_page_delete_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "add_services",
  data() {
    return {
      service_delete_id: null,
      service_delete_index: null,
      delete_dialog: false,

      save_servie_loader: false,

      tabs: 0,
      dialog: false,
      err_default_lang: false,
      err_price: false,

      edit_item: {
        id: null,
        price: null,
        description: {},
        additions: [],
      },
      service_index: null,

      indexItem: -1,
      alert: false,
      get_services_config: {
        url: `/company/services?contact_id=${this.$route.query.id}`,
        method: "get",
      },
      post_services_config: {
        url: "/company/services",
        method: "post",
      },
      get_prices_config: {
        url: "company/prices",
        method: "get",
      },

      price_id: null,
      prices: [],
      services: [],
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) this.close();
    },
  },
  computed: {
    dialog_title() {
      return this.indexItem == -1
        ? this.keys.c_add_services_page_popup_add_title
        : this.keys.c_add_services_page_popup_edit_title;
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    contact() {
      return this.$store.state.pages_data.companies
        ? this.$store.state.pages_data.companies.find(
            (e) => e.id == this.$route.query.id
          )
        : null;
    },
    default_lang() {
      return this.$store.getters.user_obj.languages.find((e) => e.is_default)
        .locale;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.call_methods();
    });
  },
  methods: {
    service_changed(ind) {
      let index = this.services[ind].details.indexOf(
        this.services[ind].details[0]
      );

      while (index !== -1) {
        this.services[ind].details[index].id;
        this.delete_row(ind, index, this.services[ind].details[index].id);
        index = this.services[ind].details.indexOf(
          this.services[ind].details[0]
        );
      }
    },
    validation() {
      let errors = [];
      if (!this.edit_item.description[this.default_lang]) {
        this.err_default_lang = true;
        errors.push(0);
        this.tabs = 0;
      } else {
        this.err_default_lang = false;
      }

      if (!this.edit_item.price) {
        this.err_price = true;
        errors.push(0);
        this.tabs = 0;
      } else {
        this.err_price = false;
      }
      this.edit_item.additions.forEach((e, i) => {
        e.children.forEach((ch, s) => {
          if (!ch.price) {
            this.edit_item.additions[i].children[s].err = true;
            errors.push(i);
          } else {
            this.edit_item.additions[i].children[s].err = false;
          }
        });
      });

      if (errors.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    new_service() {
      let default_service = {
        contact_id: parseInt(this.$route.query.id),
        price_id: null,
        alert: false,
        branch_ids: [],
        details: [],
      };

      this.$set(this.services, this.services.length, default_service);
    },
    new_row(ind, price_id) {
      this.service_index = ind;

      if (!this.services[ind].price_id) {
        this.services[ind].price_err = true;
        this.alert = true;
      } else {
        this.services[ind].price_err = false;
        this.alert = false;
      }
      if (this.services[ind].branch_ids.length == 0) {
        this.services[ind].branch_err = true;
        this.alert = true;
      } else {
        this.services[ind].branch_err = false;
        this.alert = false;
      }
      if (
        this.services[ind].price_id &&
        this.services[ind].branch_ids.length != 0
      ) {
        let findItem = this.prices.find((e) => e.id == price_id);
        findItem.additions.forEach((e, i) => {
          this.$set(this.edit_item.additions, i, {
            id: e.id,
            is_public: e.is_public,
            name: e.name,
            children: [],
          });

          e.children.forEach((s, t) => {
            this.$set(this.edit_item.additions[i].children, t, {
              id: s.id,
              name: s.name,
              err: false,
            });
          });
        });

        this.$nextTick(() => {
          this.dialog = true;
        });
      }
    },
    edit_row(row, service_index, row_index) {
      this.indexItem = row_index;
      this.$set(this, "service_index", service_index);
      this.edit_item = Object.assign({}, row);
      this.dialog = true;
    },

    save_row() {
      if (this.validation()) {
        if (this.indexItem == -1) {
          this.$set(
            this.services[this.service_index].details,
            this.services[this.service_index].details.length,
            this.edit_item
          );
          this.$nextTick(() => {
            this.close();
          });
          this.services[this.service_index].alert = false;
        } else {
          Object.assign(
            this.services[this.service_index].details,
            this.edit_item
          );
          this.$nextTick(() => {
            this.close();
          });
        }
      }
    },
    delete_row(service_index, row_index, id) {
      if (id) {
        this.$store.dispatch("public__request", {
          config: {
            method: "delete",
            url: `/company/services/detail/${id}`,
          },
        });
      }
      this.services[service_index].details.splice(row_index, 1);
    },

    validation_save() {
      let errors = [];

      this.services.forEach((e, i) => {
        if (e.details.length == 0) {
          this.$set(this.services[i], "alert", true);
          errors.push(0);
        } else {
          this.$set(this.services[i], "alert", false);
        }
        if (e.branch_ids.length == 0 || !e.branch_ids) {
          this.$set(this.services[i], "branch_err", true);
          errors.push(0);
        } else {
          this.$set(this.services[i], "branch_err", false);
        }
        if (!e.price_id) {
          errors.push(0);
          this.$set(this.services[i], "price_err", true);
        } else {
          this.$set(this.services[i], "price_err", false);
        }
      });

      if (errors.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    save_service() {
      if (this.validation_save() && this.services != 0) {
        this.save_servie_loader = true;
        let data = this.services;

        this.$store
          .dispatch("public__request", {
            config: this.post_services_config,
            data: { services: data },
          })
          .then((res) => {
            this.$router.push({ name: "c_companies_page" });
          })
          .catch((err) => {});
      } else {
        this.alert = true;
      }
    },
    close() {
      let default_item = {
        id: null,
        price: null,
        description: {},
        additions: [],
      };
      this.tabs = 0;
      this.dialog = false;
      this.service_index = null;
      this.indexItem = -1;

      this.$nextTick(() => {
        this.edit_item = Object.assign({}, default_item);
      });
    },
    get_delete_index(service_id, service_index) {
      if (service_id) {
        this.service_delete_id = service_id;
      }
      this.service_delete_index = service_index;
      this.delete_dialog = true;
    },
    delete_service() {
      if (this.service_delete_id) {
        this.$store
          .dispatch("public__request", {
            config: {
              method: "delete",
              url: `/company/services/${this.service_delete_id}`,
            },
          })
          .then((res) => {
            console.log(res);
          });
      }
      this.services.splice(this.service_delete_index, 1);

      this.delete_dialog = false;
    },
    get_prices() {
      this.$store
        .dispatch("public__request", {
          config: this.get_prices_config,
        })
        .then((res) => {
          this.prices = res.data.filter((e) => e.has_price);
        });
    },
    get_services() {
      this.$store
        .dispatch("public__request", {
          config: this.get_services_config,
        })
        .then((res) => {
          this.services = res.data;
        });
    },
    call_methods() {
      let query = this.$route.query.id;
      if (query) {
        if (this.contact) {
          this.get_services();
          this.get_prices();
        } else {
          this.$router.push({
            name: "c_companies_page",
          });
        }
      } else {
        this.$router.push({
          name: "c_companies_page",
        });
      }
    },
  },
  created() {
    // this.call_methods();
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter" && vm.delete_dialog) {
        vm.delete_service();
      }
    };
  },
};
</script>
