<template>
  <div>
    <!-- <v-btn @click="drawer = !drawer"></v-btn> -->
    <v-navigation-drawer
      hide-overlay
      class="list"
      right
      floating
      v-model="drawer"
    >
      <v-card elevation="0">
        <v-card-title class="pt-0 px-0">
          <h2 class="list__title">الرسائل</h2>
          <v-text-field
            hide-details
            placeholder="بحث"
            class="search mt-4 mx-3"
            min-height="30"
            prepend-inner-icon="search"
            filled
          ></v-text-field>
        </v-card-title>
        <v-card-text v-scroll.self="onScroll" class="overflow-y-auto py-0 px-2">
          <v-list dense>
            <v-list-item
              tag="button"
              v-for="list in 14"
              :key="list"
              class="v-btn px-2"
            >
              <v-list-item-avatar size="40">
                <img :src="`https://placeimg.com/100/100/tech?${list}`" />
              </v-list-item-avatar>
              <v-list-item-content>
                <h5 class="list__name text-start">Hassn Ahmed</h5>
                <p class="list__message mt-1 text-start">Hello how are u ...</p>
              </v-list-item-content>
              <v-list-item-action>
                <span class="caption list__time">4:00PM</span>
                <div class="d-flex align-center">
                  <v-icon
                    v-if="list == 1"
                    class="list__pin"
                    color="#667781"
                    small
                    >push_pin</v-icon
                  >
                  <div v-if="list == 2" class="list__missed">1</div>

                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    class="menu"
                    offset-y
                    v-if="list == 1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        class="pa-0 menu__btn"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>keyboard_arrow_down</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item-group v-model="model">
                        <v-list-item
                          v-for="(item, index) in items"
                          :key="index"
                        >
                          <v-list-item-title class="body-2">{{
                            item
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <!--/ card -->
    </v-navigation-drawer>
    <!--/navigation drawer -->
  </div>
</template>

<script>
export default {
  name: "contactList",
  data() {
    return {
      model:null,
      drawer: true,
      scrollInvoked: 0,
      items:[1, 2, 3]
    };
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .list {
    &.v-navigation-drawer {
      width: 340px !important;
    }
    .v-card {
      border-radius: 0;
    }
    &__title {
      width: 100%;
      background-color: #f0f2f5;
      padding:14px 10px;
    }
    &__name {
      text-transform: capitalize;
    }
    &__message {
      font-size: 12px;
      color: #6a6a6a;
      font-weight: normal;
    }
    &__time {
      color: #667781;
      margin-top: 5px;
      font-size: 11px !important;
    }
    &__pin {
      transform: rotate(45deg);
    }
    &__missed {
      width: 18px !important;
      height: 18px !important;
      line-height: 20px;
      border-radius: 50% !important;
      text-align: center;
      display: block;
      position: relative;
      top: 0px;
      background-color: #25d366;
      color: #fff;
      font-size: 11px;
    }
    .v-list-item {
      text-transform: lowercase;
      border-bottom: 1px solid #ddd;
      border-radius: 0;
      height: 68px;
      width: 100%;
    }
    .v-list-item__subtitle {
      color: #929292;
      .v-icon {
        font-size: 16px;
        &.pin {
          transform: rotate(-40deg);
        }
      }
    }
  }
  .v-card {
    height: calc(100vh - 28px);
    overflow: hidden;
    &__text {
      height: calc(100vh - 157px);
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
  .search {
    .v-input__slot {
      min-height: 40px;
      border-radius: 50px;
      background-color: rgba(240, 240, 240, 0.51);
      font-weight: normal;
      input {
        color: rgba(70, 70, 70, 0.87);
      }
      &:before,
      &:after {
        display: none;
      }
      font-size: 12px;
    }
    .v-icon.v-icon {
      font-size: 20px;
      top: -6px;
    }
  }
}
</style>
