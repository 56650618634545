<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"> </v-skeleton-loader>
    <v-card outlined v-if="data_loaded">
      <v-card-title class="d-flex">
        <span class="order-1">{{ keys.employees_page_name }}</span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.employees_page_input_search"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>
        <v-btn
          depressed
          color="primary"
          class="order-3 order-sm-5"
          :to="{ name: 'c_add_companies_page', query: { employee: true } }"
        >
          {{ keys.employees_page_add_btn }}</v-btn
        >
      </v-card-title>
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="tbody"
        :search="search"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :to="{
              name: 'employee_profile_page',
              query: { employee: item.employee_id },
            }"
            icon
            small
          >
            <v-icon small>person</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="
              open_work_cost_dialog(item.employee.id, item.employee.work_costs)
            "
          >
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn @click="get_delete_id(item.employee.id)" icon small>
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!--/ card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.employees_page_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_employee()"
            depressed
            color="error"
            >{{ keys.employees_page_delete_delete }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.employees_page_delete_cancel
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->

    <v-dialog persistent max-width="500" v-model="work_const_dialog">
      <v-card>
        <v-card-title>ساعات العمل</v-card-title>
        <v-divider></v-divider>
        <!--/card title -->
        <v-card-text class="pt-4">
          <v-form ref="form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="work_cost.hour_price"
                  label="سعر الساعة"
                  :rules="rules.require"
                ></v-text-field>
                <!--/ hour price -->
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="work_cost.day_price"
                  label="سعر اليوم"
                  :rules="rules.require"
                ></v-text-field>
                <!--/ day price -->
              </v-col>
            </v-row>

            <v-row
              class="align-center"
              v-for="(con, i) in work_cost.hour_conditions"
              :key="i"
            >
              <v-col cols="4">
                <v-text-field
                  @input="check_time({ value: $event, prop: 'from', ind: i })"
                  v-model="con.from"
                  label="من"
                  :rules="rules.time"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  @input="check_time({ value: $event, prop: 'to', ind: i })"
                  v-model="con.to"
                  label="الى"
                  :rules="rules.time"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="con.price" label="السعر"></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn @click="remove_row(i, con.id)" color="error" icon small>
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-btn
              @click="new_row()"
              color="primary"
              class="mt-3"
              small
              outlined
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-form>
        </v-card-text>
        <!--/ card  text -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            :loading="save_loader"
            :disabled="save_loader"
            @click="save_work_hours()"
            small
            color="primary"
            >حفظ</v-btn
          >
          <v-btn @click="close_work_cost()" small text color="error"
            >الغاء</v-btn
          >
        </v-card-actions>
        <!--/ actions -->
      </v-card>
      <!--/ card -->
    </v-dialog>

    <v-snackbar type="success" left v-model="snackbar">
      <p>تم حفظ الساعات</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
    <!--/ snackbar -->
  </v-container>
</template>

<script>
export default {
  name: "employees",
  data() {
    return {
      save_loader: false,
      snackbar: false,
      work_cost: {
        employee_id: null,
        hour_price: 0,
        day_price: 0,
        hour_conditions: [],
      },
      rules: {
        require: [(v) => !!v || "حقل مطلوب"],
        time: [
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || "الوقت غير صحيح",
        ],
      },
      work_const_dialog: false,
      delete_dialog: false,
      delete_loader: false,
      data_loaded: false,
      delete_id: false,
      search: "",
      tbody: [],
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.employees_page_employee_name,
          value: "employee.name",
          align: "center",
        },
        {
          text: this.keys.employees_page_identifier_number,
          value: "employee.id_number",
          align: "center",
        },
        {
          text: this.keys.employees_page_email,
          value: "employee.email",
          align: "center",
        },
        {
          text: this.keys.employees_page_phone_number,
          value: "employee.phone",
          align: "center",
        },
        // {
        //   text: this.keys.employees_page_status,
        //   value: "status",
        //   align: "center",
        // },
        {
          text: this.keys.employees_page_actions,
          value: "actions",
          align: "center",
        },
      ];
    },
  },
  methods: {
    get_employees() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/employees/list",
            method: "get",
          },
        })
        .then((res) => {
          this.tbody = Object.assign([], res.data);
          this.data_loaded = true;
        });
    },
    get_delete_id(id) {
      this.delete_dialog = true;
      this.delete_id = id;
    },
    delete_employee() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/employees/remove/${this.delete_id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.delete_dialog = false;
          this.delete_loader = false;
          this.get_employees();
        });
    },
    check_time(ev) {
      if (ev.value != null && ev.value.length == 2) {
        this.work_cost.hour_conditions[ev.ind][ev.prop] =
          this.work_cost.hour_conditions[ev.ind][ev.prop] + ":";
      }
    },

    //-------------- open work cost dialog
    open_work_cost_dialog(id, work_cost) {
      if (work_cost) {
        this.work_cost = Object.assign({}, work_cost);
      }
      this.work_cost.employee_id = id;
      this.work_const_dialog = true;
    },

    // --------------- add new row
    new_row() {
      let hour = {
        from: "",
        to: "",
        price: 0,
      };
      this.work_cost.hour_conditions.push(hour);
    },

    // remove row
    remove_row(i, id) {
      if (id) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `company/employees/workCosts/${id}`,
              method: "delete",
            },
          })
      }
      this.work_cost.hour_conditions.splice(i, 1);
    },
    //-------------- save work hours
    save_work_hours() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "company/employees/workCosts",
              method: "post",
            },
            data: this.work_cost,
          })
          .then((res) => {
            this.save_loader = false;
            this.get_employees();
            this.close_work_cost();
            this.snackbar = true;
          })
          .catch((err) => {
            this.save_loader = false;
            console.log(err.response);
          });
      }
    },
    /////////----------------- close work hours
    close_work_cost() {
      this.work_cost = Object.assign(
        {},
        {
          employee_id: null,
          hour_price: 0,
          day_price: 0,
          hour_conditions: [],
        }
      );
      this.work_const_dialog = false;
      this.$refs.form.resetValidation();
    },
  },
  created() {
    this.get_employees();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-swtich {
    .v-input__slot {
      justify-content: center;
    }
  }
}
</style>
