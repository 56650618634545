var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","hide-overlay":"","fullscreen":""},on:{"keydown":_vm.close_upload_dialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-0"},[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.comp == "products" ? _vm.keys.project_file_page_add_images_title : _vm.keys.project_file_page_add_file_title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.progress != 0,"icon":"","x-large":""},on:{"click":_vm.close_upload_dialog}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('v-file-input',{ref:"upload_input",attrs:{"hide-details":"","loading":_vm.progress > 0,"label":_vm.comp == 'products'
            ? _vm.keys.project_file_page_add_images_input
            : _vm.keys.project_file_page_add_file_input},on:{"change":function($event){return _vm.upload_file()}},model:{value:(_vm.input_file),callback:function ($$v) {_vm.input_file=$$v},expression:"input_file"}}),(_vm.progress != 0 && _vm.progress != 100)?_c('v-progress-linear',{staticClass:"mt-2",attrs:{"striped":"","color":"light-blue","active":_vm.progress != 0 && _vm.progress != 100},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}):_vm._e(),_c('v-row',{staticClass:"mt-5"},_vm._l((_vm.attachments),function(file,ind){return _c('v-col',{key:ind,staticClass:"position-relative text-center",attrs:{"cols":"6","sm":"2"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[(_vm.files_types[ind] == 'pdf')?_c('img',{attrs:{"src":require("@/assets/files_icon/pdf-file.png"),"width":"100"}}):(
                  _vm.files_types[ind] == 'docx' || _vm.files_types[ind] == 'doc'
                )?_c('img',{attrs:{"width":"100","src":require("@/assets/files_icon/doc.png")}}):(
                  _vm.files_types[ind] == 'png' ||
                  _vm.files_types[ind] == 'jpg' ||
                  _vm.files_types[ind] == 'jpeg' ||
                  _vm.files_types[ind] == 'jfif' ||
                  _vm.files_types[ind] == 'WebP'
                )?_c('img',{attrs:{"width":"100","src":require("@/assets/files_icon/image.png")}}):_c('img',{attrs:{"width":"100","src":require("@/assets/files_icon/file.png")}})]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"rgba(0, 0, 0, 0.54)","download":"","href":file.path,"icon":""}},[_c('v-icon',[_vm._v("file_download")])],1),(
                  _vm.files_types[ind] == 'png' ||
                  _vm.files_types[ind] == 'jpg' ||
                  _vm.files_types[ind] == 'jpeg' ||
                  _vm.files_types[ind] == 'jfif' ||
                  _vm.files_types[ind] == 'WebP' ||
                  _vm.files_types[ind] == 'pdf'
                )?_c('v-btn',{attrs:{"href":file.path,"icon":""}},[_c('v-icon',[_vm._v("visibility")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.delete_file(file.file_id, ind)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }