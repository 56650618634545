<template>
  <v-container>
    <div class="wrap text-center">
      <div>
        <v-img
          max-width="200"
          class="d-inline-block logo mt-4"
          src="@/assets/logo.png"
        ></v-img>
      </div>

      <v-card elevation="0" class="d-inline-block my-10" outlined>
        <v-card-text class="orange--text accent-1--text text-h4">
          $30,000
        </v-card-text>
      </v-card>

      <p class="text font-weight-bold mb-10">
        ليك دفعة من شركة اني بالمبلغ الذي أمامك للموافقة علي الدفعة يرجي ارفاق
        فاتورة نفس المبلغ ادناه
      </p>
      <v-card class="file mb-4" elevation="0" outlined>
        <v-card-text>
          <v-file-input
            label="اضغط لأرفاق فاتورة "
            outlined
            hide-details
            prepend-icon=""
            append-icon="image"
          ></v-file-input>
          <v-btn color="secondary" class="mt-2 w-100"  height="50" depressed>ارسال الفاتورة</v-btn>
        </v-card-text>
      </v-card>

      <p class="text font-weight-bold mb-10">تفاصيل الدفعة :</p>
    </div>
    <v-card outlined elevation="0" loading class="mb-10">
      <v-card-title>
        المضخات
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        disable-sort
        class=""
        hide-default-footer
        :mobile-breakpoint="0"
        :headers="headers"
        :items="ordersWithIndex"
        :items-per-page="5"
      >
        <div slot="footer">
          <div
            class="data-table-total flex-wrap d-flex w-100 py-5 px-5 align-end justify-space-between"
          >
            <v-list dense disabled>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-end"
                      v-text="item.val"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </v-data-table>
    </v-card>
    <v-card outlined elevation="0" loading class="mb-10">
      <v-card-title>
        باطون
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        disable-sort
        class=""
        hide-default-footer
        :mobile-breakpoint="0"
        :headers="headers"
        :items="ordersWithIndex"
        :items-per-page="5"
      >
        <div slot="footer">
          <div
            class="data-table-total flex-wrap d-flex w-100 py-5 px-5 align-end justify-space-between"
          >
            <v-list dense disabled>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold text-end"
                      v-text="item.val"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped>
.wrap {
  max-width: 300px !important;
  text-align: center;
  margin: 0 auto;
}


</style>
<script>
export default {
  name: "invoiceRequest",
  data() {
    return {
      logo: "@/assets/logo.png",
      headers: [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "المضخة", value: "head2", align: "center" },
        { text: "كمية الضخ	", value: "head3", align: "center" },
        { text: "كمية بعد 10 كوب	", value: "head4", align: "center" },
        { text: "تكلفة كل كوب اضافي	", value: "head5", align: "center" },
        { text: "اجمالي تكلفة الاضافات		", value: "head6", align: "center" },
        { text: "تكلفة الافتتاحية", value: "head7", align: "center" },
      ],
      desserts: [
        {
          head2: 159,
          head3: 6.0,
          head4: 24,
          head5: 4.0,
          head6: "15-20-2020",
          head7: "1%",
        },
        {
          head2: 159,
          head3: 6.0,
          head4: 24,
          head5: 4.0,
          head6: "15-20-2020",
          head7: "1%",
        },
        {
          head2: 159,
          head3: 6.0,
          head4: 24,
          head5: 4.0,
          head6: "15-20-2020",
          head7: "1%",
        },
        {
          head2: 159,
          head3: 6.0,
          head4: 24,
          head5: 4.0,
          head6: "15-20-2020",
          head7: "1%",
        },
      ],
      selectedItem: 2,
      items: [
        { text: "اجمالي حسب ستاتوس", val: "20" },
        { text: "اجمالي حسب ستاتوس", val: "20" },
        { text: "الأجمالي", val: "20" },
      ],
    };
  },
  computed: {
    ordersWithIndex() {
      return this.desserts.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
};
</script>
