<template>
  <v-card outlined class="mb-10">
    <v-card-text>
      <v-tabs
        v-if="project_type_value == 0"
        class="mb-3"
        :value="tabs"
        background-color="gray"
        @change="changetabs($event)"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab
          :value="tabs"
          v-for="(lang, s) in $store.getters.languages"
          :key="s"
        >
          {{ lang.native }}
        </v-tab>
      </v-tabs>
      <!--/tabs -->

      <v-tabs-items :value="tabs">
        <v-tab-item v-for="(lang, s) in $store.getters.languages" :key="s">
          <v-row
            :class="[
              { 'text-end': lang.direction == 'ltr' },
              { 'text-start': lang.direction == 'rtl' },
            ]"
          >
            <v-col cols="6" sm="4" v-if="tabs == 0">
              <v-select
                :value="project_type_value"
                @change="
                  $emit('selects', { value: $event, type: 'project_type' })
                "
                :items="project_type"
                item-text="text"
                item-value="value"
                :error="errors.project_type"
                :append-icon="errors.project_type ? 'error_outline' : ''"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ keys.project_file_page_name_project_type }}
                </template>
              </v-select>
            </v-col>
            <!--/ project type  (/col)-->

            <v-col cols="6" sm="4" v-if="project_type_value == 1">
              <v-select
                item-text="name"
                item-value="id"
                :items="projects"
                :loading="projects.length == 0"
                :disabled="projects.length == 0"
                :value="project_selected"
                @change="
                  $emit('selects', { value: $event, type: 'project_id' })
                "
                :error="errors.project_id"
                :append-icon="errors.project_id ? 'error_outline' : ''"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ keys.project_file_page_name_project_select }}
                </template>
              </v-select>
            </v-col>
            <!--/ project id (/col)-->

            <v-col cols="6" sm="4" v-if="project_type_value == 0">
              <v-text-field
                :error="errors.project_name"
                :append-icon="errors.project_name ? 'error_outline' : ''"
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
                @keyup="$emit('set_proejct_name', {value: $event.target.value, locale:lang.locale})"
                :value="project_name[lang.locale]"
              >
                <template slot="label">
                  <strong class="red--text" v-if="tabs == 0">*</strong>
                  {{ keys.project_file_page_name_project_name }}
                </template>
              </v-text-field>
            </v-col>
            <!--/ project name (/col)-->

            <v-col v-if="tabs == 0" cols="6" sm="4">
              <v-select
                :label="keys.project_file_page_name_share_companies"
                item-text="name"
                item-value="id"
                :items="contacts"
                multiple
                :loading="contacts.length == 0"
                :disabled="contacts.length == 0"
                :value="contacts_ids"
                @change="
                  $emit('selects', { value: $event, type: 'companies_id' })
                "
                :error="errors.companies_ids"
                :append-icon="errors.companies_ids ? 'error_outline' : ''"
              ></v-select>
            </v-col>
            <!--/  companies_id (/col) -->
          </v-row>
          <!--/row -->
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <!--/card text -->
  </v-card>
  <!--/ V-CARD (Basic info ) -->
</template>

<script>
export default {
  props: [
    "project_type",
    "keys",
    "project_type_value",
    "project_selected",
    "contacts_ids",
    "errors",
    "tabs",
    "project_name"
  ],
  name: "basicInfo",
  data() {
    return {};
  },
  computed: {
    projects() {
      return this.$store.state.pages_data.projects || [];
    },
    contacts() {
      return this.$store.state.pages_data.companies || [];
    },
  },
  methods: {
    // get all projects
    get_projects() {
      this.$store.dispatch("public__request", {
        config: {
          method: "get",
          url: "company/projects",
        },
        get: "projects",
      });
    },
    // contacts
    get_contacts() {
      this.$store.dispatch("public__request", {
        config: {
          url: `/company/contacts/list`,
          method: "get",
        },
        get: "companies",
      });
    },
    changetabs(tab) {
      this.$emit("cheng_tabs", tab);
    },
  },
  mounted() {
    this.get_projects();
    this.get_contacts();
  },
};
</script>
