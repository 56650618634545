<template>
  <v-container>
    <gstc />
  </v-container>
</template>

<script>
import GSTCComponent from "@/components/GSTC.vue";
export default {
  name: "gantt",
  components: {
    gstc: GSTCComponent,
  },
};
</script>
