var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-10",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.keys.project_file_page_products_title))]),_vm._l((_vm.products),function(product,i){return _c('v-card-text',{key:i},[_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(product.tabs),callback:function ($$v) {_vm.$set(product, "tabs", $$v)},expression:"product.tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.$store.getters.languages),function(lang,s){return _c('v-tab',{key:s,model:{value:(product.tabs),callback:function ($$v) {_vm.$set(product, "tabs", $$v)},expression:"product.tabs"}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2),_c('v-tabs-items',{model:{value:(product.tabs),callback:function ($$v) {_vm.$set(product, "tabs", $$v)},expression:"product.tabs"}},_vm._l((_vm.$store.getters.languages),function(lang,s){return _c('v-tab-item',{key:s},[_c('v-row',{staticClass:"align-center",class:[
              { 'text-end': lang.direction == 'ltr' },
              { 'text-start': lang.direction == 'rtl' } ]},[(product.tabs == 0)?_c('v-col',{staticClass:"text-end mt-3",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"error","depressed":""},on:{"click":function($event){return _vm.$emit('delete_item', {
                    type: 'products',
                    item_index: i,
                    id: product.id,
                  })}}},[_c('v-icon',[_vm._v("delete")])],1)],1):_vm._e(),(product.tabs == 0)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-select',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"error":product.errors.company && product.tabs == 0,"append-icon":product.errors.company && product.tabs == 0
                    ? 'error_outline'
                    : '',"item-text":"name_locale","item-value":"id","items":_vm.sales_companies,"disabled":_vm.sales_companies.length == 0,"loading":_vm.sales_companies.length == 0},model:{value:(product.product_company_id),callback:function ($$v) {_vm.$set(product, "product_company_id", $$v)},expression:"product.product_company_id"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.project_file_page_select_company)+" ")]),_c('template',{slot:"prepend"},[_c('v-btn',{attrs:{"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.dialog_company = true}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"error":product.errors.product_name && product.tabs == 0,"append-icon":product.errors.product_name && product.tabs == 0
                    ? 'error_outline'
                    : ''},on:{"keyup":function($event){return _vm.$emit('products_inputs', {
                    value: $event.target.value,
                    type: 'name',
                    locale: lang.locale,
                    index: i,
                  })}}},[_c('template',{slot:"label"},[(product.tabs == 0)?_c('strong',{staticClass:"red--text"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.keys.project_file_page_product_name)+" ")])],2)],1),(product.tabs == 0)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":("" + (product.images.length) + (_vm.keys.project_file_page_files_count))},on:{"click":function($event){return _vm.$emit('attach', {
                    comp: 'products',
                    index: i,
                    prop: 'images',
                  })}}},[_c('template',{slot:"prepend"},[_c('v-btn',{attrs:{"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('attach', {
                        comp: 'products',
                        index: i,
                        prop: 'images',
                      })}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1):_vm._e(),(product.tabs == 0)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"error":product.errors.date && product.tabs == 0,"append-icon":product.errors.date && product.tabs == 0
                    ? 'error_outline'
                    : '',"prepend-inner-icon":"mdi-calendar","readonly":"","value":product.date},on:{"click":function($event){return _vm.dialog_date(i)}}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.project_file_page_product_date)+" ")])],2)],1):_vm._e(),(product.tabs == 0)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"error":product.errors.barcode && product.tabs == 0,"append-icon":product.errors.barcode && product.tabs == 0
                    ? 'error_outline'
                    : ''},on:{"keyup":function($event){return _vm.$emit('products_inputs', {
                    value: $event.target.value,
                    type: 'barcode',
                    index: i,
                  })}}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.project_file_page_product_barcode)+" ")])],2)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-textarea',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"error":product.errors.description && product.tabs == 0,"append-icon":product.errors.description && product.tabs == 0
                    ? 'error_outline'
                    : '',"rows":"1"},on:{"keyup":function($event){return _vm.$emit('products_inputs', {
                    value: $event.target.value,
                    type: 'description',
                    locale: lang.locale,
                    index: i,
                  })}}},[_c('template',{slot:"label"},[(product.tabs == 0)?_c('strong',{staticClass:"red--text"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.keys.project_file_page_product_details)+" ")])],2)],1)],1)],1)}),1)],1)}),_c('v-card-actions',{staticClass:"d-block text-end pa-8"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('new_item', { type: 'products' })}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2),_c('v-dialog',{attrs:{"persistent":"","width":"290px"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save_date()}}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }