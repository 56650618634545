var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.tbody)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.tbody)?_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.keys.c_companies_page_title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'c_add_companies_page' },"color":"primary","depressed":""}},[_vm._v(_vm._s(_vm.keys.c_companies_page_add_btn))])],1),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.tbody,"mobile-breakpoint":"0","headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"me-2",attrs:{"width":"40","src":item.image}})]}},{key:"item.services",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:[
            { 'black--text': item.added_services == 0 },
            { 'primary--text': item.added_services == 1 } ],attrs:{"text":"","to":{ name: 'c_add_services_page', query: { id: item.id } }}},[_c('v-chip',{staticClass:"px-2 me-2",class:[
              { primary: item.added_services != 0 },
              { gray: item.added_services == 0 } ],attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.added_services))]),_vm._v(" "+_vm._s(_vm.keys.c_companies_page_services)+" ")],1)]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("block")])],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.get_deletd_id(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center d-block"},[_vm._v(_vm._s(_vm.keys.c_companies_page_delete_popup_title)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"loading":_vm.delete_loader,"disabled":_vm.delete_loader,"depressed":"","color":"error"},on:{"click":function($event){return _vm.delete_company()}}},[_vm._v(_vm._s(_vm.keys.c_companies_page_delete_popup_delete_btn))]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.keys.c_companies_page_delete_popup_close_btn))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }