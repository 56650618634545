<template>
  <v-container>
    <h3 class="mb-3">{{ keys.send_service_data_page_name }}</h3>
    <div v-if="page_loader">
      <v-skeleton-loader v-bind="attrs" type="article"> </v-skeleton-loader>
      <v-skeleton-loader v-bind="attrs" type="article,actions">
      </v-skeleton-loader>
    </div>
    <div v-if="!page_loader">
      <v-snackbar color="error" :timeout="3000" right v-model="alert">
        {{
          page_problem
            ? keys.send_service_data_page_requset_problem
            : keys.send_service_data_page_required
        }}
        <template v-slot:action="{ attrs }">
          <v-icon v-bind="attrs"> error </v-icon>
        </template>
      </v-snackbar>

    <pre>
      {{services}}
    </pre>

      <v-card class="mb-4" outlined elevation="0">
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                :disabled="companies.length == 0 || services.length != 0"
                :loading="companies.length == 0"
                item-text="name"
                item-value="id"
                v-model="contact_id"
                :items="companies"
                class="me-4"
                hide-details
                @change="get_services()"
                :error="contact_id_err"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ keys.send_service_data_page_company_select }}
                </template>
              </v-select>
            </v-col>

            <v-col cols="4">
              <v-select
                :disabled="projects.length == 0"
                item-text="name"
                v-model="project_id"
                item-value="id"
                :items="projects"
                class="me-4"
                hide-details
                :error="projects_err"
                @change="choose_project($event)"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ keys.send_service_data_page_projects_select }}
                </template>
              </v-select>
            </v-col>

            <v-col cols="4">
              <v-select
                :disabled="companies.length == 0"
                item-text="text"
                v-model="service_status_val"
                item-value="value"
                :items="service_status"
                class="me-4"
                hide-details
                @change="change_status"
              >
                <template slot="label">
                  <strong class="error--text">*</strong>
                  {{ keys.send_service_data_page_status_label }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div>
        <v-card
          class="mb-4"
          v-for="(service, service_index) in services"
          :key="service_index"
          elevation="0"
          outlined
        >
          <v-card-title>
            <v-row class="align-end">
              <v-col cols="6" sm="3">
                <v-select
                  :items="contact_services[0] ? contact_services[0] : []"
                  item-text="name"
                  item-value="id"
                  class="me-4"
                  v-model="services[service_index].service_id"
                  @change="set_braches(service_index)"
                  :error="service.errors.service_id"
                  :prepend-icon="
                    service.errors.service_id ? 'error_outline' : ''
                  "
                  hide-details
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    {{ keys.send_service_data_page_service_select }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6" sm="3" v-if="service.service_type == 'default'">
                <v-select
                  :items="departments"
                  v-model="service.department_id"
                  class="me-4"
                  item-text="name"
                  item-value="id"
                  :error="service.errors.department_id"
                  hide-details
                  :prepend-icon="
                    service.errors.department_id ? 'error_outline' : ''
                  "
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    {{ keys.send_service_data_page_department_select }}
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="3"
                v-if="!service.one_branch && service.service_id"
              >
                <v-select
                  :items="
                    services_bracnhes[service_index]
                      ? services_bracnhes[service_index]
                      : []
                  "
                  v-model="service.branch_id"
                  class="me-4"
                  item-text="name"
                  item-value="id"
                  :prepend-icon="
                    service.errors.branch_id ? 'error_outline' : ''
                  "
                  :error="service.errors.branch_id"
                  :disabled="
                    !services_bracnhes[service_index] ||
                    services_bracnhes[service_index].length == 0
                  "
                  hide-details
                >
                  <template slot="label">
                    <strong class="error--text">*</strong>
                    {{ keys.send_service_data_page_branch_select }}
                  </template>
                </v-select>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  @click="delete_service_popup(service.id, service_index)"
                  depressed
                  color="error"
                  x-small
                  fab
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <!--/ card title -->
          <v-card-text>
            <v-alert
              v-if="service.errors.alert"
              class="text-center"
              color="error"
              border-left
              dense
              outlined
            >
              {{ keys.send_service_data_page_insert_row }}
            </v-alert>
            <v-tabs
              v-if="!service.has_price || service.service_type == 'default'"
              class="mb-3"
              v-model="service.tabs"
              background-color="gray"
            >
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab
                v-model="service.tabs"
                v-for="(lang, la) in languages"
                :key="la"
              >
                {{ lang.native }}
              </v-tab>
            </v-tabs>
            <!--/tabs -->

            <v-tabs-items v-model="service.tabs">
              <v-tab-item v-for="(lang, la) in languages" :key="la">
                <v-row
                  class="align-end ps-3 mb-1"
                  v-for="(detail, s) in service.details"
                  :key="s"
                  :dir="lang.direction"
                  :class="[
                    { 'text-end': lang.direction == 'ltr' },
                    { 'text-start': lang.direction == 'rtl' },
                  ]"
                >
                  <v-col cols="3" v-if="service.has_price">
                    <v-select
                      v-model="detail.service_detail_id"
                      item-text="description"
                      item-value="service_detail_id"
                      hide-details
                      :items="detail.items"
                      :error="detail.error"
                      :prepend-icon="detail.error ? 'error_outline' : ''"
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ keys.send_service_data_page_service_type_select }}
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="3"
                    v-if="
                      !service.has_price || service.service_type == 'default'
                    "
                  >
                    <v-text-field
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                      @blur="
                        (ev) =>
                          $set(detail.description, lang.locale, ev.target.value)
                      "
                      :value="detail.description[lang.locale]"
                      hide-details
                      :error="service.tabs == 0 ? detail.desc_err : null"
                      :prepend-icon="
                        service.tabs == 0
                          ? detail.desc_err
                            ? 'error_outline'
                            : ''
                          : ''
                      "
                    >
                      <template slot="label">
                        <strong v-if="service.tabs == 0" class="error--text"
                          >*</strong
                        >
                        {{ keys.send_service_data_page_description }}
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="3" v-if="service.tabs == 0">
                    <v-text-field
                      type="number"
                      @blur="(ev) => $set(detail, 'quantity', ev.target.value)"
                      :value="detail.quantity"
                      hide-details
                      :error="detail.quan_err"
                      :prepend-icon="detail.quan_err ? 'error_outline' : ''"
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ keys.send_service_data_page_quantity }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <!--/  quantity -->

                  <v-col
                    cols="3"
                    v-if="!service.has_price && service.tabs == 0"
                  >
                    <v-text-field
                      type="number"
                      :value="detail.price"
                      @blur="(ev) => $set(detail, 'price', ev.target.value)"
                      hide-details
                      :error="detail.price_err"
                      :prepend-icon="detail.price_err ? 'error_outline' : ''"
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                    >
                      <template slot="label">
                        <strong class="error--text">*</strong>
                        {{ keys.send_service_data_page_price }}
                      </template>
                    </v-text-field>
                  </v-col>

                  <!--/  quantity -->

                  <v-col
                    v-show="service.tabs == 0"
                    cols="3"
                    v-for="(field, ff) in detail.fields"
                    :key="ff"
                  >
                    <v-text-field
                      :type="field.type"
                      :value="field.value"
                      @blur="(ev) => $set(field, 'value', ev.target.value)"
                      v-if="field.type != 'file'"
                      hide-details
                      :error="field.error"
                      :prepend-icon="field.error ? 'error_outline' : ''"
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                    >
                      <template slot="label">
                        <strong v-if="field.is_required" class="error--text"
                          >*</strong
                        >
                        {{ field.description }}
                      </template>
                    </v-text-field>

                    <v-file-input
                      v-if="field.type == 'file'"
                      @change="upload_file($event, service_index, s, ff)"
                      hide-details
                      :error="field.error"
                      :prepend-icon="
                        field.error ? 'error_outline' : 'attach_file'
                      "
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                    >
                      <template slot="label">
                        <strong v-if="field.is_required" class="error--text"
                          >*</strong
                        >
                        {{ field.description }}
                      </template>
                    </v-file-input>

                    <v-progress-linear
                      class="mt-2"
                      striped
                      color="light-blue"
                      v-model="field.progress"
                      :active="field.progress != 0 && field.progress != 100"
                      v-if="field.progress != 0 && field.progress != 100"
                    ></v-progress-linear>
                  </v-col>

                  <template v-for="addition in detail.additions">
                    <v-col
                      cols="3"
                      :key="addition.id"
                      v-if="!addition.is_public"
                    >
                      <v-select
                        :items="addition.children"
                        item-text="name"
                        item-value="id"
                        hide-details
                        :label="addition.name"
                        v-model="addition.child_id"
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="3"
                      :key="addition.id"
                      v-if="addition.is_public"
                    >
                      <v-text-field
                        v-if="addition.related_to == 3"
                        type="number"
                        min="0"
                        :label="addition.name"
                        hide-details
                        :value="addition.value"
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        @blur="(ev) => $set(addition, 'value', ev.target.value)"
                      ></v-text-field>

                      <v-checkbox
                        v-if="addition.related_to != 3"
                        :label="addition.name"
                        v-model="addition.used"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </template>

                  <v-col cols="1" v-if="service.tabs == 0">
                    <v-btn
                      color="error"
                      icon
                      @click="delete_row(service_index, s, detail.id)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <div class="text-end">
              <v-btn
                @click="new_row(service_index)"
                color="blue-grey lighten-1"
                outlined
                depressed
                :disabled="!service.branch_id || !service.service_id"
                >{{ keys.send_service_data_page_new_row }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
        <!--/ card -->
      </div>

      <div class="d-flex justify-space-between my-5">
        <v-btn
          @click="new_service()"
          outlined
          color="primary"
          depressed
          :disabled="!contact_id || !project_id"
        >
          {{ keys.send_service_data_page_new_service }}</v-btn
        >
        <v-btn
          :disabled="save_loader || services.length == 0"
          :loading="save_loader"
          @click="send_data()"
          color="primary"
          depressed
          >{{ keys.send_service_data_page_save }}</v-btn
        >
      </div>

      <v-dialog max-width="300" v-model="delete_dialog">
        <v-card>
          <v-card-title class="text-center d-block"
            >{{ keys.send_service_data_page_delete_title }}
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn @click="delete_service()" depressed color="error">{{
              keys.send_service_data_page_delete_delete_btn
            }}</v-btn>
            <v-btn @click="delete_dialog = false" depressed>{{
              keys.send_service_data_page_delete_close_btn
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--/ delete dialog -->
    </div>
  </v-container>
</template>

<script>
export default {
  name: "send_service_data",
  data() {
    return {

      departments: [],
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },
      page_loader: this.$route.query.id ? true : false,
      delete_dialog: false,
      post_contacts_config: {
        url: `/company/contacts/list`,
        method: "get",
      },
      save_loader: false,
      contact_services: [],
      service_index: null,
      service_id: null,
      services_bracnhes: [],
      contact_id: null,
      project_id: null,
      contact_id_err: false,
      projects: [],
      projects_err: false,
      fields: [],
      additions: [],
      details: [],
      source: null,
      CancelToken: null,
      alert: false,
      page_problem: false,
      service_status_val: 0,
      tabs: 0,

      services: [],
    };
  },
  computed: {
    companies() {
      return this.$store.state.pages_data.companies || [];
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    languages() {
      return this.$store.getters.languages;
    },
    default_lang() {
      return this.languages.find((l) => l.is_default);
    },
    service_status() {
      return [
        {
          value: 0,
          text: this.keys.send_service_data_page_status_price_suggestion,
        },
        {
          value: 1,
          text: this.keys.send_service_data_page_status_pending,
        },
        {
          value: 2,
          text: this.keys.send_service_data_page_status_on_way,
        },
        {
          value: 3,
          text: this.keys.send_service_data_page_status_done,
        },
        {
          value: 4,
          text: this.keys.send_service_data_page_status_canceled,
        },
      ];
    },
  },
  methods: {
    get_departments() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "company/serviceData/departments",
          },
        })
        .then((res) => {
          res.data.forEach((e, i) => {
            this.$set(this.departments, i, e);
          });
        });
    },
    choose_project(ev) {
      if (this.service != 0) {
        this.services.forEach((p, i) => {
          this.$set(this.services[i], "project_id", this.project_id);
        });
      }
    },
    //-------- upload files
    upload_file(inp, service_index, detail_index, field_index) {
      const formData = new FormData();
      const vm = this;
      formData.append("file", inp);
      formData.append("path", "company");
      if (inp) {
        if (this.source) {
          this.source.cancel();
        }
        this.CancelToken = this.axios.CancelToken;
        this.source = this.CancelToken.source();
        var config = {
          method: "post",
          url: "https://app.kalko.cloud/api/file/upload",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.state.token}`,
          },
          cancelToken: this.source.token,
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            vm.services[service_index].details[detail_index].fields[
              field_index
            ].progress = percentCompleted;
          },
          data: formData,
        };

        this.axios(config)
          .then((res) => {
            vm.services[service_index].details[detail_index].fields[
              field_index
            ].value = res.data.path;
          })
          .catch((thrown) => {
            if (this.axios.isCancel(thrown)) {
              vm.services[service_index].details[detail_index].fields[
                field_index
              ].value = null;
              vm.services[service_index].details[detail_index].fields[
                field_index
              ].progress = 0;
            }
          });
      } else {
        this.source.cancel();
        vm.services[service_index].details[detail_index].fields[
          field_index
        ].value = null;
        vm.services[service_index].details[detail_index].fields[
          field_index
        ].progress = 0;
      }
    },
    //--------- services
    get_services() {
      if (this.services == 0) {
        this.$store
          .dispatch("public__request", {
            config: {
              method: "get",
              url: `/company/serviceData/services?contact_id=${this.contact_id}`,
            },
          })
          .then((res) => {
            this.$set(this.contact_services, 0, res.data);
            console.log(res);
            if (this.$route.query.id) {
              this.services.forEach((e, i) => {
                let service = this.contact_services[0].find(
                  (e) => e.id == this.services[i].service_id
                );
                this.$set(this.services_bracnhes, i, service.branches);
                if (service.branches.length == 1) {
                  this.$set(this.services[i], "one_branch", true);
                }
              });
            }
            this.page_loader = false;
          });
      }
    },

    // change status
    change_status() {
      this.services.forEach((e, i) => {
        this.$set(this.services[i], "status", this.service_status_val);
      });
    },

    // fields value
    general_fields() {},

    //----------- braches
    set_braches(service_index) {
      this.services[service_index].details.forEach((e, ind) => {
        if (e.id) {
          this.$store.dispatch("public__request", {
            config: {
              method: "delete",
              url: `/company/serviceData/detail/${e.id}`,
            },
          });
        }
      });

      this.$set(this.services[service_index], "details", []);
      this.$set(this.services_bracnhes, service_index, []);
      this.$set(this.services[service_index], "branch_id", null);
      let service = this.contact_services[0].find(
        (e) => e.id == this.services[service_index].service_id
      );

      let get_main_branch = service.branches.find((e) => e.is_main);

      this.$set(this.services_bracnhes, service_index, service.branches);
      if (service.branches.length == 1 && service.branches[0].is_main) {
        this.$set(this.services[service_index], "one_branch", true);
        this.$set(
          this.services[service_index],
          "branch_id",
          get_main_branch.id
        );
      } else if (service.branches.length == 1) {
        let id = service.branches[0].id;
        this.$set(this.services[service_index], "one_branch", true);
        this.$set(this.services[service_index], "branch_id", id);
      } else {
        this.$set(this.services[service_index], "one_branch", false);
        this.$set(
          this.services[service_index],
          "branch_id",
          get_main_branch.id
        );
      }

      this.$set(
        this.services[service_index],
        "has_price",
        service.price.has_price
      );

      this.$set(
        this.services[service_index],
        "service_type",
        service.service_type
      );
    },

    //--------- companies
    get_companies() {
      if (this.companies.length == 0) {
        this.$store
          .dispatch("public__request", {
            config: this.post_contacts_config,
            get: "companies",
          })
          .then((res) => {
            this.companies_loader = false;
          });
      }
    },

    //------------- projects
    get_projects() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "company/projects",
          },
          get: "projects",
        })
        .then((res) => {
          res.data.forEach((e, i) => {
            this.$set(this.projects, i, e);
          });
        });
    },

    //------ new live validation
    new_row_validation(service_index) {
      let errs = [];

      this.services[service_index].errors.alert = false;

      if (!this.contact_id) {
        this.contact_id_err = true;
        errs.push(service_index);
      } else {
        this.contact_id_err = false;
      }

      if (!this.services[service_index].service_id) {
        this.services[service_index].errors.service_id = true;
        errs.push(service_index);
      } else {
        this.services[service_index].errors.service_id = false;
      }

      if (!this.services[service_index].branch_id) {
        this.services[service_index].errors.branch_id = true;
        errs.push(service_index);
      } else {
        this.services[service_index].errors.branch_id = false;
      }

      if (errs.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    //----------------add new line
    new_row(service_index) {
      let details_items = [];
      let fields = [];
      let public_additions = [];
      let privite_addionts = [];

      if (this.new_row_validation(service_index)) {
        // get details items ----------------------------
        this.contact_services[0].forEach((e, i) => {
          if (e.id == this.services[service_index].service_id) {
            e.details.forEach((det, det_id) => {
              details_items.push({
                service_detail_id: det.service_detail_id,
                description: det.description,
              });
            });
            //     // get fields ----------------------------
            e.fields.forEach((f, f_in) => {
              fields.push({
                id: f.id,
                description: f.description,
                type: f.type,
                is_required: f.is_required,
                value: null,
                progress: 0,
                error: false,
              });
            });

            // get additions  ----------------------------
            e.additions.forEach((add, f_in) => {
              // console.log(f_in);
              // console.log(add);
              if (!add.is_public) {
                privite_addionts.push({
                  id: add.id,
                  name: add.name,
                  is_public: add.is_public,
                  related_to: add.related_to,
                  child_id: null,
                  children: [],
                });
                add.children.forEach((chil, chil_ind) => {
                  privite_addionts[privite_addionts.length - 1].children.push({
                    name: chil.name,
                    id: chil.id,
                  });
                });
              } else {
                add.children.forEach((chil, chil_ind) => {
                  public_additions.push({
                    id: chil.id,
                    name: chil.name,
                    is_public: add.is_public,
                    related_to: add.related_to,
                    used: false,
                    value: "",
                  });
                });
              }
            });
          }
        });

        let ind =
          this.services[service_index].details.length == 0
            ? 0
            : this.services[service_index].details.length;

        this.$set(this.services[service_index].details, ind, {
          fields: fields,
          items: details_items,
          additions: [...public_additions, ...privite_addionts],
          quantity: null,
          price: null,
          error: false,
          quan_err: false,
          desc_err: false,
          price_err: false,
          description: {},
          service_detail_id: null,
        });
      }
    },
    //--------------- delete line
    delete_row(service_index, details_index, id) {
      this.$store.dispatch("public__request", {
        config: {
          method: "delete",
          url: `/company/serviceData/detail/${id}`,
        },
      });
      this.services[service_index].details.splice(details_index, 1);
    },
    // add new service block
    new_service() {
      let service = {
        errors: {
          service_id: false,
          branch_id: false,
          alert: false,
          desc_err: false,
          department_id: false,
        },
        department_id: null,
        contact_id: this.contact_id,
        project_id: this.project_id,
        branch_id: null,
        service_id: null,
        has_price: true,
        order_id: this.$route.query.id ? this.$route.query.id : null,
        status: this.service_status_val,
        details: [],
        tabs: 0,
        one_branch: false,
      };

      if (this.contact_id && this.project_id) {
        this.$set(this.services, this.services.length, service);
        // this.services.push(service);
      }
      // this.$set(this.services, 1, service);
    },
    //---------- delete dialog service
    delete_service_popup(service_id, service_index) {
      this.service_index = service_index;
      this.service_id = service_id;
      this.delete_dialog = true;
    },
    //---------- delete service
    delete_service() {
      if (this.$route.query.id && this.service_id) {
        this.$store.dispatch("public__request", {
          config: {
            url: `/company/serviceData/${this.service_id}`,
            method: "delete",
          },
        });
      }
      this.services.splice(this.service_index, 1);
      this.delete_dialog = false;
    },
    //--------- valdition all data
    send_valdiation() {
      let errs = [];
      this.services.forEach((serv, i) => {
        if (!this.contact_id) {
          this.contact_id_err = true;
          errs.push(i);
        } else {
          this.contact_id_err = false;
        }

        if (!serv.service_id) {
          this.services[i].errors.service_id = true;
          errs.push(i);
        } else {
          this.services[i].errors.service_id = false;
        }

        if (!serv.branch_id) {
          this.services[i].errors.branch_id = true;
          errs.push(i);
        } else {
          this.services[i].errors.branch_id = false;
        }

        if (serv.details.length == 0) {
          this.services[i].errors.alert = true;
          errs.push(i);
        } else {
          this.services[i].errors.alert = false;
        }
        if (serv.service_type == "default") {
          if (!serv.department_id) {
            this.services[i].errors.department_id = true;
            errs.push(i);
          } else {
            this.services[i].errors.department_id = false;
          }
        }

        serv.details.forEach((det, d) => {
          if (serv.service_type == "default" || !serv.has_price) {
            if (!det.price) {
              this.services[i].details[d].price_err = true;
              errs.push(i);
            } else {
              this.services[i].details[d].price_err = false;
            }
            if (!det.description[this.default_lang.locale]) {
              errs.push(i);
              this.services[i].details[d].desc_err = true;
            } else {
              this.services[i].details[d].desc_err = false;
            }
          }

          if (serv.has_price) {
            if (!det.service_detail_id) {
              this.services[i].details[d].error = true;
              errs.push(i);
            } else {
              this.services[i].details[d].error = false;
            }
          }

          if (det.quantity == 0 || !det.quantity) {
            this.services[i].details[d].quan_err = true;
            errs.push(i);
          } else {
            this.services[i].details[d].quan_err = false;
          }

          // //--------------- fields
          det.fields.forEach((field, f) => {
            if (field.is_required) {
              if (!field.value) {
                this.services[i].details[d].fields[f].error = true;
                errs.push(i);
              } else {
                this.services[i].details[d].fields[f].error = false;
              }
            }
          });
        });
      });

      if (errs.length != 0) {
        this.page_problem = false;
        this.alert = true;
        return false;
      } else {
        this.alert = false;
        return true;
      }
    },
    // edit service
    edit_service(id) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `/company/serviceData/${id}`,
            method: "get",
          },
        })
        .then((res) => {
          this.order_id = parseInt(this.$route.query.id);
          this.project_id = parseInt(this.$route.query.project);
          this.contact_id = parseInt(this.$route.query.contact);

          let data = res.data;

          console.log("hello", data);
          this.get_services();
          this.services = Object.assign([], data);
        });
    },
    //-------- send data
    send_data() {
      if (this.send_valdiation()) {
        console.log(this.services);
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "/company/serviceData",
              method: "post",
            },
            data: { data: this.services },
          })
          .then((res) => {
            let vm = this;
            setTimeout(() => {
              vm.$router.push({
                name: "services_data_page",
              });
            }, 1500);
          })
          .catch((e) => {
            this.page_problem = true;
            this.save_loader = false;
            console.log(e.response);
          });
      }
    },
  },

  created() {
    this.get_projects();
    this.get_companies();
    this.get_departments();
    if (this.$route.query.id) {
      this.edit_service(this.$route.query.id);
    }
  },
};
</script>
