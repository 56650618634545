<template>
  <v-container>
    <v-card class="card" elevation="0" outlined>
      <v-card-title>مراحل المشروع</v-card-title>
    </v-card>

    <div class="py-4">
      <v-chip-group v-model="active" active-class="primary--text" column>
        <v-chip v-for="tag in new Array(5)" :key="tag"> project </v-chip>
      </v-chip-group>
    </div>

    <v-progress-linear class="rounded-pill" v-model="knowledge" height="25">
      <strong>{{ Math.ceil(knowledge) }}%</strong>
    </v-progress-linear>

    <ul class="tasks mt-10">
      <li class="tasks__task done">
        <v-card elevation="0" outlined>
          <v-card-text
            class="d-flex flex-wrap justify-center justify-sm-space-between align-center primary--text pb-0"
          >
            <h2 class="me-2">أسم المرحلة</h2>
            <div class="d-flex align-center">
              <v-icon color="primary">paid</v-icon>
              <v-chip
                text-color="white"
                color="green"
                class="mx-1 mx-2"
                style="height: 22px"
                >21231</v-chip
              >
              <h3>20/20/2020</h3>
            </div>
          </v-card-text>
          <v-card-text class="primary--text pb-0">
            <div
              class="d-flex justify-center justify-sm-space-between align-center font-weight-bold flex-wrap"
            >
              <v-dialog v-model="modal" max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    rounded
                    small
                    outlined
                    elevation="0"
                    color="primary"
                    class="mb-3 mb-md-0 font-weight-bold"
                    >عرض تفاصيل المرحلة</v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="font-weight-bold"
                    >تفاصيل المرحلة</v-card-title
                  >
                  <v-divider></v-divider>
                  <v-card-text class="pt-3">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Dolore doloremque culpa reprehenderit et blanditiis minus
                    nemo magni consequatur, necessitatibus facilis, harum quod
                    error eos enim beatae ad cupiditate laboriosam consequuntur.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="red"
                      class="font-weight-bold"
                      @click="modal = !modal"
                      >الغاء</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <p class="mb-0">08:9:2021 ( تم التسليم في الموعد )</p>
            </div>
          </v-card-text>
          <v-card-text class="pb-2">
            <v-chip-group multiple>
              <v-chip v-for="tag in new Array(5)" :key="tag"> #asdasd </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </li>
      <li class="tasks__task problem">
        <v-card elevation="0" outlined>
          <v-card-text
            class="d-flex flex-wrap justify-center justify-sm-space-between align-center red--text pb-0"
          >
            <h2 class="me-2">أسم المرحلة</h2>
            <div class="d-flex align-center">
              <h3>20/20/2020</h3>
            </div>
          </v-card-text>
          <v-card-text class="red--text pb-0">
            <div
              class="d-flex justify-center justify-sm-space-between align-center font-weight-bold flex-wrap"
            >
              <v-dialog v-model="modal" max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    rounded
                    small
                    outlined
                    elevation="0"
                    color="red"
                    class="mb-3 mb-md-0 font-weight-bold"
                    >عرض تفاصيل المرحلة</v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="font-weight-bold"
                    >تفاصيل المرحلة</v-card-title
                  >
                  <v-divider></v-divider>
                  <v-card-text class="pt-3">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Dolore doloremque culpa reprehenderit et blanditiis minus
                    nemo magni consequatur, necessitatibus facilis, harum quod
                    error eos enim beatae ad cupiditate laboriosam consequuntur.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="red"
                      class="font-weight-bold"
                      @click="modal = !modal"
                      >الغاء</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <p class="mb-0">08:9:2021 ( تم التسليم في الموعد )</p>
            </div>
          </v-card-text>
          <v-card-text class="pb-2">
            <v-chip-group multiple>
              <v-chip v-for="tag in new Array(5)" :key="tag"> #asdasd </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </li>
    </ul>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/styles/main.scss";
.tasks {
  list-style: none;
  padding: 0;
  margin-bottom: 100px;
  h2 {
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  h3 {
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  &__task {
    position: relative;
    margin-bottom: 100px;
    @media (max-width: 767px) {
      padding: 10px 10px;
      margin-bottom: 30px;
    }
    &:after {
      content: "";
      width: 14px;
      height: 102px;
      background-color: #e0e0e0;
      position: absolute;
      left: calc(50% - 7px);
      bottom: -102px;
      @media (max-width: 767px) {
        height: 50px;
        bottom: -40px;
      }
    }
    .v-card {
      border-width: 4px;
      position: relative;
    }
    &.done {
      &:after {
        background-color: color(primary);
      }
      .v-card {
        border-color: color(primary);
      }
    }
    &.problem {
      &:after {
        background-color: color(red);
      }
      .v-card {
        border-color: color(red);
      }
    }
    &:last-of-type {
      &:before {
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        background-color: color(gray1);
        left: calc(50% - 20px);
        bottom: -120px;
        @include radius(50%);
        z-index: 2;
        @include query(maxM) {
          bottom: -79px;
        }
      }
      &.done {
        &:before {
          background-color: color(primary);
        }
      }
      &.problem {
        &:before {
          background-color: color(red);
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "progressTasks",
  data() {
    return {
      active: 0,
      modal: false,
      knowledge: 20,
    };
  },
};
</script>
