<template>
  <div>
    <v-card class="mb-10" outlined v-if="data_loaded">
      <v-card-title class="d-flex">
        <span class="order-1"> {{ keys.work_times_page_title }}</span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.work_times_page_search"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>
        <v-btn depressed color="primary" class="order-3 order-sm-5" to="/times">
          {{ keys.work_times_page_add_btn }}</v-btn
        >
      </v-card-title>
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="tbody"
        :search="search"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            small
            :to="{ name: 'times_page', query: { time_id: item.id } }"
          >
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn icon small @click="get_delete_id(item.id)">
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!--/ card -->
  </div>
</template>
<script>
export default {
  name: "Table",
  props: ["tbody", "keys", "data_loaded"],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          value: "date",
          text: this.keys.work_times_page_date,
          align: "center",
        },
        {
          text: this.keys.work_times_page_project_name,

          value: "project_name",
          align: "center",
        },

        {
          value: "total",
          text: this.keys.work_times_page_total,
          align: "center",
        },
        {
          value: "actions",
          text: this.keys.work_times_page_actions,
          align: "center",
        },
      ];
    },
  },
  methods: {
    get_delete_id(id) {
      this.delete_dialog = true;
      this.delete_id = id;
    },
    delete_time() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/project/times/${this.delete_id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.delete_dialog = false;
          this.delete_loader = false;
          this.get_times();
        });
    },
  },
};
</script>
