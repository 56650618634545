<template>
  <div>
    <v-row class="thead d-none d-lg-flex justify-center">
      <v-col>الضريبة المضافة</v-col>
      <v-col>SKU</v-col>
      <v-col class="details d-none d-lg-block">تفاصيل</v-col>
      <v-col>كمية</v-col>
      <v-col>السعر</v-col>
      <v-col class="d-none d-lg-block">مجموع</v-col>
      <v-col class="d-none d-lg-block"></v-col>
    </v-row>

    <!-- /header of invoice-->

    <v-row class="tbody d-none d-lg-flex">
      <v-col>
        <v-select
          :items="taxDetails"
          class="ma-0 pa-0"
          hide-details
          style="font-size: 13px"
          v-model="emptyLine.tax"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="emptyLine.sku"
          class="ma-0 pa-0"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col class="d-none d-lg-block">
        <v-text-field
          v-model="emptyLine.details"
          class="ma-0 pa-0 details"
          hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="emptyLine.quantity"
          class="ma-0 pa-0"
          hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="emptyLine.price"
          class="ma-0 pa-0"
          hide-details="true"
        ></v-text-field>
      </v-col>
      <v-col class="d-none d-lg-block"></v-col>
      <v-col class="d-none d-lg-block">
        <v-tooltip bottom nudge-bottom="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              @click="lines.push(emptyLine)"
              color="primary"
              elevation="0"
              rounded
              >حفظ</v-btn
            >
          </template>
          <span>Save</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <!--/ inpus add for desktop -->

    <v-row class="tbody align-center" v-for="(a, i) in lines" :key="i">
      <v-col cols="2" class="d-lg-none">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-dialog scrollable max-width="500px" v-model="lines[i].dialog">
                <template
                  @onclick="menu = false"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    class="primary--text"
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>تعديل الصف</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="mt-3">
                      <v-col cols="6" md="6">
                        <v-select
                          v-model="a.tax"
                          :items="items"
                          :item-value="items.value"
                          :item-text="items.text"
                          label="الضريبة"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="a.sku"
                          label="SKU"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="a.details"
                          label="تفاصيل"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="a.quantity"
                          label="كمية"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="a.price"
                          label="السعر"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="lines[i].dialog = false"
                      >Close</v-btn
                    >
                    <v-btn
                      color="primary"
                      elevation="0"
                      @click="lines[i].dialog = false"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list-item>

            <v-list-item>
              <v-btn @click="lines.splice(i, 1)" icon small class="red--text">
                <v-icon small>delete</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col>
        <span class="fw-bold">{{ a.tax }}</span>
      </v-col>
      <v-col>
        <span class="fw-bold">{{ a.sku }}</span>
      </v-col>
      <v-col class="d-none d-lg-block">
        <span class="fw-bold details d-inline-block">{{ a.details }}</span>
      </v-col>
      <v-col>
        <span class="fw-bold">{{ a.quantity }}</span>
      </v-col>
      <v-col>
        <span class="fw-bold">{{ a.price }} ₪</span>
      </v-col>
      <v-col class="d-none d-lg-block nowrap"
        >{{ a.price * a.quantity }} ₪</v-col
      >
      <v-col class="d-none d-lg-block">
        <v-dialog scrollable max-width="500px" v-model="lines[i].dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" small icon>
              <v-icon small>edit</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>تعديل الصف</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="mt-3">
                <v-col cols="6" md="6">
                  <v-select
                    v-model="a.tax"
                    :items="items"
                    :item-value="items.value"
                    :item-text="items.text"
                    label="الضريبة"
                  ></v-select>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field v-model="a.sku" label="SKU"></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="a.details"
                    label="تفاصيل"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="a.quantity"
                    label="كمية"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field v-model="a.price" label="السعر"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="lines[i].dialog = false"
                >Close</v-btn
              >
              <v-btn
                color="primary"
                elevation="0"
                @click="lines[i].dialog = false"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn small icon>
          <v-icon @click="lines.splice(i, 1)" small color="red">delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!--/ date of invoice -->

    <div class="d-lg-none my-4">
      <v-dialog scrollable max-width="500px" v-model="addDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="min-width: 100%"
            v-bind="attrs"
            v-on="on"
            color="primary"
            class="my-3"
            outlined
            >اضافة
          </v-btn>
        </template>
        <v-card>
          <v-card-title>اضافة الصف</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="6" md="6">
                <v-select
                  :items="items"
                  :item-value="items.value"
                  :item-text="items.text"
                  label="الضريبة"
                  v-model="emptyLine.tax"
                ></v-select>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="SKU"
                  v-model="emptyLine.sku"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="تفاصيل"
                  v-model="emptyLine.details"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="كمية"
                  v-model="emptyLine.quantity"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  label="السعر"
                  v-model="emptyLine.price"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="addDialog = false">Close</v-btn>
            <v-btn
              color="primary"
              elevation="0"
              @click="lines.push(emptyLine), (addDialog = false)"
              >حفظ</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!--=========== start total -- ============= -->
    <v-row class="text-center">
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col>مجموع</v-col>
      <v-col class="nowrap">77.00 ₪</v-col>
      <v-col class="d-none d-lg-block"></v-col>
    </v-row>

    <v-row class="text-center align-center">
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col>
        <v-text-field style="min-width: 100px" label="تخفيض"></v-text-field>
      </v-col>
      <v-col>
        <v-select style="min-width: 100px" :items="items"></v-select>
      </v-col>
      <v-col class="nowrap">77.00 ₪</v-col>
      <v-col></v-col>
    </v-row>

    <v-row class="text-center">
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col class="nowrap">ضريبة القيمة المضافة</v-col>
      <v-col class="nowrap">₪ 13.08.00</v-col>
      <v-col></v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row class="text-center">
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col class="nowrap">المبلغ الاجمالي للدفع</v-col>
      <v-col class="font-weight-bold black--text nowrap">₪ 13.08.00</v-col>
      <v-col></v-col>
    </v-row>

    <!--=========== End total -- ============= -->

    <!-- {{total}} -->
  </div>
</template>

<style lang="scss" scoped>
.details {
  min-width: 300px;
}
</style>
<script>
export default {
  name: "invoiceDetails",

  data() {
    return {
      menu: false,
      addDialog: false,
      editMenu: false,
      taxDetails: ["قبل الضريبة", "شامل الضريبة"],
      all: this.total,
      items: [
        {
          text: "1",
          value: "1",
        },
      ],
      emptyLine: {
        dialog: false,
        tax: "قبل الضريبة",
        sku: null,
        details: null,
        quantity: null,
        price: null,
      },
      lines: [],
    };
  },
};
</script>
