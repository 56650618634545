<template>
  <v-container>
    <v-alert
      v-if="alerts.err"
      dense
      type="error"
      class="text-center mt-10"
      small
    >
      {{ keys.c_add_companies_page_error_cant_find }}
    </v-alert>

    <v-alert
      v-if="alerts.warning"
      dense
      type="warning"
      class="text-center mt-10"
      small
    >
      {{ keys.c_add_companies_page_error_existing }}
    </v-alert>
    <v-alert
      v-if="alerts.info"
      dense
      type="info"
      class="text-center mt-10"
      small
    >
      {{ keys.c_add_companies_page_error_request_sent }}
    </v-alert>

    <v-text-field
      rounded
      solo
      v-model="search"
      :label="keys.c_add_companies_page_search_input"
      color="white"
      hide-details
      class="mb-4 mt-10"
    ></v-text-field>
    <div class="text-center">
      <v-btn
        @click="$route.query.employee ? search_employee() : search_company()"
        class="ms-3 px-5"
        height="40"
        rounded
        x-large
        color="primary"
        :loading="search_loader"
        :disabled="search_loader"
        >{{ keys.c_add_companies_page_search_btn }}</v-btn
      >
    </div>

    <v-card class="mt-5" v-if="company" elevation="0" outlined>
      <v-card-text class="py-0 d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-avatar class="ma-3" size="60">
            <v-img :src="company.image"></v-img>
          </v-avatar>
          <div class="ms-3">
            <h2 class="black--text">{{ company.name }}</h2>
          </div>
        </div>
        <!--/ image & name & department  -->

        <div>
          <p v-if="done">
            {{ keys.c_add_companies_page_message_request_sent }}
            <v-icon color="success" large>check</v-icon>
          </p>
          <v-btn
            :loading="loader"
            v-if="!done"
            outlined
            small
            depressed
            color="primary"
            @click="send_request"
          >
            {{ keys.c_add_companies_page_send_request }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "add_company",
  data() {
    return {
      search_loader: false,
      loader: false,
      done: false,
      search: "",
      search_config: {
        method: "get",
        url: "",
      },
      send_config: {
        method: "post",
        url: "",
      },
      alerts: {
        err: false,
        info: false,
        warning: false,
      },
      search_config: {
        method: "get",
        url: "",
      },
      company: null,
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
  },
  methods: {
    search_company() {
      this.done = false;
      this.search_loader = true;
      this.search_config.url = `company/contacts?search_key=${this.search}`;

      this.$store
        .dispatch("public__request", {
          config: this.search_config,
        })
        .then((res) => {
          this.search_loader = false;
          this.company = null;
          Object.entries(this.alerts).forEach((e) => {
            this.alerts[e[0]] = false;
          });
          if (res.data.data.length != 0) {
            if (res.data.data[0].in_contact_list) {
              this.alerts.warning = true;
            } else if (res.data.data[0].request_sent) {
              this.alerts.info = true;
            } else {
              this.company = res.data.data[0];
            }
          } else {
            this.alerts.err = true;
          }
        });
    },
    search_employee() {
      this.done = false;
      this.search_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/employees/search?search_key=${this.search}`,
            method: "get",
          },
        })
        .then((res) => {
          this.search_loader = false;
          this.company = null;
          Object.entries(this.alerts).forEach((e) => {
            this.alerts[e[0]] = false;
          });
          if (res.data.length != 0) {
            if (res.data[0].in_contact_list) {
              this.alerts.warning = true;
            } else if (res.data[0].request_sent) {
              this.alerts.info = true;
            } else {
              this.company = res.data[0];
            }
          } else {
            this.alerts.err = true;
          }
        });
    },
    send_request() {
      this.loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.$route.query.employee
              ? `company/employees/add`
              : `company/contacts/add/${this.company.id}`,
            method: "post",
          },
          data: this.$route.query.employee
            ? { employee_id: this.company.id }
            : {},
        })
        .then((res) => {
          console.log(res);
          this.loader = false;
          this.done = true;
        });
    },
  },
};
</script>
