var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.buildings),function(building,i){return _c('v-card',{key:i,staticClass:"mb-5",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.keys.project_page_details_building_title)+" "+_vm._s(i + 1)+" ")]),(i != 0)?_c('v-btn',{staticClass:"white--text",attrs:{"elevation":"1","fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.$emit('delete_building', {
            building_index: i,
            building_id: building.id,
          })}}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(building.tabs),callback:function ($$v) {_vm.$set(building, "tabs", $$v)},expression:"building.tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.languages),function(lang,la){return _c('v-tab',{key:la,model:{value:(building.tabs),callback:function ($$v) {_vm.$set(building, "tabs", $$v)},expression:"building.tabs"}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2),_c('v-tabs-items',{model:{value:(building.tabs),callback:function ($$v) {_vm.$set(building, "tabs", $$v)},expression:"building.tabs"}},_vm._l((_vm.languages),function(lang,la){return _c('v-tab-item',{key:la},[_c('v-row',{class:[
              { 'text-left': lang.direction == 'ltr' },
              { 'text-right': lang.direction == 'rtl' } ],attrs:{"dir":lang.direction}},[(building.tabs == 0)?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-text-field',{attrs:{"disabled":"","label":_vm.keys.project_page_details_building_total_meter},model:{value:(_vm.total_space[i]),callback:function ($$v) {_vm.$set(_vm.total_space, i, $$v)},expression:"total_space[i]"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":building.description[lang.locale],"label":_vm.keys.project_page_details_building_notes},on:{"keyup":function($event){return _vm.text_inputs(
                    $event.target.value,
                    i,
                    'description',
                    lang.locale
                  )}}})],1),(building.tabs == 0)?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":building.meter_inside,"min":"0","type":"number","label":_vm.keys.project_page_details_building_meter_inside},on:{"keyup":function($event){return _vm.text_inputs($event.target.value, i, 'meter_inside')}}})],1):_vm._e(),(building.tabs == 0)?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":building.meter_outside,"type":"number","label":_vm.keys.project_page_details_building_meter_outside},on:{"keyup":function($event){return _vm.text_inputs($event.target.value, i, 'meter_outside')}}})],1):_vm._e(),(building.tabs == 0)?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":building.meter_stone_or_outside,"type":"number","label":_vm.keys.project_page_details_building_meter_stone},on:{"keyup":function($event){return _vm.text_inputs(
                    $event.target.value,
                    i,
                    'meter_stone_or_outside'
                  )}}})],1):_vm._e(),(building.tabs == 0)?_c('v-col',{attrs:{"md":"9","cols":"12"}},[_c('v-combobox',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"filter":_vm.filter,"value":building.tags,"hide-no-data":!_vm.search,"items":building.tags,"search-input":_vm.search,"label":_vm.keys.project_page_details_building_keywords,"multiple":"","small-chips":"","hide-selected":""},on:{"input":function($event){return _vm.update_tags($event, i)},"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("اضافة ")]),_c('v-chip',{attrs:{"color":"gray ighten-3","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
                var attrs = ref.attrs;
                var item = ref.item;
                var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.delete_tag(item, i, $event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$delete")])],1)],1):_vm._e()]}},{key:"item",fn:function(ref){
                var index = ref.index;
                var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{class:[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' } ],attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true)})],1):_vm._e()],1)],1)}),1),_vm._l((building.floors),function(floor,f){return _c('v-row',{key:f,staticClass:"align-end"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","value":f + 1,"disabled":"","label":_vm.keys.project_page_details_building_floor_no}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"value":floor.space,"prepend-icon":floor.space_err ? 'error_outline' : '',"error":floor.space_err,"type":"number","min":"0"},on:{"keyup":function($event){return _vm.text_floors_inputs($event.target.value, i, f, 'space')}}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.project_page_details_building_floor_space)+" ")])],2)],1),_c('v-col',{attrs:{"cols":"9","sm":"3"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","value":floor.roof_height,"label":_vm.keys.project_page_details_building_roof_hight},on:{"keyup":function($event){return _vm.text_floors_inputs($event.target.value, i, f, 'roof_height')}}})],1),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[(f != 0)?_c('v-btn',{attrs:{"color":"transparent","fab":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('delete_floor', {
                building_index: i,
                floor_index: f,
                floor_id: floor.floor_id,
              })}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1):_vm._e()],1)],1)}),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('new_floor', i)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("add")])],1)],2)],1)}),_c('v-btn',{staticClass:"mb-2",attrs:{"depressed":"","color":"gray"},on:{"click":function($event){return _vm.$emit('new_buidling')}}},[_vm._v(_vm._s(_vm.keys.project_page_details_building_new_building))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }