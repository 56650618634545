<template>
  <v-container>
    <v-card outlined>
      <v-card-title>
        <v-list dense>
          <v-list-item class="font-weight-regular">
            <v-list-item-content>
              <p>
                <b class="d-inline-block me-2">تاريخ الانشاء : </b> 20/20/2020
              </p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="font-weight-regular">
            <v-list-item-content>
              <p>
                <b class="d-inline-block me-2"> الايميل : </b> company@mail.com
              </p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="font-weight-regular">
            <v-list-item-content>
              <p><b class="d-inline-block me-2">رقم الهاتف: </b> 0101278797</p>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-spacer></v-spacer>
        <div class="image">
          <img src="https://placeimg.com/100/50/tech/any?1" />
          <p class="font-weight-medium">company name</p>
        </div>
      </v-card-title>

      <v-card-text>
        <h4 class="gray pa-2 black--text">Customer info</h4>
        <v-list dense>
          <v-list-item class="font-weight-regular">
            <v-list-item-content class="d-block">
              <img src="https://placeimg.com/100/50/tech/any?1" width="100" />
              <p class="font-weight-medium">company name</p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="font-weight-regular">
            <v-list-item-content>
              <p>
                <b class="d-inline-block me-2"> الايميل : </b> company@mail.com
              </p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="font-weight-regular">
            <v-list-item-content>
              <p><b class="d-inline-block me-2">رقم الهاتف: </b> 0101278797</p>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <h4 class="gray pa-2 black--text">Items</h4>
        <v-simple-table class="text-center">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Name</th>
                <th class="text-center">Unite cost</th>
                <th class="text-center">separate addition</th>
                <th class="text-center">quantity</th>
                <th class="text-center">Total row</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in desserts" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.unite }}</td>
                <td>{{ item.separate }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.total }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "order_report",
  data() {
    return {
      desserts: [
        {
          name: "Frozen Yogurt",
          unite: 300,
          separate: 20,
          quantity: 5,
          total: 159,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.image {
  line-height: 1.3;
}
</style>
