var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.tbody)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.tbody)?_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.keys.services_data_page_name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'send_service_data_page' },"color":"primary","depressed":""}},[_vm._v(_vm._s(_vm.keys.services_data_page_btn_add))])],1),_c('v-divider'),_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_vm._v("المستلم")]),_c('v-tab',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_vm._v("المفروض")])],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"items":_vm.tbody,"mobile-breakpoint":"0","headers":_vm.headers},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.company.name)+" ")]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project.name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice( item.created_at.indexOf("T") + 1, item.created_at.indexOf(".") ))+" "+_vm._s(item.created_at.slice(0, item.created_at.indexOf("T")))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 0)?_c('v-chip',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.keys.services_data_page_status_price_suggestion)}}):_vm._e(),(item.status == 1)?_c('v-chip',{attrs:{"small":"","color":"warning"},domProps:{"textContent":_vm._s(_vm.keys.services_data_page_status_pending)}}):_vm._e(),(item.status == 2)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"blue lighten-2","small":""},domProps:{"textContent":_vm._s(_vm.keys.services_data_page_status_on_way)}}):_vm._e(),(item.status == 3)?_c('v-chip',{attrs:{"color":"success","small":""},domProps:{"textContent":_vm._s(_vm.keys.services_data_page_status_done)}}):_vm._e(),(item.status == 4)?_c('v-chip',{attrs:{"color":"error","small":""},domProps:{"textContent":_vm._s(_vm.keys.services_data_page_status_canceled)}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
                name: 'send_service_data_page',
                query: {
                  id: item.order_id,
                  project: item.project.id,
                  contact: item.company.id,
                },
              },"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.get_deletd_id(item.order_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)})],1),_c('v-tab-item',[_vm._v(" table ")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center d-block"},[_vm._v(_vm._s(_vm.keys.services_data_page_delete_title)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"loading":_vm.delete_loader,"disabled":_vm.delete_loader,"depressed":"","color":"error"},on:{"click":function($event){return _vm.delete_company()}}},[_vm._v(_vm._s(_vm.keys.services_data_page_delete_btn))]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.keys.services_data_page_delete_close))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }