<template>
  <div>
    <pre>
      {{ tables[0].tables }}
    </pre>
    <v-container class="swiper-con">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide :key="v" v-for="(a, v) in analayze">
          <v-card
            elevation="0"
            class="analayze rounded-lg"
            :style="{
              'border-color': routeName == a.link.toLowerCase() ? a.color : '',
            }"
          >
            <router-link :to="a.link" class="analayze__body d-block">
              <div class="d-flex justify-space-between mb-5">
                <h2 class="h3 analayze__title" :style="{ color: a.color }">
                  {{ a.money }}
                </h2>
                <v-icon class="analayze__icon" :style="{ color: a.color }">
                  circle</v-icon
                >
              </div>
              <div class="d-flex justify-space-between">
                <span class="analayze__percent">{{ a.percent }} %</span>
                <h2 class="analayze__title analayze__title--text">
                  {{ a.text }}
                </h2>
              </div>
            </router-link>
          </v-card>
          <!--/card -->
        </swiper-slide>
        <!--/swiper slide -->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <!--/end analazye carousel -->
    </v-container>
    <!--/contaioner -->

    <v-container>
      <v-chip-group class="mb-5">
        <v-chip v-for="(a, i) in new Array(10)" :key="i">
          اجمالي الكهرباء (1000 جنيه )
        </v-chip>
      </v-chip-group>

      <!--/end analazye carousel -->
      <v-row>
        <v-col cols="12" v-for="(table, i) in tables[0].tables" :key="i">
          <v-card outlined elevation="0" loading>
            <v-card-title>
  
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              disable-sort
              class=""
              hide-default-footer
              :mobile-breakpoint="0"
              :items-per-page="5"
              :headers="table.headers"
              :items="table.data"
            >
              <div slot="footer">
                <div
                  class="data-table-total flex-wrap d-flex w-100 py-5 px-5 align-end justify-space-between"
                >
                  <v-list dense disabled>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in items" :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-bold"
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-bold text-end"
                            v-text="item.val"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <!--/v list -->
                  <v-tooltip bottom nudge-bottom="-5">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        v-bind="attrs"
                        v-on="on"
                        to="/login"
                        color="primary"
                        elevation="0"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>Add</span>
                  </v-tooltip>
                </div>
              </div>
            </v-data-table>
            <!--/data table -->
          </v-card>
          <!--/card -->
        </v-col>
        <!--/col -->
      </v-row>
      <!--/row -->
    </v-container>
    <!--/container -->
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/main.scss";
::v-deep {
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    @media (max-width: 580px) {
      width: 286px;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 35px;
    height: 35px;
    background-color: #fff !important;
    @include radius(50%!important);
    @include shadow(menu);
    margin: 0 !important;
    overflow: hidden;
    top: calc(50% - 16px);
    color: #333;
    &::after {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  .swiper-button-next {
    right: -15px;
    @media (max-width: 1399px) {
      right: -5px;
    }
  }
  .swiper-button-prev {
    left: -15px;
    @media (max-width: 1399px) {
      left: -5px;
    }
  }

  .swiper-container-rtl {
    .swiper-button-next {
      right: auto;
      left: -15px;
      @media (max-width: 1399px) {
        left: -5px;
        right: auto;
      }
    }
    .swiper-button-prev {
      left: auto;
      right: -15px;
      @media (max-width: 1399px) {
        left: auto;
        right: -5px;
      }
    }
  }
}
</style>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Home",
  data() {
    return {
      swiperOption: {
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
        },
      },
      analayze: [
        {
          text: "التفاصيل",
          percent: 30,
          color: "#00ffe7",
          money: 77,
          link: "home",
        },
        {
          text: "التحليلات",
          percent: 30,
          color: "#ffa500",
          money: 77,
          link: "charts",
        },
        {
          text: "مستلزمان",
          percent: 30,
          color: "#188320",
          money: 77,
          link: "project-details2",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "red",
          money: 77,
          link: "project-details3",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#0997b7",
          money: 77,
          link: "project-details4",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#095200",
          money: 77,
          link: "project-details5",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#857c17",
          money: 77,
          link: "project-details6",
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#188320",
          money: 77,
          link: "project-details7",
        },
      ],
      selectedItem: 2,
      tables: [
        {
          name: "",
          tables: [],
        },
      ],
      items: [
        { text: "اجمالي حسب ستاتوس", val: "20" },
        { text: "اجمالي حسب ستاتوس", val: "20" },
        { text: "الأجمالي", val: "20" },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ordersWithIndex() {
      return this.desserts.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
    routeName() {
      return this.$route.name.toLowerCase();
    },
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: `company/projects/details/${this.$route.query.id}`,
          },
          data: this.project,
        })
        .then((res) => {
          res.data.data.forEach((e, i) => {
            this.$set(this.tables, i, e);
          });
          this.set_header();
          console.log(res);
        });
    },
    set_header() {
      const trans_headers = [
        {
          value: "price",
          text: "price",
          align: "center",
        },
        {
          value: "quantity",
          text: "quantity",
          align: "center",
        },
        {
          value: "subtotal",
          text: "subtotal",
          align: "center",
        },
      ];
      this.tables.forEach((e, i) => {
        e.tables.forEach((t, s) => {
          trans_headers.forEach((element) => {
            this.tables[i].tables[s].headers.push(element);
          });
        });
      });
      console.log(this.tables);
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.get_data();
    } else {
      this.$router.push({ name: "projects_page" });
    }
  },
};
</script>
