<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="tbody.length == 0"
    ></v-skeleton-loader>

    <v-card outlined v-if="tbody.length != 0">
      <v-card-title class="d-flex">
        <span class="order-1"> {{ keys.sales_companies_page_name }}</span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.sales_companies_page_input_search"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <!--/ search filed -->
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>
        <v-btn
          @click="dialog = true"
          depressed
          color="primary"
          class="order-3 order-sm-5"
        >
          {{ keys.sales_companies_page_company_add_btn }}</v-btn
        >
        <!--/ add new companies -->
      </v-card-title>
      <!--/ card title -->
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="tbody"
        :search="search"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.actions`]="{ item, index }">
          <v-btn @click="get_company_id(item.id)" icon small>
            <v-icon small>delete</v-icon>
          </v-btn>
          <v-btn @click="edit_company(item, index)" icon small>
            <v-icon small>edit</v-icon>
          </v-btn>
        </template>
        <!--/template actions -->
      </v-data-table>
      <!--/ data table -->
    </v-card>
    <!--/ card -->

    <!--start  dialog -->
    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>
          {{ dialog_title }}
        </v-card-title>
        <!--/ card title -->
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-tabs class="mb-3" v-model="tabs" background-color="gray">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              v-model="tabs"
              v-for="(lang, i) in $store.getters.languages"
              :key="i"
            >
              {{ lang.native }}
            </v-tab>
          </v-tabs>
          <!--/tabs -->

          <v-form ref="form" v-model="form">
            <v-tabs-items v-model="tabs">
              <v-tab-item
                v-for="(lang, i) in $store.getters.languages"
                :key="i"
              >
                <v-text-field
                  :rules="tabs == 0 ? name_rules : []"
                  v-model="company.name[lang.locale]"
                >
                  <template slot="label">
                    <strong v-if="tabs == 0" class="red--text">*</strong>
                    {{ keys.sales_companies_page_company_name }}
                  </template>
                </v-text-field>
                <!--/ company name -->

                <!-- :rules="phone_rules" -->
                <v-text-field
                  v-model="company.phone"
                  v-show="tabs == 0"
                  class="my-5"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.sales_companies_page_company_phone }}
                  </template>
                </v-text-field>
                <!--/ company phone number -->

                <v-text-field
                  :rules="tabs == 0 ? address_rules : []"
                  v-model="company.address[lang.locale]"
                >
                  <template slot="label">
                    <strong v-if="tabs == 0" class="red--text">*</strong>
                    {{ keys.sales_companies_page_company_address }}
                  </template>
                </v-text-field>
                <!--/ company address -->
              </v-tab-item>
            </v-tabs-items>
          </v-form>
          <!--/ v- form -->
        </v-card-text>
        <!--/ card text -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="close()" small text color="red">الغاء</v-btn>
          <!--/ close form -->
          <v-btn
            :disabled="save_loading"
            :loading="save_loading"
            @click="valdation()"
            small
            color="primary"
            >حفظ</v-btn
          >
          <!--/ submit form -->
        </v-card-actions>
        <!--/card actions -->
      </v-card>
    </v-dialog>
    <!--/  dialog -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.sales_companies_page_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            :disabled="delete_loader"
            @click="delete_company()"
            depressed
            color="error"
            >{{ keys.sales_companies_page_delete_btn }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.sales_companies_page_delete_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "sales_companies",
  data() {
    return {
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      save_loading: false, // for save loading
      company_index: -1, // index compoany for know what is the request role (update or create )
      tabs: 0,
      form: null,
      dialog: false,
      search: "",
      headers: [
        {
          text: "اسم الشركة",
          value: "name_locale",
          align: "center",
        },
        {
          text: "رقم التليفون",
          value: "phone",
          align: "center",
        },
        {
          text: "عنوان الشركة",
          value: "address_locale",
          align: "center",
        },
        {
          text: "اجراءات",
          value: "actions",
          align: "center",
        },
      ],
      name_rules: [(v) => !!v || "اسم الخدمة مطلوب"],
      // phone_rules: [
      //   (v) =>
      //     /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      //       v
      //     ) || "رقم الهاتف غير صحيح",
      //   (v) => !!v || "رقم الهاتف مطلوب",
      // ],
      address_rules: [(v) => !!v || "address"],

      company: {
        name: {},
        address: {},
        phone: "",
      },
    };
  },
  watch: {
    dialog(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.close();
      }
    },
  },
  computed: {
    // set dialog title if it update or create
    dialog_title() {
      return this.company_index > -1
        ? this.keys.sales_companies_page_company_title_edit
        : this.keys.sales_companies_page_company_title;
    },
    keys() {
      // get all translation keys
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    tbody() {
      // gety companies from store
      return this.$store.state.pages_data.sales_companies || [];
    },
  },
  methods: {
    // get all companies
    get_companies() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "company/productCompany",
          },
          get: "sales_companies",
        })
        .then((res) => {
          console.log(res);
        });
    },

    // edit company  (set company_index for company index on table  & assign new values for company properites)
    edit_company(item, ind) {
      this.company_index = ind;
      this.company = {
        // assign new value for company properties
        id: item.id,
        name: item.name,
        phone: "",
        address: item.address,
      };
      this.dialog = true; // show form
    },
    // check if form is valid
    valdation() {
      if (this.$refs.form.validate()) {
        this.save_loading = true; // make save button loading
        this.$store
          .dispatch("public__request", {
            config: {
              method: this.company_index > -1 ? `put` : `post`, // check if the request for update or create
              url:
                this.company_index > -1
                  ? `company/productCompany/${this.company.id}`
                  : "company/productCompany",
            },
            data: this.company,
          })
          .then((res) => {
            this.get_companies(); // get updated after  request is don e
            this.close(); // close dialog
          });
      }
    },

    // get id company for delete
    get_company_id(id) {
      this.delete_id = id; // set id
      this.delete_dialog = true; // show delete dialog
    },
    delete_company() {
      if (this.delete_id) {
        // check if delete_id exist
        this.delete_loader = true; // make delete button loading
        this.$store
          .dispatch("public__request", {
            config: {
              method: "delete",
              url: `company/productCompany/${this.delete_id}`,
            },
          })
          .then((res) => {
            this.get_companies();
            this.delete_dialog = false;
            this.delete_loader = false;
          });
      }
    },
    close() {
      let company = {
        name: {},
        address: {},
        phone: "",
      };
      this.company = Object.assign({}, company); // make company properties  are  empty
      this.$nextTick(() => {
        this.dialog = false; // close dialog
        this.save_loading = false; // sotp save button loading
        this.company_index = -1; // reset company index
        this.$refs.form.reset(); // reset form fields
        this.$refs.form.resetValidation(); // reset form valdiation
      });
    },
    // close dialog
  },
  mounted() {
    //  call  this function before page load
    this.get_companies();
  },
};
</script>
