<template>
  <v-card elevation="0" outlined class="mb-5">
    <v-card-title class='font-weight-bold'>الفحوصات</v-card-title>
    <v-card-text>
      <v-row v-for="(check, ind) in arr" :key="ind">
        <v-col cols="12" v-if="ind  != 0">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class='py-0' v-if="ind != 0">
          <v-btn  @click="arr.splice(ind, 1)" elevation="1" fab small color="red" class='white--text'>
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
        <v-col md="3" cols="6">
          <v-select label="نوع الفحص" :items="items"></v-select>
        </v-col>

        <v-col md="3" cols="6">
          <v-dialog
            ref="dialog"
            v-model="inputDate.modal"
            :return-value.sync="inputDate.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="inputDate.date"
                label="Picker in dialog"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="inputDate.date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="inputDate.modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(inputDate.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col md="3" cols="6">
          <v-text-field label="ملاحظات"></v-text-field>
        </v-col>
        <v-col md="3" cols="6">
          <v-select label="ستاتيوس الفحص" :items="status"></v-select>
        </v-col>

        <v-col cols="12">
          <v-dialog
            transition="dialog-bottom-transition"
            hide-overlay
            fullscreen
            v-model="dialogFiles"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field value="عدد الملفات 5">
                <template v-slot:prepend>
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="1"
                    small
                    color="primary"
                    fab
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>

            <v-card class="rounded-0">
              <v-card-title class="font-weight-bold">
                جميع الملفات
                <v-spacer></v-spacer>
                <v-btn @click="dialogFiles = false" icon x-large>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-file-input label="اضافة ملف جديد"></v-file-input>
                <v-row class="mt-5">
                  <v-col
                    cols="3"
                    class="position-relative"
                    v-for="(file, ind) in files"
                    :key="ind"
                  >
                    <v-img class="overflow-visible" :src="file">
                      <v-btn
                        @click="files.splice(ind, 1)"
                        elevation="1"
                        :top="true"
                        fab
                        small
                        class="white--text"
                        color="red"
                        :absolute="true"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-img>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>

      </v-row>

      <div class="text-end">
        <v-btn @click="arr.push(1)" color="primary" outlined>
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "checkUp",
  data() {
    return {
      arr: [0],
      items: [1, 2, 3, 4],
      status: ["لم يتم بعد ", "تم بنجاح", "فشل الفحص"],
      dialogFiles: false,
      files: [
        "https://placeimg.com/200/200/any?1",
        "https://placeimg.com/200/200/any?2",
      ],
      inputDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        modal: false,
      },
    };
  },
};
</script>
