import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    def_path: "https://app.kalko.cloud",
    role: null,
    drawer: true,
    darkMode: false,
    company: true,
    noHeader: false,
    dataTableFooterProps: {
      showFirstLastPage: true,
      "items-per-page-text": "لكل صفحة",
    },
    contact_services: [],
    static_translations: [],
    token: localStorage.getItem("token") || null,
    user_obj: JSON.parse(JSON.stringify(localStorage.getItem("user_obj"))) || null,

    pages_data: {
      //requests
      request: null,
      // branches
      branches: null,
      // companies
      companies: null,
      //send data
      send_data: null,
      // projects
      projects: null,
      // checks
      checks: null,

      // sales companies
      sales_companies: null,
    },
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    company_id(state) {
      return JSON.parse(state.user_obj).employee.company_id || null;
    },
    page_trans_keys: (state) => (page_key) => {
      let page_keys = state.static_translations.find((e) => e.page == page_key);
      return page_keys || [];
    },
    user_role(state) {
      return JSON.parse(state.user_obj).employee.role || null;
    },
    user_info(state) {
      return JSON.parse(state.user_obj) || null
    },
    languages(state) {
      let langs = JSON.parse(state.user_obj).languages;
      let default_lang = JSON.parse(state.user_obj).languages.findIndex(
        (e) => e.is_default
      );
      let def = langs.splice(default_lang, 1);

      langs.unshift(def[0]);
      return langs;
    },
  },
  mutations: {
    user_obj(state, obj) {
      state.user_obj = obj;
      localStorage.setItem("user_obj", obj);
    },
    getTrns(state, static_trans) {
      state.static_translations.push(static_trans);
    },
    removeTrans(state, page_key) {
      let ind = state.static_translations.findIndex((e) => e.page == page_key);
      if (ind > -1) {
        state.static_translations.splice(ind, 1);
      }
    },
    login(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
      console.log(token);
    },
    logout(state) {
      state.token = null;
      localStorage.removeItem("user_obj");
      localStorage.removeItem("token");
      state.user_obj = null;
      state.static_translations = [];
      Object.keys(state.pages_data).forEach((e) => {
        state.pages_data[e] = null;
      });
    },
    toggleAsideMenu(state) {
      return (state.drawer = !state.drawer);
    },

    public__request(state, item) {
      state.pages_data[item.name] = item.data;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        var data = JSON.stringify({
          email: cred.email,
          password: cred.password,
        });
        var config = {
          method: "post",
          url: "company/auth/login",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then((res) => {
            const token = res.data.token;
            const user = JSON.stringify(res.data);
            context.commit("login", token);
            context.commit("user_obj", user);
            console.log(data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // public request
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };

        axios(config)
          .then((res) => {
            resolve(res);
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // translations
    async get_Static_Translate(context, cred) {
      let data = {
        side: "company",
        pages: [cred],
      };
      let config = {
        method: "post",
        url: "admin/settings/static_translation/side",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${context.state.token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let res = await axios(config);
      if (res.data[0] != undefined) {
        let check_trans = context.state.static_translations.findIndex(
          (e) => e.page == res.data[0].page
        );

        if (check_trans == -1) {
          context.commit("getTrns", res.data[0]);
        } else {
          context.state.static_translations.splice(check_trans, 1);
          context.commit("getTrns", res.data[0]);
        }
      }
    },
  },
  modules: {},
});
