<template>
  <v-card elevation="0" outlined class="mb-5">
    <v-card-title> {{ keys.project_page_accessories_tab_name }} </v-card-title>

    <v-card-text v-for="(acc, ind) in accessories" :key="ind">
      <v-tabs class="mb-3" v-model="acc.tabs" background-color="gray">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-model="acc.tabs" v-for="(lang, la) in languages" :key="la">
          {{ lang.native }}
        </v-tab>
      </v-tabs>
      <!--/tabs -->

      <v-tabs-items v-model="acc.tabs">
        <v-tab-item v-for="(lang, la) in languages" :key="la">
          <v-row
            class="mb-2"
            :dir="lang.direction"
            :class="[
              { 'text-end': lang.direction == 'ltr' },
              { 'text-start': lang.direction == 'rtl' },
            ]"
          >
            <v-col cols="12" class="py-0 mt-3 text-end" v-if="acc.tabs == 0">
              <v-btn @click="$emit('delete_accessories', {acc_index: ind, acc_id:acc.id})" elevation="1" fab small color="red" class="white--text">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
            <!-- / col -->

            <v-col cols="6" md="3">
              <v-text-field
                :value="acc.description[lang.locale]"
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
                :label="keys.project_page_accessories_desc"
                @keyup="
                  $emit('accessories_inputs', {
                    value: $event.target.value,
                    prop_name: 'description',
                    acc_index: ind,
                    locale: lang.locale,
                  })
                "
              ></v-text-field>
            </v-col>
            <!-- / col -->

            <v-col md="3" cols="6" v-if="acc.tabs == 0">
              <v-text-field
                v-model="acc.start_date"
                :label="keys.project_page_accessories_start_date"
                append-icon="mdi-calendar"
                @click="dialog_date(ind, 'start_date')"
                readonly
                hide-details
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
              ></v-text-field>
            </v-col>
            <!-- / col -->

            <v-col md="3" cols="6" v-if="acc.tabs == 0">
              <v-text-field
                v-model="acc.end_date"
                :label="keys.project_page_accessories_end_date"
                append-icon="mdi-calendar"
                @click="dialog_date(ind, 'end_date')"
                readonly
                hide-details
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
              ></v-text-field>
            </v-col>
            <!-- / col -->

            <v-col cols="6" md="3" v-if="acc.tabs == 0">
              <v-text-field
                :value="acc.amount"
                :prepend-icon="
                  acc.amount_err && acc.tabs == 0 ? 'error_outline' : ''
                "
                :error="acc.amount_err && acc.tabs == 0"
                hide-details
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
                type="number"
                @keyup="
                  $emit('accessories_inputs', {
                    value: $event.target.value,
                    prop_name: 'amount',
                    acc_index: ind,
                    locale: null,
                  })
                "
              >
                <template slot="label">
                  <strong class="red--text"> *</strong>
                  {{ keys.project_page_accessories_amount }}
                </template>
              </v-text-field>
            </v-col>
            <!-- / col -->

            <v-col cols="6" md="3" v-if="acc.tabs == 0">
              <v-select
                :value="acc.type"
                :prepend-icon="
                  acc.type_err && acc.tabs == 0 ? 'error_outline' : ''
                "
                :error="acc.type_err && acc.tabs == 0"
                hide-details
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
                :items="payment_type"
                item-text="text"
                item-value="value"
                @input="
                  $emit('accessories_inputs', {
                    value: $event,
                    prop_name: 'type',
                    acc_index: ind,
                    locale: null,
                  })
                "
              >
                <template slot="label">
                  <strong class="red--text"> *</strong>
                  {{ keys.project_page_accessories_amount_type }}
                </template>
              </v-select>
            </v-col>
            <!-- / col -->

            <v-col cols="12" sm="3" v-if="acc.tabs == 0">
              <v-text-field
                :value="acc.total"
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
                type="number"
                min="0"
                hide-details
                :label="keys.project_page_accessories_total"
                @keyup="
                  $emit('accessories_inputs', {
                    value: $event.target.value,
                    prop_name: 'total',
                    acc_index: ind,
                    locale: null,
                  })
                "
              >
              </v-text-field>
            </v-col>
            <!-- / col -->
            <v-col cols="12" sm="3" v-if="acc.tabs == 0">
              <v-select
                :value="acc.add_total"
                :class="[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' },
                ]"
                :label="keys.project_page_accessories_total_type"
                hide-details
                :items="total_type"
                item-text="text"
                item-value="value"
                @input="
                  $emit('accessories_inputs', {
                    value: $event,
                    prop_name: 'add_total',
                    acc_index: ind,
                    locale: null,
                  })
                "
              ></v-select>
            </v-col>
          </v-row>
          <!--/ row -->
        </v-tab-item>
        <!--/ tab item -->
      </v-tabs-items>
      <!--tabs items -->
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn @click="$emit('new_acc')" color="primary" outlined>
        <v-icon>add</v-icon>
      </v-btn>
    </v-card-actions>

    <v-dialog
      :retain-focus="false"
      ref="dialog"
      v-model="dialog"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false">
          {{ keys.project_page_date_close }}
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dialog.save(date), save_date()"
        >
          {{ keys.project_page_date_save }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: ["accessories", "keys"],
  name: "accessoriesProject",
  data() {
    return {
      date: null,
      dialog: false,
      acc_index: -1,
      date_type: "",
      arr: [0],
      items: ["monthly", "fixed"],
      totalText: "",
      start_date: "",
      end_date: "",
      inputDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        modal: false,
      },
    };
  },
  computed: {
    languages() {
      return this.$store.getters.languages;
    },
    payment_type() {
      return [
        {
          text: this.keys.projects_page_payment_type_monthly,
          value: "monthly",
        },
        {
          text: this.keys.projects_page_payment_type_fixed,
          value: "fixed",
        },
      ];
    },
    total_type() {
      return [
        {
          text: this.keys.projects_page_total_type_minus,
          value: false,
        },
        {
          text: this.keys.projects_page_payment_type_fixed,
          value: true,
        },
      ];
    },
  },
  methods: {
    dialog_date(acc_index, date_type) {
      this.dialog = true;
      this.acc_index = acc_index;
      this.date_type = date_type;
    },
    save_date() {
      this.$emit("accessories_inputs", {
        value: this.date,
        prop_name: this.date_type,
        acc_index: this.acc_index,
        locale: null,
      });
    },
  },
  created() {},
};
</script>
