<template>
  <div>
    <v-row v-if="!data_loaded">
      <v-col cols="12" sm="4" v-for="(a, i) in 3" :key="i">
        <v-skeleton-loader type="article"> </v-skeleton-loader>
      </v-col>

      <v-col cols="12">
        <v-skeleton-loader type="article"> </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="data_loaded">
      <v-col cols="12" sm="4" class="pa-2">
        <v-card elevation="0" class="stat" color="#847da4">
          <v-card-text class="text-center">
            <p class="stat__p">{{ keys.employee_profile_page_total_days }}</p>
            <h2 class="stat__h2 my-5">
              {{
                totals
                  ? parseFloat(totals.days.count.replace(/,/g, ""))
                  : "0.00"
              }}
            </h2>
            <p class="stat__p">
              {{ keys.employee_profile_page_total_cost }}
              {{ totals ? totals.days.total : "0.00" }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/ COL --->
      <v-col cols="12" sm="4" class="pa-2">
        <v-card class="stat" elevation="0" color="#f39a00">
          <v-card-text class="text-center">
            <p class="stat__p">{{ keys.employee_profile_page_total_hours }}</p>
            <h2 class="stat__h2 my-5">
              {{
                totals
                  ? parseFloat(totals.hours.count.replace(/,/g, ""))
                  : "0.00"
              }}
            </h2>
            <p class="stat__p">
              {{ keys.employee_profile_page_total_cost }} ({{
                totals ? totals.hours.total : "0.00"
              }})
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/ col -->
      <v-col cols="12" sm="4" class="pa-2">
        <v-card class="stat" elevation="0" color="#006bf3">
          <v-card-text class="text-center">
            <p class="stat__p">
              {{ keys.employee_profile_page_total_payments }}
            </p>
            <h2 class="stat__h2 my-5">
              {{
                totals
                  ? (
                      parseFloat(totals.hours.total.replace(/,/g, "")) +
                      parseFloat(totals.days.total.replace(/,/g, ""))
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00"
              }}
            </h2>
            <p class="stat__p">
              ({{
                totals
                  ? remining.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "0.00"
              }}) {{ keys.employee_profile_page_remaining_payments }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/ col -->
      <v-col cols="12" class="px-2 pt-2 pt-sm-0">
        <v-card color="primary" outlined="">
          <v-card-title>
            <h2 class="white--text">{{ keys.employee_profile_page_wallet }}</h2>
            <v-spacer></v-spacer>
            <h2 class="white--text">{{ wallet }}</h2>
          </v-card-title>
        </v-card>
        <!--/ card -->
      </v-col>
      <!--/ col -->
    </v-row>
    <v-row class="mt-0" v-if="data_loaded">
      <v-col cols="6" class="pa-2" v-for="(con, i) in conditions" :key="i">
        <v-card elevation="0" outlined>
          <v-card-title>
            <div>
              <p>
                {{ keys.employee_profile_page_total_conditions }} ({{
                  con.total
                }})
              </p>
              <p>{{ con.start_time }} - {{ con.end_time }}</p>
            </div>
            <v-spacer></v-spacer>
            <h2 class="font-weight-bold">{{ con.price }}</h2>
          </v-card-title>
        </v-card>
        <!--/ card -->
      </v-col>
      <!--col -->
    </v-row>
    <!--/ row -->
  </div>
</template>

<script>
export default {
  name: "Totals",
  props: ["totals", "data_loaded", "conditions", "keys", "wallet"],
  data() {
    return {
      data: [
        {
          price: 8,
          total: 20,
        },
        {
          price: 5,
          total: 24,
        },
        {
          price: 6,
          total: 65,
        },
        {
          price: 7,
          total: 23,
        },
      ],
    };
  },
  computed: {
    remining() {
      return (
        parseFloat(this.totals.hours.total.replace(/,/g, "")) +
        parseFloat(this.totals.days.total.replace(/,/g, "")) -
        this.totals.payments.total
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.stat {
  &__p,
  &__h2 {
    color: #fff !important;
  }
  &__p {
    font-size: 16px;
  }
  &__h2 {
    font-size: 40px;
  }
}
</style>
