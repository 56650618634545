<template>
  <v-container>
    <v-card outlined>
      <v-card-title class="d-flex">
        <span class="order-1">{{ keys.projects_files_page_name }} </span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.projects_files_page_search_input"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>

        <div class="order-3 order-sm-5">
          <v-btn depressed color="primary" :to="{ name: 'project_file_page' }">
            {{ keys.projects_files_page_add_files }}</v-btn
          >
          <v-btn
            class="ms-3"
            depressed
            color="primary"
            :to="{ name: 'sales_companies_page' }"
          >
            {{ keys.projects_files_page_add_company }}</v-btn
          >
        </div>
      </v-card-title>
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="tbody"
        :search="search"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template v-slot:[`item.actions`]>
          <v-btn icon small>
            <v-icon small>delete</v-icon>
          </v-btn>
          <v-btn icon small>
            <v-icon small>edit</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.projects_files_page_delete_tilte }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_branche()"
            depressed
            color="error"
            >{{ keys.projects_files_page_delete_btn }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.projects_files_page_delete_close
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "projects_files",
  data() {
    return {
      delete_dialog:false,
      delete_loader:false,
      search: "",
      tbody: [
        {
          name: "project name",
          creation_date: "2020-05-05",
          products: 15,
          companies: 6,
        },
      ],
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.projects_files_page_company_name,
          value: "name",
          align: "center",
        },
        {
          text: this.keys.projects_files_page_creation_date,
          value: "creation_date",
          align: "center",
        },
        {
          text: this.keys.projects_files_page_products,
          value: "products",
          align: "center",
        },
        {
          text: this.keys.projects_files_page_companies,
          value: "companies",
          align: "center",
        },
        {
          text: this.keys.projects_files_page_actions,
          value: "actions",
          align: "center",
        },
      ];
    },
  },
};
</script>
