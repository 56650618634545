<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field label="اسم الشركة"></v-text-field>
        </v-col>
        <!--/col company name -->
        <v-col cols="12" sm="6">
          <v-text-field label="اسم صاحب الشركة"></v-text-field>
        </v-col>
        <!--/col owner name -->
        <v-col cols="12" sm="6">
          <v-text-field label="رقم الهاتف"></v-text-field>
        </v-col>
        <!--/col company phone  -->

        <v-col cols="12" sm="6">
          <v-text-field label="رقم الواتساب"></v-text-field>
        </v-col>
        <!--/col company whatsapp   -->

        <v-col cols="12" sm="6">
          <v-text-field label="البلد"></v-text-field>
        </v-col>
        <!--/col company country    -->

        <v-col cols="12" sm="6">
          <v-text-field label="البريد الالكتروني"></v-text-field>
        </v-col>
        <!--/col company country    -->

        <v-col cols="12" sm="6">
          <v-text-field label="password" type="password"></v-text-field>
        </v-col>
        <!--/col  password -->
        <v-col cols="12" sm="6">
          <v-text-field label="password" type="password"></v-text-field>
        </v-col>
        <!--/col confirm password    -->

        <v-col cols="12" sm="6">
          <v-file-input label="لوجو الشركة"></v-file-input>
        </v-col>
        <!--/col confirm password    -->
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "companyFrom",
  data() {
    return {
      
    };
  },
};
</script>
