<template>
  <div>
    <v-container>
      <v-navigation-drawer color="white" v-model="navDraw" top fixed>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold"
              >فلتر النشطات</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" @click="navDraw = false" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>close</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
        <!--/ list item  -->
        <v-list-item>
          <v-list-item-content>
            <v-text-field label="الأسم"></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <!--/ list item  -->
        <v-list-item>
          <v-list-item-content>
            <v-dialog
              ref="dialog"
              v-model="inputDate.modal"
              :return-value.sync="inputDate.date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="inputDate.date"
                  label="Picker in dialog"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="inputDate.date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="inputDate.modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(inputDate.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-list-item-content>
        </v-list-item>
        <!--/ list item  -->
        <v-list-item>
          <v-list-item-content>
            <v-radio-group v-model="radioGroup">
              <v-radio
                v-for="a in n"
                :key="a"
                :label="`${a}`"
                :value="a"
              ></v-radio>
            </v-radio-group>
          </v-list-item-content>
        </v-list-item>
        <!--/ list item  -->
        <v-list-item>
          <v-list-item-content>
            <v-text-field label="رقم الفاتور"></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <!--/ list item  -->
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-text-field label="وصف الفاتورة"></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <!--/ list item  -->
        <v-list-item>
          <v-list-item-content>
            <v-select :items="[1, 23, 4]" label="نوع الحقل"></v-select>
          </v-list-item-content>
        </v-list-item>
        <!--/ list item  -->
        <v-list-item>
          <v-list-item-content>
            <h5>مبلغ الفاتورة:</h5>
            <v-col cols="6">
              <v-text-field
                :value="range[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                @change="$set(range, 0, $event)"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="range[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                @change="$set(range, 1, $event)"
              ></v-text-field>
            </v-col>

            <v-range-slider
              v-model="range"
              :max="max"
              :min="min"
              hide-details
              class="align-center"
            >
            </v-range-slider>
          </v-list-item-content>
        </v-list-item>
        <!--/ list item  -->
      </v-navigation-drawer>
      <!--/ navigation -->

      <v-tooltip bottom nudge-bottom="-5">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            color="primary"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="navDraw = !navDraw"
          >
            <v-icon>filter_alt</v-icon>
          </v-btn>
          <!--/ btn --> 
        </template>
        <span>Filter</span>
      </v-tooltip>
      <!--/ filter btn -->

      <v-card class="mt-5" flat elevation="0" outlined>
        <v-card-title>
          سجل النشاطات
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <!--/card title --> 
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          sort-by="calories"
          no-data-text="لا يوجد نتائج "
          no-results-text="لا يوجد نتائج بحث"
          :mobile-breakpoint="0"
          :footer-props="$store.state.dataTableFooterProps"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small icon :to="{ name: 'Home', query: { id: item.id } }">
              <v-icon small>visibility</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.activtyType`]="{ item }">
            <v-chip
              x-small
              class="white--text px-2"
              :class="[
                { 'red accent-1': item.activtyType == 0 },
                { primary: item.activtyType == 1 },
              ]"
            >
              {{ item.activtyType == 0 ? "حذف" : "تعديل" }}
            </v-chip>
          </template>
        </v-data-table>
        <!--/data table --> 
      </v-card>
      <!--/card --> 
    </v-container>
    <!--/container --> 
  </div>
</template>

<script>
export default {
  name: "activity",
  data: () => ({
    navDraw: false,
    search: "",
    dialog: false,
    dialogDelete: false,
    inputDate: {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      modal: false,
    },
    radioGroup: "حسب تاريخ الفاتورة",
    n: ["حسب تاريخ الفاتورة", "حسب تاريخ انشاء الفاتورة"],
    min: -50,
    max: 90,
    range: [-20, 70],
    headers: [
      { text: "ID", value: "id" },
      { text: "التاريخ", value: "date" },
      { text: "اسم المستخدم", value: "username" },
      { text: "نوع النشاط", value: "activtyType" },
      { text: " النشاط", value: "activty" },
      { text: "اجراءات", value: "actions", sortable: false },
    ],
    desserts: [
      {
        id: "1",
        date: "20/20/2020",
        username: "حسن احمد",
        activtyType: 0,
        activty: "موظف",
      },
      {
        id: "13",
        date: "20/20/2020",
        username: "حسن احمد",
        activtyType: 1,
        activty: "موظف",
      },
    ],
  }),
};
</script>
