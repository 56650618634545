<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!tbody"> </v-skeleton-loader>

  

    <v-card v-if="tbody" elevation="0" outlined>
      <v-card-title>
        {{ keys.c_branches_page_title }}
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500">
          <template v-slot:[`activator`]="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="primary" depressed>{{
              keys.c_branches_page_add_btn
            }}</v-btn>
          </template>
          <v-card>
            <v-card-title> {{ dialogTitle }} </v-card-title>
            <v-divider></v-divider>
            <!--/title -->
            <v-card-text>
              <v-tabs class="mb-3" v-model="tabs" background-color="gray">
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab
                  v-model="tabs"
                  v-for="(lang, i) in $store.getters.languages"
                  :key="i"
                >
                  {{ lang.native }}
                </v-tab>
              </v-tabs>
              <!--/tabs -->

              <v-tabs-items v-model="tabs">
                <v-tab-item
                  v-for="(lang, i) in $store.getters.languages"
                  :key="i"
                >
                  <v-text-field
                    :dir="lang.direction"
                    v-model="editItemObj.name[lang.locale]"
                    class="my-3"
                    hide-details
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.c_branches_page_branche_name }}
                    </template>
                  </v-text-field>
                  <!--/ text faild (name) -->
                </v-tab-item>
              </v-tabs-items>

              <v-switch hide-details v-model="editItemObj.is_active">
                <template slot="label">
                  {{
                    editItemObj.is_active
                      ? keys.c_branches_page_branche_active_status
                      : keys.c_branches_page_branche_status_inactive
                  }}
                </template>
                <!--/ template -->
              </v-switch>
              <!--/ switch -->
            </v-card-text>
            <!--/ card text -->

            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :loading="save_loader"
                @click="save()"
                depressed
                color="primary"
                >{{ keys.c_branches_page_popup_save_btn }}</v-btn
              >
              <v-btn @click="close()" text color="error">{{
                keys.c_branches_page_popup_close_btn
              }}</v-btn>
            </v-card-actions>
            <!--/card actions -->
          </v-card>
          <!--/ card -->
        </v-dialog>
        <!--/dialog -->

        <!--add bracnhe -->
      </v-card-title>
      <!--/ title -->
      <v-divider></v-divider>
      <v-data-table :items="tbody" mobile-breakpoint="0" :headers="headers">
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            x-small
            class="white--text px-2"
            :class="[
              { primary: item.is_active },
              { 'red accent-1': !item.is_active },
            ]"
          >
            {{ item.is_active ? "فعال" : "غير فعال" }}
          </v-chip>
        </template>
        <!--/ services -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="edit_branche(item)" small icon>
            <v-icon small>edit</v-icon>
          </v-btn>
          <!--/ edit  bracnhe -->
          <v-btn v-if="!item.is_main" @click="delete_id(item.id)" small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
          <!--/ delete bracnhe -->
        </template>
      </v-data-table>
      <!--/card text -->
    </v-card>
    <!--/card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.c_branches_page_remove_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_branche()"
            depressed
            color="error"
            >{{ keys.c_branches_page_remove_popup_delete_btn }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.c_branches_page_remove_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
  <!--/container -->
</template>

<script>
export default {
  name: "branches",
  data() {
    return {

      
      services: [
        {
          company_id:2,
          service_id: 1,
          service_name: "a",
          price:0,
          quantity:0,
          sub_services:[
            {
              name:"",
              id:0
            }
          ],
          fildes:[
            {
              field_id:0,
              is_required:false,
              type:"text",
              value:"",
              src:null
            }
          ],
          additions:[
              {
                is_used:false,
                name:"name",
                status:0,
                value:"",
              }
          ],
          branches: [
            {
              branch_id: 1,
              branch_name: "branch",
            },
          ],
          branch_id:1,
        },
      ],


      tabs: 0,
      get_config: {
        url: `/company/branches?company_id=${this.$store.getters.company_id}`,
        method: "get",
      },
      config: {
        url: `/company/branches?company_id=${this.$store.getters.company_id}`,
        method: "get",
      },
      dialog: false,
      indexItem: -1,
      editItemObj: {
        name: {},
        company_id: this.$store.getters.company_id,
        is_active: null,
      },
      default_item: {
        name: {},
        company_id: null,
        is_active: null,
      },

      save_loader: false,
      delete_dialog: false,
      delete_loader: false,
      id: null,
    };
  },
  watch: {
    dialog(newvalue, oldvalue) {
      if (!newvalue && oldvalue) this.close();
    },
  },
  computed: {
    dialogTitle() {
      return this.indexItem == -1
        ? this.keys.c_branches_page_popup_add_title
        : this.keys.c_branches_page_popup_edit_title;
    },
    tbody() {
      return this.$store.state.pages_data.branches || null; // get all categories from vuex store
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.c_branches_page_branche_name,
          value: "name_locale",
          align: "center",
        },
        {
          text: this.keys.c_branches_page_branche_status,
          value: "status",
          align: "center",
        },
        {
          text: this.keys.c_branches_page_branche_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  methods: {
    edit_branche(item) {
      this.indexItem = this.tbody.indexOf(item);
      this.editItemObj = Object.assign({}, item);
      this.dialog = true;
    },
    validation() {
      return true;
    },
    save() {
      this.save_loader = true;
      if (this.validation()) {
        if (this.indexItem > -1) {
          this.config.url = `/company/branches/${this.editItemObj.id}`;
          this.config.method = "put";
          this.editItemObj["company_id"] =
            this.$store.getters.user_obj.employee.company_id;
        } else {
          this.config.url = "/company/branches";
          this.config.method = "post";
        }

        this.$store
          .dispatch("public__request", {
            data: this.editItemObj,
            config: this.config,
          })
          .then((res) => {
            this.get_branches();
            this.close();
          });
      }
    },
    close() {
      this.dialog = false;
      this.save_loader = false;
      this.indexItem = -1;
      this.$nextTick(() => {
        this.editItemObj = Object.assign({}, this.default_item);
      });
    },
    delete_id(id) {
      this.delete_dialog = true;
      this.id = id;
    },
    delete_branche() {
      this.delete_loader = true;
      this.config.url = `/company/branches/${this.id}`;
      this.config.method = "delete";
      this.$store
        .dispatch("public__request", {
          config: this.config,
        })
        .then((res) => {
          let findindex = this.tbody.findIndex((e) => e.id == this.id); // get index of  category delete for delete form desserts
          this.tbody.splice(findindex, 1);
          this.delete_dialog = false;
          this.delete_loader = false;
        });
    },
    get_branches() {
      this.$store.dispatch("public__request", {
        config: this.get_config,
        get: "branches",
      });
    },
  },
  mounted() {
    if (!this.$store.state.branches) {
      this.get_branches();
    }
  },
  created() {
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.delete_branche();
      }
    };
  },
};
</script>
