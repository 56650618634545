var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-5",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',{staticClass:"border"},[_vm._v(" "+_vm._s(_vm.keys.project_page_details_genral_title)+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-tabs',{staticClass:"mb-3",attrs:{"value":_vm.tabs,"background-color":"gray"},on:{"change":function($event){return _vm.$emit('basic_info_tabs', $event)}}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.languages),function(lang,la){return _c('v-tab',{key:la,attrs:{"value":_vm.tabs}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2),_c('v-tabs-items',{attrs:{"value":_vm.tabs}},_vm._l((_vm.languages),function(lang,la){return _c('v-tab-item',{key:la},[_c('v-row',{staticClass:"pt-4",class:[
              { 'text-end': lang.direction == 'ltr' },
              { 'text-start': lang.direction == 'rtl' } ],attrs:{"dir":lang.direction}},[_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"prepend-icon":_vm.errors.name_err && _vm.tabs == 0 ? 'error_outline' : '',"error":_vm.errors.name_err && _vm.tabs == 0,"value":_vm.name[lang.locale]},on:{"keyup":function($event){return _vm.text_inputs($event.target.value, 'name', lang.locale)}}},[_c('template',{slot:"label"},[(_vm.tabs == 0)?_c('strong',{staticClass:"red--text"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.keys.project_page_details_project_name)+" ")])],2)],1),_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":_vm.title[lang.locale],"label":_vm.keys.project_page_details_project_title},on:{"keyup":function($event){return _vm.text_inputs($event.target.value, 'title', lang.locale)}}})],1),(_vm.tabs == 0)?_c('v-col',{attrs:{"md":"3","cols":"6"}},[_c('v-select',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"prepend-icon":_vm.errors.type_err && _vm.tabs == 0 ? 'error_outline' : '',"error":_vm.errors.type_err && _vm.tabs == 0,"items":[28, 2, 3],"value":_vm.project_type_id},on:{"input":function($event){return _vm.$emit('project_id', $event)}}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.project_page_details_project_type)+" ")])],2)],1):_vm._e(),(_vm.tabs == 0)?_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-dialog',{ref:"dialog",refInFor:true,attrs:{"return-value":_vm.inputDate.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.inputDate, "date", $event)},"update:return-value":function($event){return _vm.$set(_vm.inputDate, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' } ],attrs:{"prepend-icon":_vm.errors.data_err && _vm.tabs == 0
                        ? 'error_outline'
                        : 'mdi-calendar',"error":_vm.errors.data_err && _vm.tabs == 0,"readonly":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on),[_c('template',{slot:"label"},[_c('strong',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.keys.project_page_details_project_date)+" ")])],2)]}}],null,true),model:{value:(_vm.inputDate.modal),callback:function ($$v) {_vm.$set(_vm.inputDate, "modal", $$v)},expression:"inputDate.modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.inputDate.date),callback:function ($$v) {_vm.$set(_vm.inputDate, "date", $$v)},expression:"inputDate.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.inputDate.modal = false}}},[_vm._v(" "+_vm._s(_vm.keys.project_page_date_close)+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog[0].save(_vm.inputDate.date),
                        _vm.$emit('start_date', _vm.inputDate.date)}}},[_vm._v(" "+_vm._s(_vm.keys.project_page_date_save)+" ")])],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":_vm.description[lang.locale],"label":_vm.keys.project_page_details_project_note},on:{"keyup":function($event){return _vm.text_inputs($event.target.value, 'description', lang.locale)}}})],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }