<template>
  <div>
    <v-container>
      <v-card class="mb-5" elevation="0" outlined>
        <v-card-text md="6" cols="12">
          <v-select
            :items="items"
            chips
            label="اختيار المشروع"
            multiple
            v-model="form.id"
            item-text="name"
            item-value="id"
            dense
          ></v-select>
        </v-card-text>
      </v-card>
      <!--/ card --> 

      <v-card loading class="mb-5" outlined elevation="0">
        <v-card-title>
          تكاليف البناء حتي هذه الدقيقة
          <v-spacer></v-spacer>
        </v-card-title>
        <v-simple-table class="pb-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(item, i) in table.headers"
                  :key="i"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in table.tbody" :key="i">
                <th class="gray fixedColumns">{{ item.name }}</th>
                <td v-for="(data, a) in item.data" :key="a">
                  {{ data }}
                </td>
              </tr>
              <tr>
                <th class="gray fixedColumns">
                  {{ table.tfoot.name }}
                </th>
                <td v-for="(data, a) in table.tfoot.data" :key="a">
                  <span
                    class="font-weight-bold"
                    :class="[
                      { 'success--text': data.plus },
                      { 'red--text': !data.plus },
                    ]"
                  >
                    {{ data.plus ? "" : "-" }}{{ data.num }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <!--/card --> 

      <v-card loading class="mb-5" outlined elevation="0">
        <v-card-title>
          تكاليف البناء حتي هذه الدقيقة
          <v-spacer></v-spacer>
        </v-card-title>
        <!--/ card Title --> 
        <v-simple-table class="pb-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(item, i) in table.headers"
                  :key="i"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <!--/thead --> 
            <tbody>
              <tr v-for="(item, i) in table.tbody" :key="i">
                <th class="gray fixedColumns">{{ item.name }}</th>
                <td v-for="(data, a) in item.data" :key="a">
                  {{ data }}
                </td>
              </tr>
              <tr>
                <th class="gray fixedColumns">
                  {{ table.tfoot.name }}
                </th>
                <td v-for="(data, a) in table.tfoot.data" :key="a">
                  <span
                    class="font-weight-bold"
                    :class="[
                      { 'success--text': data.plus },
                      { 'red--text': !data.plus },
                    ]"
                  >
                    {{ data.plus ? "" : "-" }}{{ data.num }}
                  </span>
                </td>
              </tr>
            </tbody>
            <!--/tbody --> 
          </template>
        </v-simple-table>
        <!--/simple  table --> 
      </v-card>
      <!--/card --> 
    </v-container>
  </div>
</template>

<style lang="scss" scoped>

tr:last-of-type td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
::v-deep {
  .v-badge__badge {
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 20px !important;
  }
}
</style>
<script>
export default {
  neme: "projectBudget",
  data() {
    return {
      items: [
        { id: 1, name: "Item 1", description: "Item 1 description." },
        { id: 2, name: "Item 2", description: "Item 2 description." },
        { id: 3, name: "Item 3", description: "Item 3 description." },
        { id: 4, name: "Item 4", description: "Item 4 description." },
        { id: 5, name: "Item 1", description: "Item 1 description." },
        { id: 6, name: "Item 2", description: "Item 2 description." },
        { id: 7, name: "Item 3", description: "Item 3 description." },
        { id: 8, name: "Item 4", description: "Item 4 description." },
      ],

      form: {
        id: null,
        name: "",
        description: "",
      },

      table: {
        headers: [
          { text: " نوع	" },
          { text: "A مشروع 	" },
          { text: "B مشروع 	" },
          { text: "C مشروع 	" },
          { text: "D مشروع	" },
          { text: "F مشروع	" },
        ],
        tbody: [
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "البناء حسب العقد	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "البناء حسب العقد	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
        ],
        tfoot: {
          name: "البناء حسب العقد	",
          data: [
            { plus: true, num: 100 },
            { plus: false, num: 100 },
            { plus: true, num: 100 },
            { plus: false, num: 100 },
            { plus: true, num: 100 },
          ],
        },
      },
    };
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };
          this.nonce++;
        }

        return v;
      });
    },
  },
};
</script>
