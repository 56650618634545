<template>
  <div>
    <!-- <v-btn @click="drawer = !drawer"></v-btn> -->
    <v-navigation-drawer
      class="list"
      left
      :fixed="fixed"
      floating
      v-model="drawer"
      @input="close_nav($event)"
    >
      <v-card elevation="0">
        <v-card-title class="d-block text-center">
          <v-avatar size="100">
            <img src="https://placeimg.com/100/100/tech/any?1" />
          </v-avatar>
          <h3 class="font-weight-medium">Company name</h3>
        </v-card-title>
        <v-card-text>
          <h5
            class="font-weight-bold mb-2 d-flex justify-space-between align-center"
          >
            <span>
              <v-icon small>groups</v-icon>
              الاعضاء (22)
            </span>
            <v-btn
              text
              color="primary"
              class="text-decoration-underline font-weight-bold"
              small
            >
              عرض الكل</v-btn
            >
          </h5>

          <v-list dense class="mb-4">
            <v-list-item
              tag="button"
              v-for="list in 4"
              :key="list"
              class="v-btn px-3"
            >
              <v-list-item-avatar size="40">
                <img :src="`https://placeimg.com/100/100/tech?${list}`" />
              </v-list-item-avatar>
              <v-list-item-content>
                <h5 class="list__name text-start">Hassn Ahmed</h5>
                <p class="list__message mt-1 text-start">Hello how are u ...</p>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small>question_answer</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <h5
            class="font-weight-bold mb-2 d-flex justify-space-between align-center"
          >
            <span>
              <v-icon small>image</v-icon>
              الصور (22)
            </span>
            <v-btn
              text
              color="primary"
              class="text-decoration-underline font-weight-bold"
              small
            >
              عرض الكل</v-btn
            >
          </h5>
          <div class="imgs d-flex flex-wrap justify-space-between mb-3">
            <div data-num="+22" v-for="a in 6" :key="a" class="imgs__img mb-2">
              <img :src="`https://placeimg.com/100/100/tech?${a}`" />
            </div>
          </div>
          <!--/ imgs -->

          <h5
            class="font-weight-bold mb-2 d-flex justify-space-between align-center"
          >
            <span>
              <v-icon small>folder</v-icon>
              ملفات (22)
            </span>
          </h5>

          <v-list dense class="files">
            <v-list-item tag="button" class="v-btn px-3 py-0">
              <v-list-item-icon class="me-1">
                <span class="files__icon">
                  <v-icon color="primary" small>description</v-icon>
                </span>
              </v-list-item-icon>
              <v-list-item-content>
                <div class="ms-3 files__name text-start">
                  <h5 class="font-weight-bold black--text mb-1">ملفات</h5>
                  <p class="caption">126 ملف</p>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <span class="files__arrow">
                  <v-icon> keyboard_arrow_left </v-icon>
                </span>
              </v-list-item-action>
            </v-list-item>

            <v-list-item tag="button" class="v-btn px-3 py-0">
              <v-list-item-icon class="me-1">
                <span class="files__icon">
                  <v-icon color="primary" small>mic</v-icon>
                </span>
              </v-list-item-icon>
              <v-list-item-content>
                <div class="ms-3 files__name text-start">
                  <h5 class="font-weight-bold black--text mb-1">صوتى</h5>
                  <p class="caption">126 صوتى</p>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <span class="files__arrow">
                  <v-icon> keyboard_arrow_left </v-icon>
                </span>
              </v-list-item-action>
            </v-list-item>

            <v-list-item tag="button" class="v-btn px-3 py-0">
              <v-list-item-icon class="me-1">
                <span class="files__icon">
                  <v-icon color="primary" small>videocam</v-icon>
                </span>
              </v-list-item-icon>
              <v-list-item-content>
                <div class="ms-3 files__name text-start">
                  <h5 class="font-weight-bold black--text mb-1">فيديو</h5>
                  <p class="caption">126 فيديو</p>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <span class="files__arrow">
                  <v-icon> keyboard_arrow_left </v-icon>
                </span>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <!--/ card text -->
      </v-card>
      <!--/ card -->
    </v-navigation-drawer>
    <!--/navigation drawer -->
  </div>
</template>

<script>
export default {
  name: "files",
  data() {
    return {
      drawer: true,
      fixed: false,
    };
  },
  methods: {
    close_nav(ev) {
      if (!ev) {
        this.drawer = false;
        let vm = this;
        setTimeout(() => {
          vm.$emit("draw_emit", ev);
          vm.drawer = true;
        }, 300);
      }
    },
    window_changed() {
      if (innerWidth < 992) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },
  },
  created() {
    if (innerWidth < 992) {
      this.fixed = true;
    } else {
      this.fixed = false;
    }
    window.addEventListener("resize", this.window_changed);
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .list {
    &.v-navigation-drawer {
      width: 300px !important;
    }
    &__name {
      text-transform: capitalize;
    }
    &__message {
      font-size: 12px;
      color: #6a6a6a;
      font-weight: normal;
    }
    &__time {
      color: #929292;
      margin-top: 5px;
    }
    .v-chip {
      width: 16px !important;
      height: 16px !important;
      line-height: 16px;
      border-radius: 50% !important;
      text-align: center;
      display: block;
      position: relative;
      top: 0px;
    }
    .v-list-item {
      text-transform: lowercase;
    }
    .v-list-item__subtitle {
      color: #929292;
      .v-icon {
        font-size: 16px;
        &.pin {
          transform: rotate(-40deg);
        }
      }
    }
  }
  .v-card {
    height: calc(100vh - 95px);
  }
  .imgs {
    &__img {
      cursor: pointer;
      width: calc((100% / 3) - 5px);
      overflow: hidden;
      img {
        width: 100%;
        border-radius: 3px;
      }
      &:nth-of-type(6) {
        position: relative;
        img {
          filter: blur(2px);
        }
        &:before {
          left: 25%;
          top: 23px;
          content: attr(data-num);
          position: absolute;
          font-size: 15px;
          color: #fff;
          font-weight: bold;
          z-index: 2;
        }
      }
    }
  }

  //--------------- files

  .files {
    .v-btn {
      padding-top: 7px;
      width: 100%;
    }
    &__icon {
      background-color: #f0f0f0;
      padding: 0 0.2rem;
      border-radius: 5px;
    }
    &__name {
      line-height: 0.5;
      position: relative;
      top: 5px;
    }
    .caption {
      color: #ababab;
    }
    &__arrow {
      .material-icons {
        font-size: 15px;
        color: #808080 !important;
      }
    }
  }
}
</style>
