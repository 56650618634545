<template>
  <v-container>
    <v-row class="mb-1">
      <v-col cols="12" md="4">
        <v-card elevation="0" outlined class="analayze rounded-lg">
          <v-card-text>
            <div class="d-flex">
              <div>
                <span class="primary--text font-weight-bold">0</span>
                /
                <span class="red--text font-weight-bold">0</span>
              </div>
              <v-spacer></v-spacer>
              <h2 class="analayze__title analayze__title--text">
                اجمالي الرسائل
              </h2>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/col -->

      <v-col cols="12" md="4">
        <v-card elevation="0" outlined class="analayze rounded-lg">
          <v-card-text>
            <div class="d-flex">
              <div>
                <span class="primary--text font-weight-bold">0</span>
                /
                <span class="red--text font-weight-bold">0</span>
              </div>
              <v-spacer></v-spacer>
              <h2 class="analayze__title analayze__title--text">
                اجمالي رسائل SMS
              </h2>
            </div>
          </v-card-text>
          <!--/card Text -->
        </v-card>
        <!--/card -->
      </v-col>
      <!--/col -->
      <v-col cols="12" md="4">
        <v-card elevation="0" outlined class="analayze rounded-lg">
          <v-card-text>
            <div class="d-flex">
              <div>
                <span class="primary--text font-weight-bold">0</span>
                /
                <span class="red--text font-weight-bold">0</span>
              </div>
              <v-spacer></v-spacer>
              <h2 class="analayze__title analayze__title--text">
                اجمالي رسائل الواتساب
              </h2>
            </div>
          </v-card-text>
          <!--/card Text -->
        </v-card>
        <!--/card -->
      </v-col>
      <!--/col -->
    </v-row>
    <!--/ analayze -->
    <v-card class="mb-3" elevation="0" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" class="mb-2 d-flex justify-space-between">
            <v-dialog
              ref="dialog"
              v-model="dateModal"
              :return-value.sync="dates"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="التاريخ"
                  append-icon="mdi-calendar"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>

              <v-date-picker range v-model="dates" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateModal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!--/dialog -->
          </v-col>
          <!--/col -->
          <v-col cols="12" md="8">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="بحث"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <!--/col -->
        </v-row>
        <!--/row -->
      </v-card-text>
      <!--/card text -->
    </v-card>
    <!--/card -->

    <v-card class="text-center" elevation="0" outlined>
      <v-card-title class="d-flex">
        <span class="order-1">الرسائل </span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="بحث"
          single-line
          hide-details
          class="order-4 order-sm-3 ma-0"
        ></v-text-field>

        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...dialog, ...tooltip }"
                  v-bind="attrs"
                  depressed
                  color="primary"
                  class="order-3 order-sm-5"
                >
                  ارسال رسالة</v-btn
                >
              </template>
              <span>ارسال</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ dialogTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-datetime-picker
                    label="تاريخ الرسالة"
                    v-model="editItemObj.date"
                    @input="inp($event)"
                    :textFieldProps="{ id: 'input-date' }"
                  >
                    <template slot="actions" slot-scope="{ parent }">
                      <v-btn
                        color="error lighten-1"
                        @click.native="parent.clearHandler"
                        >Cancel</v-btn
                      >
                      <v-btn color="success darken-1" @click="parent.okHandler"
                        >Done</v-btn
                      >
                    </template>
                  </v-datetime-picker>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label=" اسم المرسل"
                    v-model="editItemObj.sender"
                    disabled
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-select
                    label="نوع الأرسال"
                    :items="['رسالة عادية', 'رسالة واتساب']"
                  ></v-select>
                </v-col>
                <!--/col -->
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editItemObj.sentTo"
                    :items="items"
                    chips
                    label="اختيار المرسل اليهم"
                    item-text="name"
                    item-value="id"
                    multiple
                    return-object
                  >
                    <template v-slot:selection="{ item, attrs }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="item.selected"
                        close
                        @click:close="remove(item)"
                      >
                        {{ item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:items="item">
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--/col -->

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    label="الرسالة"
                    v-model="editItemObj.message"
                  ></v-textarea>
                  <p class="text-end">
                    <span class="orange--text darken-1--text font-weight-bold">
                      ({{ editItemObj.message.length }}) </span
                    >حرف
                  </p>
                </v-col>
                <!--/col -->
              </v-row>
              <!--/row -->
            </v-card-text>
            <!--/card text -->

            <v-card-actions>
              <v-btn
                @click="save(), (dialog = false)"
                color="primary"
                class="font-weight-bold"
                text
                small
              >
                حفظ
              </v-btn>
              <v-btn small @click="close()" class="font-weight-bold" color="red" text>
                الغاء
              </v-btn>
            </v-card-actions>
            <!--/card actions -->
          </v-card>
        </v-dialog>
        <!--/dialog -->
      </v-card-title>
      <!--/card title -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :mobile-breakpoint="0"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template style="max-width: 300px" v-slot:[`item.sentTo`]="{ item }">
          <v-btn
            rounded
            elevation="0"
            class="me-1"
            @click="sentToArr(item.sentTo)"
            small
          >
            {{ item.sentTo[0].name }}
          </v-btn>
          <v-chip
            class="px-2"
            color="success"
            x-small
            v-if="item.sentTo.length > 1"
          >
            <v-icon small class="material-icon">add</v-icon>
            {{ item.sentTo.length - 1 }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            x-small
            :class="[
              { 'red accent-1 white--text': item.status == 0 },
              { 'gray grey darken-1 black--text': item.status == 1 },
              { success: item.status == 2 },
            ]"
          >
            <span v-if="item.status == 0">فشل الارسال</span>
            <span v-else-if="item.status == 1">لم يتم بعد</span>
            <span v-else>تم الأرسال</span>
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn fab icon small @click="editItem(item)">
            <v-icon small> edit </v-icon>
          </v-btn>
          <v-btn fab icon small>
            <v-icon small> delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!--start dialog viwe sent to  names -->
    <v-dialog max-width="500" v-model="sentToDialog.modal">
      <v-card>
        <v-card-title class="font-weight-bold">عرض المرسل اليهم</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-chip-group column>
            <v-chip v-for="(a, i) in sentToDialog.names" :key="i">
              {{ a.name }}
              <span class="ms-2" v-if="!a.sent">
                <v-icon class="red--text" small>cancel</v-icon>
              </span>
              <span class="ms-2" v-if="a.sent">
                <v-icon class="primary--text" small>check_circle</v-icon>
              </span>
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="font-weight-bold"
            text
            small
            color="sencodary"
            @click="sentToDialog.modal = false"
            >الغاء</v-btn
          >
        </v-card-actions>
      </v-card>
      <!--/card-->
    </v-dialog>
    <!--End dialog viwe sent to  names -->
  </v-container>
</template>

<script>
export default {
  name: "messages",
  data() {
    return {
      dates: [],
      dateModal: false,
      sentToDialog: {
        modal: false,
        names: [],
      }, // for view sent to
      obj: "",
      search2: null,
      menu: false,
      items: [
        { id: 1, name: "mohaned" },
        { id: 2, name: "ahmed" },
        { id: 3, name: "kahled" },
        { id: 4, name: "saed" },
        { id: 5, name: "mustafa" },
        { id: 6, name: "taher" },
      ],
      sendTo: [],
      plans: ["plan1", "plan2"],
      closeOnContentClick: true,
      closeOnContentClick2: true,
      dialog: false,
      search: "",
      dialog: false,
      editItemObj: {
        messageNumber: "",
        sentTo: [],
        sender: "",
        status: null,
        date: "",
        message: "",
      },
      defualtItem: {
        messageNumber: "",
        sentTo: [],
        sender: "",
        status: null,
        date: "",
        message: "",
      },
      indexItem: -1,
      headers: [
        { text: "رقم الرسالة", value: "messageNumber", align: "center" },
        { text: "مرسل الى", value: "sentTo", align: "center" },
        { text: "المرسل", value: "sender", align: "center" },
        { text: "حالة الرسالة", value: "status", align: "center" },
        { text: "تاريخ الارسال", value: "date", align: "center" },
        { text: "اجراءات", value: "actions", sortable: false, align: "center" },
      ],
      desserts: [
        {
          messageNumber: 213,
          sentTo: [
            { id: 1, name: "mohaned1", sent: true },
            { id: 2, name: "ahmed", sent: false },
            { id: 3, name: "ahmed", sent: false },
            { id: 4, name: "ahmed", sent: false },
            { id: 5, name: "ahmed", sent: false },
          ],
          sender: "hassan ahmed",
          status: 0,
          date: "20-20-2020 12:00",
          message: "",
        },
        {
          messageNumber: 44,
          sentTo: [
            { id: 1, name: "mohaned", sent: false },
            { id: 2, name: "ahmed", sent: false },
          ],
          sender: "hassan ahmed",
          status: 1,
          date: "20-20-2020 12:00",
          message: "",
        },
        {
          messageNumber: 22,
          sentTo: [{ id: 5, name: "alaa", sent: false }],
          sender: "hassan ahmed",
          status: 2,
          date: "20-20-2020 12:00",
          message: "",
        },
      ],
    };
  },

  computed: {
    dialogTitle() {
      return this.indexItem == -1 ? "ارسال رسالة " : "تعديل رسالة";
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    sentToArr(names) {
      this.sentToDialog.modal = true;
      this.sentToDialog.names = [];
      Object.assign(this.sentToDialog.names, names);
    },
    inp(va) {
      this.editItemObj.date = va;
    },
    remove(item) {
      this.editItemObj.sentTo.splice(this.sendTo.indexOf(item), 1);
    },
    multipleSelection(item) {
      this.sendTo.push(item);
      this.items.splice(this.items.indexOf(item), 1);
    },
    deleteChip(item) {
      this.items.push(item);
      this.sendTo.splice(this.sendTo.indexOf(item), 1);
    },
    editItem(item) {
      this.dialog = true;
      this.indexItem = this.desserts.indexOf(item);
      this.editItemObj = Object.assign({}, item);
    },
    save() {
      if (this.indexItem > -1) {
        Object.assign(this.desserts[this.indexItem], this.editItemObj);
      } else {
        this.desserts.push(this.editItemObj);
      }
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editItemObj = Object.assign({}, this.defualtItem);
        this.indexItem = -1;
      });
    },
  },
};
</script>
