<template>
  <div class="mb-10">
    <v-skeleton-loader type="table" v-if="!data_loaded"> </v-skeleton-loader>
    <v-card elevation="0" outlined class="mt-4" v-if="data_loaded">
      <v-card-title>
        {{keys.employee_profile_page_payments_table_title}}
        <v-spacer></v-spacer>
        <v-text-field hide-details v-model="search" :label="keys.employee_profile_page_payments_table_search"></v-text-field>
      </v-card-title>
      <!--/ card title -->
      <v-data-table
        mobile-breakpoint="0"
        :search="search"
        :items="tbody"
        :headers="headers"
      >
      </v-data-table>
      <!-- / data table -->
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["tbody", "data_loaded", "keys"],
  name: "PaymentTable",
  data() {
    return {
      search: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          align: "center",
          value: "created_at",
          text: this.keys.employee_profile_page_payments_table_date,
        },
        {
          align: "center",
          value: "payment_method",
          text: this.keys.employee_profile_page_payments_table_methods,
        },
        {
          align: "center",
          value: "amount",
          text: this.keys.employee_profile_page_payments_table_amount,
        },
        {
          align: "center",
          value: "created_at",
          text: this.keys.employee_profile_page_payments_table_paid_by,
        },
      ];
    },
  },
};
</script>
