<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!date_done"> </v-skeleton-loader>
    <v-card v-if="date_done" class="text-center" elevation="0" outlined>
      <v-card-title class="d-flex">
        <span class="order-1">{{ keys.checkes_page_table_page_name }} </span>
        <v-spacer class="order-2"></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="keys.checkes_page_table_search"
          single-line
          hide-details
          class="order-4 order-sm-3"
        ></v-text-field>
        <v-spacer class="order-4 d-none d-sm-block"></v-spacer>
        <v-btn
          depressed
          color="primary"
          class="order-3 order-sm-5"
          :to="{ name: 'new_check_page' }"
        >
          {{ keys.checkes_page_table_add_btn }}</v-btn
        >
      </v-card-title>
      <!--/card title -->
      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        sort-by="calories"
        no-data-text="لا يوجد نتائج "
        no-results-text="لا يوجد نتائج بحث"
        :footer-props="$store.state.dataTableFooterProps"
      >
        <template style="max-width: 300px" v-slot:[`item.engPro`]="{ item }">
          <v-btn rounded elevation="0" class="me-1" small>
            {{ item.engPro[0] }}
          </v-btn>
          <v-chip
            class="px-1"
            color="success"
            x-small
            v-if="item.engPro.length > 1"
          >
            <v-icon x-small class="material-icon">add</v-icon>
            {{ item.engPro.length - 1 }}
          </v-chip>
        </template>
        <!--/ template -->
        <template style="max-width: 300px" v-slot:[`item.superPro`]="{ item }">
          <v-btn rounded elevation="0" class="me-1" small>
            {{ item.superPro[0] }}
          </v-btn>
          <v-chip
            class="px-1"
            color="success"
            x-small
            v-if="item.superPro.length > 1"
          >
            <v-icon x-small class="material-icon">add</v-icon>
            {{ item.superPro.length - 1 }}
          </v-chip>
        </template>
        <!--/ template -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            @click="
              $router.push({
                name: 'new_check_page',
                query: { check: item.id },
              })
            "
            fab
            small
            icon
          >
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn
            @click="(delete_dialog = true), (delete_id = item.id)"
            fab
            small
            icon
          >
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ template -->
      </v-data-table>
      <!--/data table -->
    </v-card>
    <!--/card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.checkes_page_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            :disabled="delete_loader"
            @click="delete_project()"
            depressed
            color="error"
            >{{ keys.checkes_page_delete_button }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.checkes_page_close_button
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
</template>

<script>
export default {
  name: "checks",
  data() {
    return {
      date_done: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      addCat: false,
      categoriesDialgo: {
        modal: false,
        names: [],
      }, // for view sent to
      dates: "",
      obj: "",
      search2: null,
      menu: false,
      items: [
        { id: 1, name: "mohaned" },
        { id: 2, name: "ahmed" },
        { id: 3, name: "kahled" },
        { id: 4, name: "saed" },
        { id: 5, name: "mustafa" },
        { id: 6, name: "taher" },
      ],
      search: "",
      dialog: false,
      editItemObj: {
        messageNumber: "",
        sentTo: [],
        sender: "",
        status: null,
        date: "",
        message: "",
      },
      defualtItem: {
        messageNumber: "",
        sentTo: [],
        sender: "",
        status: null,
        date: "",
        message: "",
      },
      indexItem: -1,
      items: ["hassan", "ahmed", "taher", "saied", "asdas"],
      inputDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        modal: false,
      },
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    headers() {
      return [
        {
          text: this.keys.checkes_page_table_project_name,
          value: "name",
          align: "center",
        },
        {
          text: this.keys.checkes_page_table_num_checks,
          value: "checks_counts",
          align: "center",
        },
        {
          text: this.keys.checkes_page_table_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
    desserts() {
      return this.$store.state.pages_data.checks || [];
    },
  },
  methods: {
    delete_project() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "delete",
            url: `company/project/checks/${this.delete_id}`,
          },
        })
        .then((res) => {
          this.get_checks();
          this.delete_loader = false;
          this.delete_dialog = false;
        });
    },
    get_checks() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "company/project/checks",
          },
          get: "checks",
        })
        .then((res) => {
          this.date_done = true;
          console.log(res);
        });
    },
  },
  created() {
    this.get_checks();
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter" && vm.delete_dialog) {
        vm.delete_project();
      }
    };
  },
};
</script>
