<template>
  <div>
    <v-container>
      <v-card loading class="mb-10 mt-5" outlined elevation="0">
        <v-card-title class="">
          المهام
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500">
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-tooltip v-on="on" v-bind="attrs" bottom nudge-bottom="-5">
                <template v-slot:activator="{ on: tooltip, attrs }">
                  <v-btn
                    elevation="0"
                    class="font-weight-bold"
                    color="primary"
                    v-on="{ ...tooltip, ...dialog }"
                    v-bind="attrs"
                  >
                    اضافة مهمة
                  </v-btn>
                </template>
                <span>اضافة مهمة</span>
              </v-tooltip>
            </template>

            <v-card>
              <v-card-title class="font-weight-bold"> اضافة مهمة </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      label="نوع المهمة"
                      :items="['item1', 'item2']"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      label="قسم المهمة"
                      :items="['item1', 'item2']"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      :items="['hassan', 'ahmed', 'taher']"
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex justify-end">
                    <v-switch
                      class="mt-5"
                      v-model="switchVal"
                      :label="`حالة المهمة ( ${
                        switchVal ? 'فعال' : 'غير فعال'
                      })`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  elevation="0"
                  color="primary"
                  small
                  class="font-weight-bold"
                  text
                  >حفظ</v-btn
                >
                <v-btn
                  @click="dialog = false"
                  color="red"
                  class="font-weight-bold"
                  text
                  small
                  >الغاء</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--/dialog -->
        </v-card-title>
        <!--/card title -->
        <v-simple-table class="pb-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(item, i) in table.headers"
                  :key="i"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in table.tbody" :key="i">
                <td>{{ item.name }}</td>
                <td v-for="(data, a) in item.data" :key="a">
                  {{ data }}
                </td>
                <td>
                  <v-btn icon small>
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small>
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <!--/tbody -->
          </template>
        </v-simple-table>
        <!--/ simple table -->
      </v-card>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>

tr:last-of-type td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>

<script>
export default {
  name: "invoices",
  data() {
    return {
      dialog: false,
      switchVal: false,
      table: {
        headers: [
          { text: " رقم المهمة	" },
          { text: "نوع المهمة	" },
          { text: "قسم المهمة" },
          { text: "صاحب المهمة	" },
          { text: "توقيت الاضافة" },
          { text: "موعد التسليم" },
          { text: "حالة المهمة" },
          { text: " اجراءت" },
        ],
        tbody: [
          {
            name: "213	",
            data: [100, 100, 100, 100, 100, 100],
          },
          {
            name: "41	",
            data: [100, 100, 100, 100, 100, 100],
          },
          {
            name: "4123",
            data: [100, 100, 100, 100, 100, 100],
          },
          {
            name: "123	",
            data: [100, 100, 100, 100, 100, 100],
          },
          {
            name: "421",
            data: [100, 100, 100, 100, 100, 100],
          },
        ],
      },
    };
  },
};
</script>
