var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" دليل الهاتف "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"بحث","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"sort-by":"calories","no-data-text":"لا يوجد نتائج ","no-results-text":"لا يوجد نتائج بحث","mobile-breakpoint":0,"footer-props":_vm.$store.state.dataTableFooterProps},scopedSlots:_vm._u([{key:"item.activtyType",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text px-2",class:[
            { 'red accent-1': item.activtyType == 0 },
            { primary: item.activtyType == 1 } ],attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item.activtyType == 0 ? "مورد" : "مورد")+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){_vm.closeOnContentClick = !_vm.closeOnContentClick}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("call")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-center"},[_c('v-list-item',{attrs:{"outlined":"","href":"tel:021231231","link":""}},[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v("اتصل الان")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v("02132123154")])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.closeOnContentClick2},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","fab":"","icon":""},on:{"click":function($event){_vm.closeOnContentClick = !_vm.closeOnContentClick2}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("whatsapp")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-center"},[_c('v-list-item',{attrs:{"href":"tel:021231231","link":""}},[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v("اتصل الان")])],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v("02132123154")])],1)],1)],1),_c('v-btn',{attrs:{"fab":"","small":"","icon":"","to":{ name: 'Home', query: { id: item.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("badge")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }