<template>
  <v-container>
    <h3 class="mb-4 font-weight-regular mt-5">{{keys.employee_requests_page_title}}</h3>
    <div v-if="!request_loaded">
      <v-row>
        <v-col cols="4" v-for="(a, i) in new Array(6)" :key="i">
          <v-skeleton-loader
            elevation="0"
            outlined
            type="list-item-avatar"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <v-alert dense class="text-center" v-if="empty" type="warning"
      >     {{keys.employee_requests_page_empty}}</v-alert
    >

    <v-row v-if="request_loaded">
      <v-col v-for="(company, i) in requests" :key="i" cols="12" sm="6">
        <v-card class="mt-5" elevation="0" outlined>
          <v-card-text class="py-0 d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-avatar class="ma-3" size="60">
                <img
                  :src="`${$store.state.def_path}/${company.company.image}`"
                />
              </v-avatar>
              <div class="ms-3">
                <h2 class="black--text">{{ company.company.name }}</h2>
              </div>
            </div>
            <!--/ image & name & department  -->

            <div>
              <v-btn
                @click="accept_request(company.id, company.company.id)"
                small
                depressed
                color="primary"
                class="me-2"
                rounded
                :loading="accept_loader"
                :disabled="accept_loader"
              >
                <v-icon>check</v-icon>
              </v-btn>
              <v-btn
                :loading="reject_loader"
                :disabled="reject_loader"
                rounded
                small
                depressed
                color="error"
                @click="rejct_request(company.id)"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar width="auto" right v-model="snackbar">
      {{keys.employee_requests_page_sucess}}
      <template v-slot:action="{ attrs }">
        <v-icon color="success" v-bind="attrs">done</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  name: "employee_requests",
  data() {
    return {
      request_loaded: false,
      snackbar: false,
      requests: [],
      accept_loader: false,
      reject_loader: false,
      empty: false,
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
  },
  methods: {
    get_requests() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/profile/requests",
            methods: "get",
          },
        })
        .then((res) => {
          res.data.length == 0 ? (this.empty = true) : (this.empty = false);
          this.requests = res.data;
          this.request_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    accept_request(id, company_id) {
      this.accept_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/profile/requests/accept",
            method: "post",
          },
          data: {
            id: id,
          },
        })
        .then((res) => {
          this.accept_loader = false;
          this.snackbar = true;
          this.switch_company(company_id);
          this.get_requests();
        });
    },
    rejct_request(id) {
      this.reject_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/profile/requests/reject",
            method: "post",
          },
          data: {
            id: id,
          },
        })
        .then((res) => {
          this.reject_loader = false;

          this.get_requests();
        });
    },
    switch_company(company_id) {
      if (!this.$store.getters.company_id) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `company/auth/switch/${company_id}`,
              method: "get",
            },
          })
          .then((res) => {
            const user = JSON.stringify(res.data);
            this.$store.commit("user_obj", user);
          });
      }
    },
  },
  created() {
    this.get_requests();
  },
};
</script>
