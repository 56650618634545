<template>
  <div class="invoiceView text-center">
    <v-dialog v-model="dialog" width="1000">
      <v-card  id="my-node-parent">
        <v-card-text class="text-center py-10">
          <div class="wrap" id="my-node">
            <header>
              <v-row class="align-center">
                <v-col cols="4">
                  <img :src="require('@/assets/logo.png')" width="100" />
                </v-col>
                <v-col cols="4">
                  <h2 class="title font-weight-bold mb-5">title</h2>
                  <ul class="list-unstyled">
                    <li class="mb-2">4545465456</li>
                    <li class="mb-2">4545465456</li>
                    <li class="mb-2">4545465456</li>
                    <li class="mb-2">4545465456</li>
                  </ul>
                </v-col>

                <v-col cols="4">
                  <h2 class="title font-weight-bold mb-5">title</h2>
                  <ul class="list-unstyled">
                    <li class="mb-2">4545465456</li>
                    <li class="mb-2">4545465456</li>
                    <li class="mb-2">4545465456</li>
                    <li class="mb-2">4545465456</li>
                  </ul>
                </v-col>
              </v-row>
            </header>

            <section class="mt-10">
              <div class="d-flex align-center justify-space-between">
                <span class="font-weight-bold">20-20-2021</span>

                <div>
                  <h1 class="h1">212as1d</h1>
                  <p>5s4ad5sa4</p>
                </div>
              </div>

              <v-card outlined>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Calories</th>
                        <th class="text-left">Calories2</th>
                        <th class="text-left">Calories3</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in desserts" :key="item.name">
                        <td>{{ item.name }}</td>
                        <td>{{ item.calories }}</td>
                        <td>{{ item.calories2 }}</td>
                        <td>{{ item.calories3}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </section>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>



<style lang="scss" scoped>
.wrap {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
thead {
  background-color: #333;
  th {
    color: #fff !important;
  }
}
td {
  font-weight: bold;
}
tr:last-of-type {
  td:first-of-type,
  td:nth-of-type(2) {
    background-color: #c1a67b;
    color: #fff;
  }
}
.img {
  margin: 0 auto;
  display: block;
}
</style>

<script>
export default {
  name: "invoiceView",
  props: ["inView"],
  data() {
    return {
      dialog: this.inView,
      desserts: [
        {
          name: "Froze42n Yogurt",
          calories: 159,
          calories2: 159,
          calories3: 159,
        },
        {
          name: "Ice cr23eam sandwich",
          calories: 237,
          calories2: 159,
          calories3: 159,
        },
        {
          name: "Ecla213ir",
          calories: 123,
          calories2: "",
          calories3: "",
        },
        {
          name: "Eclai41r",
          calories: 262,
          calories2: "",
          calories3: "",
        },
      ],
    };
  },
  watch: {
    dialog() {
      this.$emit("close");
    },
  },
  created() {
    window.onload = function () {
      var node = document.getElementById("my-node");
      var parent = document.getElementById("my-node-parent");
      html2canvas(node, {
        logging: true,
        letterRendering: 1,
        allowTaint: false,
        useCORS: true,
      }).then(function (canvas) {
        var img = document.createElement("img");
        img.setAttribute("class", "img");
        img.download = "html_image.png";
        img.src = canvas.toDataURL("image/png");
        parent.appendChild(img);
        node.style.display = "none";
      });
    };
  },
};
</script>