<template>
  <div>
    <v-container>

      <v-card
        elevation="0"
        outlined
        v-for="(v, i) in supplier.prices"
        :key="i"
        class="mb-5"
      >
        <v-card-title class="row">
          <v-col cols="12" class="text-end">
            <v-btn
              v-if="i != 0"
              @click="supplier.prices.splice(i, 1)"
              elevation="1"
              fab
              small
              color="red"
            >
              <v-icon class="white--text">delete</v-icon>
            </v-btn>
          </v-col>
          <!--/col -->

          <v-col cols="6" md="3">
            <v-select height="42" label="اختيار البطون" :items="items">
            </v-select>
          </v-col>
          <v-col cols="6" md="9">
            <v-select
              :items="items2"
              chips
              label="اختيار المشروع"
              multiple
              v-model="form.id"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <!-- col -->
        </v-card-title>
        <!--/card title -->
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            class="overflow-auto flex-nowrap"
            v-for="(a, s) in v.price"
            :key="s"
          >
            <v-col
              v-for="(item, it) in a"
              :key="it"
              :style="{
                'min-width':
                  v.length < 4 ? `calc(100% / ${v.length})` : '150px',
              }"
            >
              <v-text-field label="one"></v-text-field>
            </v-col>
            <!--/col -->
          </v-row>
          <!--/row -->

          <div class="text-end mt-5">
            <v-btn
              @click="supplier.prices[i].price.push(emptyLine)"
              outlined
              color="primary"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <!--/ card text -->
      </v-card>
      <!--/ card -->
      <div class="d-flex justify-space-between mt-5 mb-10">
        <v-tooltip bottom nudge-bottom="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="gray darken-1"
              elevation="0"
              large
              v-bind="attrs"
              v-on="on"
              @click="supplier.prices.push(emptyPrice)"
            >
              تسعيرة جديدة
            </v-btn>
          </template>
          <span>تسعيرة</span>
        </v-tooltip>

        <v-tooltip bottom nudge-bottom="-5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" large elevation="0" color="primary">
              حفظ
            </v-btn>
          </template>
          <span>حفظ</span>
        </v-tooltip>
      </div>
    </v-container>
    <!--/container -->
  </div>
</template>

<script>
export default {
  name: "newSupplier",
  data() {
    return {
      items2: [
        { id: 1, name: "Item 1", description: "Item 1 description." },
        { id: 2, name: "Item 2", description: "Item 2 description." },
        { id: 3, name: "Item 3", description: "Item 3 description." },
        { id: 4, name: "Item 4", description: "Item 4 description." },
        { id: 5, name: "Item 1", description: "Item 1 description." },
        { id: 6, name: "Item 2", description: "Item 2 description." },
        { id: 7, name: "Item 3", description: "Item 3 description." },
        { id: 8, name: "Item 4", description: "Item 4 description." },
      ],

      form: {
        id: null,
        name: "",
        description: "",
      },

      items: ["بوطن3 ", "بوطن2 ", "بوطن1 "],
      emptyPrice: {
        price: ["", "", "", ""],
      },
      emptyLine: ["", "", "", ""],
      supplier: {
        basicInfo: {},
        prices: [
          {
            price: [[1, 2, 3, 4]],
          },
        ],
      },
    };
  },
};
</script>
