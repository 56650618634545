<template>
  <v-dialog persistent scrollable max-width="500" :value="addDialog">
    <!--/ template -->
    <v-card elevation="0" outlined>
      <v-form ref="form">
        <v-card-title>
          {{
            payment_obj.id
              ? keys.payments_page_dialog_form_edit_title
              : keys.payments_page_dialog_form_title
          }}
        </v-card-title>
        <v-divider></v-divider>
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in $store.getters.languages"
            :key="i"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->
        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="(lang, i) in $store.getters.languages"
            :key="i"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" v-if="tabs == 0">
                  <v-select
                    v-model="payment_obj.type"
                    :items="type"
                    item-value="value"
                    item-text="text"
                    :rules="rules.require"
                    @change="payment_obj.reciver_id = null"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.payments_page_type }}
                    </template>
                  </v-select>
                </v-col>
                <!--/ col (payment type)-->

                <v-col
                  cols="12"
                  sm="6"
                  v-if="payment_obj.type == 1 && tabs == 0"
                >
                  <v-select
                    return-object
                    :value="payment_obj.reciver_id"
                    @change="change_employee($event)"
                    :items="employees"
                    :rules="rules.require"
                    item-value="id"
                  >
                    <template slot="label">
                      <strong class="red--text">*</strong>
                      {{ keys.payments_page_select_employee }}
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>{{ item.name }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <div class="d-flex align-center">
                        <v-avatar size="45">
                          <img :src="item.image" alt="" />
                        </v-avatar>
                        <div class="ms-3">
                          <h4 style="font-size: 14px">
                            {{ item.name }}
                          </h4>
                          <div
                            class="d-flex align-center justify-space-between"
                          >
                            <p style="font-size: 12px">
                              {{ item.phone }}
                            </p>
                            <span>|</span>
                            <p style="font-size: 12px">
                              {{ item.id_number }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-col>
                <!--/ col (Select employee )-->
                <v-col
                  cols="12"
                  sm="6"
                  v-if="payment_obj.type == 2 && tabs == 0"
                >
                  <v-select
                    v-model="payment_obj.reciver_id"
                    :rules="rules.require"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.payments_page_select_company }}
                    </template>
                  </v-select>
                </v-col>
                <!--/ col (Select  company)-->

                <v-col
                  cols="12"
                  sm="6"
                  v-if="tabs == 0 && payment_obj.type == 1"
                >
                  <v-text-field
                    disabled
                    v-model="wallet"
                    :label="keys.payments_page_wallet"
                  ></v-text-field>
                </v-col>
                <!--/ col (wallet)-->

                <v-col cols="12" sm="6" v-if="tabs == 0">
                  <v-text-field
                    v-model="payment_obj.amount"
                    :rules="rules.require"
                    prepend-icon=""
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.payments_page_amount }}
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" v-if="tabs == 0">
                  <v-select
                    v-model="payment_obj.payment_method"
                    :rules="rules.require"
                    :items="['cash', 'bank']"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.payments_page_payment_method }}
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  v-if="tabs == 0 && payment_obj.type != 1"
                >
                  <v-file-input
                    v-model="invoice"
                    prepend-icon=""
                    prepend-inner-icon="attach_file"
                    :label="keys.payments_page_invoice"
                  ></v-file-input>
                </v-col>
                <!--/ col (insert invoice)-->

                <v-col cols="12" sm="6" v-if="tabs == 0">
                  <v-text-field
                    @click="$emit('open_attachments')"
                    readonly
                    prepend-inner-icon="attach_file"
                    :label="keys.payments_page_attachments"
                    :value="attach_length"
                  >
                    <template slot="append-outer">
                      <v-btn
                        @click="$emit('open_attachments')"
                        depressed
                        fab
                        x-small
                        color="primary"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col (upload attachments)-->

                <v-col cols="12">
                  <v-textarea
                    rows="3"
                    v-model="payment_obj.notes[lang.locale]"
                    :label="keys.payments_page_note"
                  ></v-textarea>
                </v-col>
                <!--/ col (notes)-->
              </v-row>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-btn
            @click="save_payment()"
            small
            color="primary"
            depressed
            :disabled="save_loader"
            :loading="save_loader"
            class="font-weight-bold"
            >{{ keys.payments_page_dialog_form_save }}</v-btn
          >
          <v-btn
            small
            depressed
            color="error"
            class="font-weight-bold"
            @click="close('close')"
            >{{ keys.payments_page_dialog_form_close }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Form",
  props: ["keys", "attachments", "payment_edit_item", "addDialog"],
  data() {
    return {
      save_loader: false,
      uploaded_count: 0,
      invoice: null,
      companies: [],
      employees: [],
      wallet: 0,
      tabs: 0,
      rules: {
        require: [(v) => !!v || this.keys.payments_page_require_input],
      },
      test: "",
      payment_obj: {
        type: 1,
        reciver_id: null,
        amount: 0,
        payment_method: "cash",
        invoice: null,
        notes: {},
        attachments: [],
      },
    };
  },
  watch: {
    payment_edit_item(ev) {
      const item = JSON.parse(JSON.stringify(this.payment_edit_item));
      this.payment_obj = Object.assign({}, item);
    },
  },
  computed: {
    attachments_count() {
      return this.attachments.length + (this.invoice ? 1 : 0);
    },
    attach_length() {
      return this.payment_edit_item
        ? this.attachments.length + this.payment_edit_item.files.length
        : this.attachments.length;
    },
    type() {
      return [
        {
          text: this.keys.payments_page_type_employee,
          value: 1,
        },
        // {
        //   text: this.keys.payments_page_type_company,
        //   value: 2,
        // },
        // {
        //   text: this.keys.payments_page_type_public,
        //   value: 3,
        // },
      ];
    },
  },
  methods: {
    change_employee(ev) {
      this.payment_obj.reciver_id = ev.id;
      this.wallet = ev.wallet;
    },
    // get companioes and put into select
    get_companies() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "/company/contacts/list",
            method: "get",
          },
        })
        .then((res) => {
          this.companies = Object.assign([], res.data);
        });
    },
    // get employees and put into select
    get_employees() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/project/times/employees",
            method: "get",
          },
        })
        .then((res) => {
          // console.log(res);
          this.employees = Object.assign([], res.data);
        });
    },

    edit_payment() {
      this.payment_obj = Object.assign({}, this.payment_edit_item);
    },
    // upload attachments and invoice
    upload_attachments(path, file) {
      let formData = new FormData();
      formData.append("path", path);
      formData.append("file", file);
      const config = {
        url: "https://app.kalko.cloud/api/file/upload",
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.$store.state.token}`,
        },
        data: formData,
      };
      this.axios(config).then((res) => {
        this.uploaded_count++; // increment 1 after attachment uploaded
        if (path == "invoices") {
          this.payment_obj.invoice = res.data.path; // set invoice path
        } else {
          this.payment_obj.attachments.push({
            // push attachment path
            path: res.data.path,
          });
        }
        if (this.attachments_count == this.uploaded_count) {
          // check if all attachmennts  uploaded to send payment
          this.send_payment(); // send payment
        }
      });
    },
    // save payment
    save_payment() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;

        if (this.invoice || this.attachments.length != 0) {
          if (this.invoice) {
            this.upload_attachments("invoices", this.invoice);
          }
          if (this.attachments.length != 0) {
            this.attachments.forEach((file) => {
              this.upload_attachments("payment_attachments", file);
            });
          }
        } else {
          this.send_payment();
        }
      }
    },

    send_payment() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: this.payment_edit_item
              ? `company/payments/${this.payment_edit_item.id}`
              : "company/payments",
            method: this.payment_edit_item ? "put" : "post",
          },
          data: this.payment_obj,
        })
        .then((res) => {
          this.save_loader = false;
          this.close("store");
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    close(close_type) {
      this.payment_obj = Object.assign(
        {},
        {
          type: 1,
          reciver_id: null,
          amount: 0,
          payment_method: "",
          invoice: null,
          notes: {},
          attachments: [],
        }
      );
      this.save_loader = false;
      this.$refs.form.resetValidation();
      this.$emit("close_form", close_type);
      this.uploaded_count = 0;
    },
  },
  created() {
    this.get_companies();
    this.get_employees();
  },
};
</script>
