var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.data_loaded)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.data_loaded)?_c('v-data-table',{attrs:{"items":_vm.tbody,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(item.invoice_path && item.type != 1)?_c('v-chip',{staticClass:"px-2 white--text",attrs:{"color":"primary","x-small":""}},[_vm._v(" "+_vm._s(_vm.keys.payments_page_invoice_status_exist)+" ")]):(!item.invoice_path && item.type != 1)?_c('v-chip',{staticClass:"px-2 white--text",attrs:{"color":"red lighten-2","x-small":""}},[_vm._v(" "+_vm._s(_vm.keys.payments_page_invoice__status_not_exist)+" ")]):(item.type == 1)?_c('span',[_vm._v(" ___ ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.slice( 0, item.created_at.lastIndexOf("T") ))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type == 1)?_c('v-chip',{staticClass:"px-2 white--text",attrs:{"color":"blue","x-small":""}},[_vm._v(" "+_vm._s(_vm.keys.payments_page_type_employee)+" ")]):(item.type == 2)?_c('v-chip',{staticClass:"px-2 white--text",attrs:{"color":"primary","x-small":""}},[_vm._v(" "+_vm._s(_vm.keys.payments_page_type_company)+" ")]):_c('v-chip',{staticClass:"px-2",attrs:{"color":"gray","x-small":""}},[_vm._v(" "+_vm._s(_vm.keys.payments_page_type_public)+" ")])]}},{key:"item.reciver",fn:function(ref){
var item = ref.item;
return [(item.type != 3)?_c('v-chip',{staticClass:"px-2 white--text",attrs:{"color":item.type == 1 ? 'blue' : 'primary',"x-small":""}},[_vm._v(" "+_vm._s(item.reciver.name)+" ")]):_c('span',[_vm._v("___")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.$emit('edit_payment', JSON.parse(JSON.stringify(item)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.get_delete_id(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center d-block"},[_vm._v(" "+_vm._s(_vm.keys.payments_page_delete_title)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"loading":_vm.delete_loader,"depressed":"","color":"error","small":""},on:{"click":function($event){return _vm.delete_payment()}}},[_vm._v(" "+_vm._s(_vm.keys.payments_page_delete_save)+" ")]),_c('v-btn',{attrs:{"small":"","depressed":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(" "+_vm._s(_vm.keys.payments_page_delete_close)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }