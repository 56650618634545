<template>
  <div>
    <v-container>
      <!-- ***** Start    filter  ***** --> 
      <v-navigation-drawer color="white" v-model="drawer" top fixed>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold"
              >فلتر الفاتوتير</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-icon>
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" @click="drawer = false" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>close</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-text-field label="الأسم"></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-dialog
              ref="dialog"
              v-model="inputDate.modal"
              :return-value.sync="inputDate.date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="inputDate.date"
                  label="Picker in dialog"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="inputDate.date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="inputDate.modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(inputDate.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-radio-group v-model="radioGroup">
              <v-radio
                v-for="a in n"
                :key="a"
                :label="`${a}`"
                :value="a"
              ></v-radio>
            </v-radio-group>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-text-field label="رقم الفاتور"></v-text-field>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="py-0">
            <v-text-field label="وصف الفاتورة"></v-text-field>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-select :items="[1, 23, 4]" label="نوع الحقل"></v-select>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <h5>مبلغ الفاتورة:</h5>
            <v-col cols="6">
              <v-text-field
                :value="range[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                @change="$set(range, 0, $event)"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="range[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                @change="$set(range, 1, $event)"
              ></v-text-field>
            </v-col>

            <v-range-slider
              v-model="range"
              :max="max"
              :min="min"
              hide-details
              class="align-center"
            >
            </v-range-slider>
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>
      <!--  *****  end filter *****  --> 

      <v-tooltip bottom nudge-bottom="-5">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="0"
            color="primary"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="drawer = !drawer"
          >
            <v-icon>filter_alt</v-icon>
          </v-btn>
        </template>
        <span>Filter</span>
      </v-tooltip>
      <!--/ filter btn -->

      <v-card loading class="mb-10 mt-5" outlined elevation="0">
        <v-card-title>
          الفواتير
          <v-spacer></v-spacer>
        </v-card-title>
        <v-simple-table class="pb-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(item, i) in table.headers"
                  :key="i"
                >
                  <span class="d-inline-block">
                    {{ item.text }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in table.tbody" :key="i">
                <th class="gray fixedColumns">
                  <span class="d-inline-block">{{ item.name }}</span>
                </th>
                <td v-for="(data, a) in item.data" :key="a">
                  {{ data }}
                </td>
                <td>
                  <v-btn icon small>
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn icon small>
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!--/ table --> 
      </v-card>
      <!--/ card --> 
    </v-container>
    <!-- / container --> 
  </div>
</template>

<style lang="scss" scoped>

tr:last-of-type td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>

<script>
export default {
  name: "invoices",
  data() {
    return {
      min: -50,
      max: 90,
      range: [-20, 70],
      drawer: false,
      radioGroup: "حسب تاريخ الفاتورة",
      n: ["حسب تاريخ الفاتورة", "حسب تاريخ انشاء الفاتورة"],
      inputDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        modal: false,
      },
      table: {
        headers: [
          { text: " رقم الفاتورة	" },
          { text: "التاريخ	" },
          { text: "نوع الفاتورة	" },
          { text: "اسم الزبون	" },
          { text: "وصف" },
          { text: "المبلغ" },
          { text: "اجراءات" },
        ],
        tbody: [
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "البناء حسب العقد	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "البناء حسب العقد	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
        ],
      },
    };
  },
};
</script>
