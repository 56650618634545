<template>
  <v-container>
    <v-card elevation="0" outlined class="mb-10">
      <v-card-title>
        يوميات العمال
        <v-spacer></v-spacer>
        <v-dialog max-width="500">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom nudge-bottom="-5">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...dialog, ...tooltip }"
                  v-bind="attrs"
                  depressed
                  color="primary"
                  >اضافة</v-btn
                >
              </template>
              <span>اضافة</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title> اضافة تحليل </v-card-title>
            <!--/title -->
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    class="mt-2"
                    :items="['one', 'two']"
                    label="اختيار المشروع"
                    hide-details
                  ></v-combobox>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="التاريخ"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date"></v-date-picker>
                  </v-menu>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field hide-details label="عدد العمال"></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-details
                    label="وصف حالة الطقس"
                  ></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12" md="6">
                  <v-text-field hide-details label="عدد المعدات"></v-text-field>
                </v-col>
                <!--/col -->
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    rows="2"
                    label="ملاحظات المقاول"
                  ></v-textarea>
                </v-col>
                <!--/col -->
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    rows="2"
                    label="ملاحظات مراقب العمل"
                  ></v-textarea>
                </v-col>
                <!--/col -->
                <v-col cols="12">
                  <v-textarea
                    hide-details
                    rows="2"
                    label="ملاحظات  المشرف"
                  ></v-textarea>
                </v-col>
                <!--/col -->
                <v-col clos="12" md="6">
                  <label class="mb-2 mb-3 d-block">توقيع المشرف</label>
                  <div class="signature pa-2">
                    <vueSignature
                      ref="signature"
                      :sigOption="option"
                      :disabled="disabled"
                      :w="'180px'"
                      :h="'150px'"
                      class="mx-auto"
                    ></vueSignature>
                    <v-btn class="mt-2" color="red accent" text @click="clear"
                      >حذف</v-btn
                    >
                    <v-btn class="mt-2" @click="save">حفظ</v-btn>
                  </div>
                </v-col>

                <!--/col -->
                <v-col clos="12" md="6">
                  <label class="mb-2 mb-3 d-block">توقيع المشرف</label>
                  <div class="signature pa-2">
                    <vueSignature
                      ref="signature2"
                      :sigOption="option"
                      :disabled="disabled"
                      :w="'180px'"
                      :h="'150px'"
                      class="mx-auto"
                    ></vueSignature>
                    <v-btn class="mt-2" color="red accent" text @click="clear"
                      >حذف</v-btn
                    >
                    <v-btn class="mt-2" @click="clear">حفظ</v-btn>
                  </div>
                </v-col>
                <!--/col -->
              </v-row>
              <!--/row -->
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text color="primary" class="font-weight-bold">حفظ</v-btn>
              <v-btn text color="red" class="font-weight-bold"> الفاء</v-btn>
            </v-card-actions>
            <!--/card actions -->
          </v-card>
        </v-dialog>
        <!--/dialog -->
      </v-card-title>
      <!--/card title -->
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          hide-default-footer
          :headers="table.headers"
          :items="table.body"
          ref="html2Pdf"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              class="white--text px-2"
              x-small
              :class="[
                { primary: item.status == 0 },
                { 'red accent-1': item.status == 1 },
              ]"
            >
              {{ item.status == 0 ? "فعال" : "غير فعال" }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="generateReport()" icon small>
              <v-icon small>print</v-icon>
            </v-btn>
            <v-btn icon small>
              <v-icon small @click="item">edit</v-icon>
            </v-btn>
            <v-btn icon small>
              <v-icon small @click="item">delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!--/data table -->
      </v-card-text>
      <!--/card text -->
    </v-card>
    <!--/card -->
    <!-- :enable-download="true" -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section style="padding: 50px" slot="pdf-content">lorem</section>
    </vue-html2pdf>
  </v-container>
</template>

<style>
.signature {
  background: #ddd;
}
</style>
<script>
import VueHtml2pdf from "vue-html2pdf";
import vueSignature from "vue-signature";
export default {
  name: "staffDays",
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      date: null,
      table: {
        headers: [
          {
            text: "اسم المشروع",
            align: "center",
            value: "name",
          },
          {
            text: "التاريخ",
            align: "center",
            value: "date",
          },
          {
            text: "عدد العمال",
            align: "center",
            value: "staffCount",
          },
          {
            text: "عدد المعدات",
            align: "center",
            value: "stuffCount",
          },
          {
            text: "توقيع المقاول",
            align: "center",
            value: "contractorSignature",
          },
          {
            text: "توقيع المشرف",
            align: "center",
            value: "superVisorSignature",
          },
          {
            text: "الحالة",
            align: "center",
            value: "status",
          },
          {
            text: "اجراءات",
            align: "center",
            value: "actions",
          },
        ],
        body: [
          {
            name: "دائرة",
            date: "20-20-2020",
            staffCount: 1,
            stuffCount: "ملاحظات",
            contractorSignature: "hassan",
            superVisorSignature: "ahmed",
            status: 0,
          },
          {
            name: "دائرة",
            date: "20-20-2020",
            staffCount: 1,
            stuffCount: "ملاحظات",
            contractorSignature: "hassan",
            superVisorSignature: "ahmed",
            status: 1,
          },
        ],
      },
    };
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    toDataURL() {
      const dataURL = this.$refs.signaturePad.toDataURL();
      console.log(dataURL);
    },
    save() {
      var _this = this;
      var png = this.$refs.signature.save();
      var jpeg = this.$refs.signature.save("image/jpeg");
      var svg = this.$refs.signature.save("image/svg+xml");
      console.log(png);
      console.log(jpeg);
      console.log(svg);
    },
    clear() {
      this.$refs.signature.clear();
    },
    // undo() {
    //   var _this = this;
    //   _this.$refs.signature.undo();
    // },
    // fromDataURL(url) {
    //   var _this = this;
    //   _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    // },
  },
  components: {
    VueHtml2pdf,
    vueSignature,
  },
};
</script>
