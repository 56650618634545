<template>
  <div class="login">
    <v-app-bar fixed flat color="transparent">
      <v-container fluid class="py-0 fill-height">
        <v-btn fab depressed @click="toggleDarkMode()">
          <v-icon> dark_mode </v-icon>
        </v-btn>
        <v-spacer />
        <router-link to="/">
          <img src="../assets/logo.png" width="110" class="mt-1 me-3" />
        </router-link>
      </v-container>
    </v-app-bar>
    <!--/end app-bar -->

    <div class="login__wrapper text-center">
      <v-row no-gutters>
        <v-col cols="6" class="d-none d-lg-block">
          <div class="login__bg">
            <LottieAnimation
              ref="anim"
              :animationData="require('@/assets/login/bg.json')"
              :loop="true"
              style="width: 100%"
            ></LottieAnimation>
          </div>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="login__colForm align-center justify-center"
        >
          <div class="login__form">
            <h1 class="login__h1 text-h2 font-weight-bold">حساب جديد</h1>
            <v-stepper
              background-color="primary"
              class="elevation-0"
              v-model="e1"
            >
              <v-stepper-header class="elevation-0">
                <v-stepper-step :complete="e1 > 1" step="1">
                  نوع التسجيل
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
               بيانات التسجيل
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  تم التسجيل<v-icon>celebration</v-icon>
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card color="transparent">
                    <v-card-text class="px-0 pb-0 pt-1">
                      <v-radio-group v-model="type" row>
                        <!-- <v-radio label="شركة" value="0"></v-radio> -->
                        <v-radio label="موظف" value="1"></v-radio>
                      </v-radio-group>

                      <div class="text-center mt-5 mb-10">
                        <img
                          v-if="type == 0"
                          width="100"
                          src="https://cdn-icons-png.flaticon.com/512/3061/3061341.png"
                        />
                        <img
                          v-if="type == 1"
                          width="100"
                          src="https://cdn-icons-png.flaticon.com/512/3061/3061341.png"
                        />
                      </div>

                      <div class="text-center">
                        <v-btn
                          x-large
                          @click="e1 = 2"
                          color="primary"
                          width="200"
                          >التالي</v-btn
                        >
                      </div>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
                <!--/ steppter 1 -->

                <v-stepper-content step="2">
                  <companyFrom v-if="type == 0"></companyFrom>
                  <userForm @reg_done="e1 = 3" v-if="type == 1"></userForm>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <div class="text-center">
                    <h4 class="text-center font-weight-medium primary--text">
                      تم التسجيل بنجاح
                    </h4>
                    <LottieAnimation
                      ref="anim"
                      :animationData="require('@/assets/success.json')"
                      :loop="true"
                      style="width: 250px; margin: 0 auto"
                    ></LottieAnimation>

                    <v-btn to="/" color="primary" rounded x-large outlined
                      >  تسجيل الدخول</v-btn
                    >
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .v-stepper {
    background-color: transparent !important;
  }
  .v-input--radio-group__input {
    justify-content: center;
  }
}
.v-btn--fab.v-size--default {
  width: 40px;
  height: 40px;
}
.login {
  &__wrapper {
    min-height: 100vh;
  }
  &__colForm {
    height: 100vh;
  }
  &__bg {
    background: #cfdeffba;
    width: 100%;
    height: 100%;
    padding-top: 100px;
  }
  &__form {
    padding: 120px 25px;
  }
  form {
    width: 100%;
  }
  &__h1 {
    margin-bottom: 78px;
    &.text-h2 {
      @media (max-width: 767px) {
        margin-bottom: 30px;
        font-size: 30px !important;
      }
    }
  }
  &__btn.v-size--x-large {
    width: calc(100% - 220px);
    padding: 30px 40px;
    height: 60px !important;
    line-height: 85px;
    font-size: 18px;
    font-weight: bold;
    &#google {
      width: 200px;
      &:hover {
        &:before {
          background-color: #55c0b4 !important;
          opacity: 1;
        }

        color: #fff !important;
      }
    }
  }
}
.theme--dark {
  .login__bg {
    filter: grayscale(1);
  }
}
</style>
<script>
import LottieAnimation from "lottie-web-vue";
import companyFrom from "@/components/signup/company_form.vue";
import userForm from "@/components/signup/user_form.vue";

export default {
  name: "signup",
  data: () => ({
    e1: 1,
    type: "1",
    checking: false,
    trans: {
      categories: {},
    },
    snackbar: {
      prop: false,
      err: true,
      errColor: "red lighteen-2",
      succColor: "primary",
      errText: "Username or password not valid",
      succText: "Login success",
    },
    valid: true,
    email: "company2@mail.com",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: "password",
    passwordRules: [(v) => !!v || "Password is required"],
  }),
  components: {
    LottieAnimation,
    companyFrom,
    userForm,
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
