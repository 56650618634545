<template>
  <div>
    <v-container>
      <v-card elevation="0" outlined class="mb-10">
        <v-card-title>
          جميل التحليلات
          <v-spacer></v-spacer>
          <v-dialog max-width="500">
            <template v-slot:activator="{ on:dialog, attrs }">
              <v-tooltip bottom nudge-bottom="-5">
                <template v-slot:activator="{ on:tooltip }">
                  <v-btn v-on="{...dialog,...tooltip}" v-bind="attrs" depressed color="primary"
                    >اضافة</v-btn
                  >
                </template>
                <span>اضافة</span>
              </v-tooltip>
            </template>

            <v-card>
              <v-card-title> اضافة تحليل </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-text-field label="اسم التحليل"></v-text-field>
                <v-select
                  label="شكل التحليل"
                  :items="['pie_chart', 'signal_cellular_alt']"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon>{{ item }}</v-icon>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon>{{ item }}</v-icon>
                  </template>
                </v-select>

                <v-combobox
                  label="مقارنة بين"
                  multiple
                  :items="['one', 'two', 'three']"
                  chips
                ></v-combobox>

                <v-text-field label="وصف التحليل"></v-text-field>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn text color="red">الفاء</v-btn>
                <v-btn text color="primary">حفظ</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :headers="table.headers"
            :items="table.body"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.shape`]="{ item }">
              <v-icon small>{{ item.shape }}</v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small>
                <v-icon small @click="item">edit</v-icon>
              </v-btn>
              <v-btn icon small>
                <v-icon small @click="item">delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col cols="12" lg="6">
          <zingchart :data="chartData" :height="300" :width="600" />
        </v-col>
        <v-col cols="12" lg="6">
          <zingchart
            :data="chartData2"
            :series="mySeries"
            :height="300"
            :width="600"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <zingchart :data="chartData3" :height="300" :width="600" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "charts",
  data() {
    return {
      table: {
        headers: [
          {
            text: "اسم التحليل",
            align: "center",
            value: "name",
          },
          {
            text: "شكل التحليل",
            align: "center",
            value: "shape",
          },
          {
            text: "وصف التحليل",
            align: "center",
            value: "desc",
          },
          {
            text: "اجراءات",
            align: "center",
            value: "actions",
          },
        ],
        body: [
          {
            name: "دائرة",
            shape: "pie_chart",
            quantity: 1,
            desc: "ملاحظات",
          },
          {
            name: "بار",
            shape: "signal_cellular_alt",
            quantity: 1,
            desc: "ملاحظات",
          },
          {
            name: "خطوط",
            shape: "show_chart",
            quantity: 1,
            desc: "ملاحظات",
          },
        ],
      },
      chartData: {
        type: "line",

        // plot represents general series, or plots, styling
        plot: {
          lineWidth: "3px",
          marker: {
            borderWidth: "0px",
            size: "6px",
          },
        },
        plotarea: {
          margin: "85px",
        },
        scaleX: {
          // convert text on scale indices
          values: "30:80:5",
        },
        scaleY: {
          values: "0:500000:100000",
          labels: ["0", "100K", "200K", "300K", "400K", "500K"],
        },
        crosshairX: {
          plotLabel: {
            _padding: "10px 15px",
            borderRadius: "3px",
            color: "#5D7D9A",
            padding: "10px",
            backgroundColor: "#fff",
            thousandsSeparator: ",",
          },
        },
        series: [
          {
            _text: "Week 1",
            // plot values
            values: [
              20000, 15000, 40000, 55000, 22000, 60000, 50000, 115000, 45000,
              47000, 45000,
            ],
            lineColor: "#48d8c5",
            marker: {
              backgroundColor: "#48d8c5",
            },
          },
          {
            _text: "Week 2",
            // plot values
            values: [
              105000, 190000, 140000, 195000, 210000, 295000, 415000, 370000,
              330000, 350000, 320000,
            ],
            lineColor: "#3290be",
            marker: {
              backgroundColor: "#3290be",
            },
          },
        ],
      },
      chartData2: {
        type: "pie3d",
        plot: {
          valueBox: [
            {
              text: "%t: %v",
              fontSize: "16px",
              placement: "out",
            },
            {
              text: "%npv%",
              fontColor: "#1A1B26",
              fontSize: "16px",
              placement: "in",
            },
          ],
          refAngle: 270,
        },
        series: [
          {
            values: [400],
            backgroundColor: "#FB7373",
          },
          {
            values: [350],
            backgroundColor: "#FFA089",
          },
          {
            values: [250],
            backgroundColor: "#F2C994",
          },
        ],
      },
      chartData3: {
        type: "bar3d",
        plot: {
          valueBox: [
            {
              text: "%v",
              fontSize: "10px",
              placement: "out",
              fontColor: "#000",
            },
          ],
          refAngle: 100,
        },
        "3dAspect": {
          depth: 30,
          true3d: 0,
          yAngle: 10,
        },
        backgroundColor: "#fff",
        title: {
          text: "Product Sales Comparison",
          fontWeight: "normal",
          height: "40px",
          textColor: "#ffffff",
        },
        legend: {
          backgroundColor: "none",
          borderColor: "none",
          item: {
            fontColor: "#333",
          },
          layout: "float",
          shadow: false,
          width: "90%",
          x: "37%",
          y: "10%",
        },
        plotarea: {
          margin: "95px 35px 50px 70px",
          alpha: 0.3,
          backgroundColor: "#fff",
        },
        scaleX: {
          values: ["January", "February", "March", "April", "May", "June"],
          alpha: 0.5,
          backgroundColor: "#fff",
          borderColor: "#333",
          borderWidth: "1px",
          guide: {
            visible: false,
          },
          item: {
            fontColor: "#333",
            fontSize: "11px",
          },
          tick: {
            alpha: 0.2,
            lineColor: "#333",
          },
        },
        scaleY: {
          alpha: 0.5,
          backgroundColor: "#fff",
          borderColor: "#333",
          borderWidth: "1px",
          format: "$%v",
          guide: {
            alpha: 0.2,
            lineColor: "#333",
            lineStyle: "solid",
          },
          item: {
            paddingRight: "6px",
            fontColor: "#333",
          },
          tick: {
            alpha: 0.2,
            lineColor: "#333",
          },
        },
        series: [
          {
            text: "Product 1",
            values: [12, 41, 77, 33, 55, 90],
            tooltip: {
              text: "$%v",
              padding: "6px 12px",
              backgroundColor: "#03A9F4",
              borderColor: "none",
              borderRadius: "5px",
              fontSize: "12px",
              shadow: false,
            },
            backgroundColor: "#03A9F4 #4FC3F7",
            borderColor: "#03A9F4",
            legendMarker: {
              borderColor: "#03A9F4",
            },
          },
          {
            text: "Product 2",
            values: [70, 20, 40, 30, 66, 55],
            tooltip: {
              text: "$%v",
              padding: "6px 12px",
              backgroundColor: "#673AB7",
              borderColor: "none",
              borderRadius: "5px",
              fontSize: "12px",
              shadow: false,
            },
            backgroundColor: "#673AB7 #9575CD",
            borderColor: "#673AB7",
            legendMarker: {
              borderColor: "#673AB7",
            },
          },
        ],
      },
    };
  },
};
</script>
