<template>
  <div>
    <v-col cols="6" sm="4" class="mb-3" v-if="data_loaded">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="date"
            persistent-hint
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          type="month"
          @input="(menu = false), date_changed($event)"
        ></v-date-picker>
      </v-menu>
    </v-col>

    <v-row v-if="!data_loaded">
      <v-col cols="12" sm="3" v-for="(a, i) in 4" :key="i">
        <v-skeleton-loader type="article"> </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="data_loaded">
      <v-col cols="12" sm="3" class="pa-2">
        <v-card elevation="0" class="stat" color="#847da4">
          <v-card-text class="text-center">
            <p class="stat__p">{{ keys.work_times_page_total_days }}</p>
            <h2 class="stat__h2 my-5">{{ totals ? totals.days : 0 }}</h2>
            <p class="stat__p">
              {{ totals ? totals.days_count : 0 }}
              {{ keys.work_times_page_count_days }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/ COL --->
      <v-col cols="12" sm="3" class="pa-2">
        <v-card class="stat" elevation="0" color="#f39a00">
          <v-card-text class="text-center">
            <p class="stat__p">{{ keys.work_times_page_total_hours }}</p>
            <h2 class="stat__h2 my-5">{{ totals ? totals.hours : 0 }}</h2>
            <p class="stat__p">
              {{ totals ? totals.hours_count : 0 }}
              {{ keys.work_times_page_count_hours }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/ col -->
      <v-col cols="12" sm="3" class="pa-2">
        <v-card class="stat" elevation="0" color="#006bf3">
          <v-card-text class="text-center">
            <p class="stat__p">{{ keys.work_times_page_total_payemnts }}</p>
            <h2 class="stat__h2 my-5">
              {{ totals ? totals.total_payment : 0 }}
            </h2>
            <p class="stat__p">
              {{ totals ? totals.payed : 0 }}
              {{ keys.work_times_page_total_paid }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!--/ col -->
      <v-col cols="12" sm="3" class="pa-2">
        <v-card class="stat" elevation="0" color="#756e6e">
          <v-card-text class="text-center">
            <p class="stat__p">{{ keys.work_times_page_total_remaining }}</p>
            <h2 class="stat__h2 my-5">{{ totals ? totals.remainder : 0 }}</h2>
            <p class="stat__p">&nbsp;</p>
          </v-card-text>
        </v-card>
        <!--/ card -->
      </v-col>
      <!--/ col -->
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["totals", "data_loaded", "keys"],
  name: "Stats",
  data() {
    return {
      menu: false,
      date: new Date().toISOString().substr(0, 7),
    };
  },
  computed: {
    days() {
      return this.totals ? this.totals.days : 0;
    },
  },
  methods: {
    date_changed(ev) {
      let full_date = `${ev}-01`;

      this.$emit("date_changed", full_date);
    },
  },
};
</script>
<style lang="scss" scoped>
.stat {
  &__p,
  &__h2 {
    color: #fff !important;
  }
  &__p {
    font-size: 16px;
  }
  &__h2 {
    font-size: 40px;
  }
}
</style>
