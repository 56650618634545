<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!tbody"> </v-skeleton-loader>

    <v-card v-if="tbody" elevation="0" outlined>
      <v-card-title>
        {{ keys.c_companies_page_title }}
        <v-spacer></v-spacer>
        <v-btn
          :to="{ name: 'c_add_companies_page' }"
          color="primary"
          depressed
          >{{ keys.c_companies_page_add_btn }}</v-btn
        >
      </v-card-title>
      <!--/ title -->
      <v-divider></v-divider>
      <v-data-table :items="tbody" mobile-breakpoint="0" :headers="headers">
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <img class="me-2" width="40" :src="item.image" />
        </template>
        <template v-slot:[`item.services`]="{ item }">
          <v-btn
            text
            :class="[
              { 'black--text': item.added_services == 0 },
              { 'primary--text': item.added_services == 1 },
            ]"
            :to="{ name: 'c_add_services_page', query: { id: item.id } }"
          >
            <v-chip
              :class="[
                { primary: item.added_services != 0 },
                { gray: item.added_services == 0 },
              ]"
              x-small
              class="px-2 me-2"
            >
              {{ item.added_services }}</v-chip
            >
            {{ keys.c_companies_page_services }}
          </v-btn>
        </template>
        <!--/ services -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small icon>
            <v-icon small>block</v-icon>
          </v-btn>
          <!--/ block company  -->
          <v-btn @click="get_deletd_id(item.id)" small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
          <!--/ delete company -->
        </template>
      </v-data-table>
      <!--/card text -->
    </v-card>
    <!--/card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.c_companies_page_delete_popup_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            :disabled="delete_loader"
            @click="delete_company()"
            depressed
            color="error"
            >{{ keys.c_companies_page_delete_popup_delete_btn }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.c_companies_page_delete_popup_close_btn
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
  <!--/container -->
</template>

<script>
export default {
  name: "companies",
  data() {
    return {
      delete_id: null,
      delete_dialog: false,
      delete_loader: false,
      post_contacts_config: {
        url: `/company/contacts/list`,
        method: "get",
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.keys.c_companies_page_company_name,
          value: "name",
          align: "center",
        },
        {
          text: this.keys.c_companies_page_image,
          value: "image",
          align: "center",
        },
        {
          text: this.keys.c_companies_page_services,
          value: "services",
          align: "center",
        },
        {
          text: this.keys.c_companies_page_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    tbody() {
      return this.$store.state.pages_data.companies || null;
    },
  },
  methods: {
    get_contacts() {
      this.$store.dispatch("public__request", {
        config: this.post_contacts_config,
        get: "companies",
      }).then(res=> {
        console.log(res);
      })
    },
    delete_company() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "delete",
            url: `company/contacts/${this.delete_id}`,
          },
        })
        .then((res) => {
          this.delete_dialog = false;
          this.delete_loader = false;
          this.get_contacts();
        });
    },
    get_deletd_id(id) {
      this.delete_id = id;
      this.delete_dialog = true;
      console.log(id);
    },
  },
  mounted() {
    this.get_contacts();
  },
};
</script>
