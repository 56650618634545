<template>
  <div>
    <v-container>
      <v-card elevation="0" outlined class="mb-5 mb-5">
        <v-card-title> نوع التسعيرة </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col md="3" cols="6">
              <v-text-field hide-details label="نوع التسعيرة"></v-text-field>
            </v-col>
            <v-col md="3" cols="6">
              <v-select
                hide-details
                label="فئة السعر"
                :items="['cat1', 'cat2']"
              ></v-select>
            </v-col>
            <v-col md="3" cols="6">
              <v-select
                label="مساحة العرض"
                :items="['50%', '100%']"
                item-value="value"
                item-text="text"
                hide-details
              >
              </v-select>
            </v-col>
            <v-col md="3" cols="6">
              <v-text-field hide-details label="ملاحظات"></v-text-field>
            </v-col>
          </v-row>
          <!--/basic info-->

          <v-row class="align-items" v-for="(item, i) in priceField" :key="i">
            <v-col md="3">
              <v-text-field
                v-model="priceField[i].textField"
                label="اسم الخانة"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="3" cols="6">
              <v-select
                label="نوع الحقل"
                :items="itemSelectType"
                item-value="value"
                item-text="text"
                v-model="priceField[i].typeField"
                hide-details
              >
              </v-select>
            </v-col>
            <v-col md="2" cols="6">
              <v-select
                label="نوع الادخال"
                :items="['ثابت', 'ديناميكي']"
                item-value="value"
                item-text="text"
                v-model="priceField[i].typeField"
                hide-details
              >
              </v-select>
            </v-col>
            <v-col md="2" cols="6">
              <v-select
                label="شامل"
                :items="['شامل', 'غير شامل']"
                item-value="value"
                item-text="text"
                v-model="priceField[i].typeField"
                hide-details
              >
              </v-select>
            </v-col>
            <v-col v-if="i != 0" class="text-end">
              <v-btn
                @click="priceField.splice(i, 1)"
                elevation="1"
                color="red"
                class="white--text"
                small
                fab
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <div class="text-end mb-10 mb-md-0 mt-5">
            <v-tooltip v-on="on" v-bind="attrs" bottom nudge-bottom="-5">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="priceField.push({ typeField: '', textField: '' })"
                  outlined
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>اضافة </span>
            </v-tooltip>
          </div>

          <div>
            <v-combobox
              v-model="model"
              :filter="filter"
              :hide-no-data="!search"
              :items="items"
              :search-input.sync="search"
              hide-selected
              label="اعداد المعادلة"
              multiple
              small-chips
            >
              <template v-slot:no-data>
                <v-list-item>
                  <span class="subheading">Create</span>
                  <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
                    {{ search }}
                  </v-chip>
                </v-list-item>
              </template>

              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :color="`${item.color} lighten-3`"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">
                    {{ item.text }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>

              <template v-slot:item="{item }">
                <v-chip :color="`${item.color} lighten-3`" dark label small>
                  {{ item.text }}
                </v-chip>
              </template>
            </v-combobox>
          </div>

          <v-alert
            icon="error_outline"
            border="bottom"
            colored-border
            type="warning"
            elevation="0"
            class="px-0 mb-0"
          >
            يمكنك استعمال الـ ( + أو - أو x أو / للتقسيم بالإضافة الى استعمال
            الأقواس )
          </v-alert>
        </v-card-text>
      </v-card>
      <v-tooltip v-on="on" v-bind="attrs" bottom nudge-bottom="-5">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            large
            depressed
            class="mb-10"
            color="primary"
            >حفظ</v-btn
          >
        </template>
        <span>اضافة </span>
      </v-tooltip>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .v-subheader {
    font-weight: bold !important;
  }
  .v-alert {
    @media (max-width: 580px) {
      font-size: 12px;
    }
  }
}
</style>
<script>
export default {
  name: "setPrices",
  data() {
    return {
      activator: null,
      attach: null,
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
      editingIndex: -1,
      emptyFields: {
        textField: "",
        typeField: "",
      },
      itemSelectType: [
        {
          text: "نص",
          value: 0,
        },
        {
          text: "مرفق",
          value: 1,
        },
        {
          text: "دروب داون",
          value: 2,
        },
      ],
      priceField: [
        {
          textField: "",
          typeField: "",
        },
      ],
      items: [
        { header: "Group 1" },
        {
          text: "Foo",
          color: "blue",
        },
        {
          text: "Bar",
          color: "red",
        },
        {
          divider: true,
        },
        { header: "Group 2" },
        {
          text: "Fo2o",
          color: "blue",
        },
        {
          text: "Bar2",
          color: "red",
        },
      ],
      nonce: 1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      y: 0,
    };
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };
          this.nonce++;
        }

        return v;
      });
    },
  },
  methods: {
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
};
</script>
