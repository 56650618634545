<template>
  <v-container>
    <div v-if="page_loader">
      <v-skeleton-loader v-bind="attrs" type="article"> </v-skeleton-loader>
      <v-skeleton-loader v-bind="attrs" type="article,actions">
      </v-skeleton-loader>
    </div>
    <v-snackbar color="error" :timeout="3000" right v-model="alert">
      {{
        page_problem
          ? keys.new_check_page_errors_page
          : keys.new_check_page_inputs_empty
      }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs"> error </v-icon>
      </template>
    </v-snackbar>

    <div v-if="!page_loader" class="mb-10">
      <v-card outlined class="mb-3">
        <v-card-text>
          <v-col cols="12" class="pa-0" sm="4">
            <v-select
              :error="project_id_err"
              :prepend-icon="project_id_err ? 'error_outline' : ''"
              :disabled="projects_done"
              :loading="projects_done"
              hide-details
              :items="projects"
              item-text="name"
              item-value="id"
              v-model="project_id"
              @change="set_project_id($event)"
            >
              <template slot="label">
                <strong class="error--text">*</strong>
                {{ keys.new_check_page_projects_select }}
              </template>
            </v-select>
          </v-col>
        </v-card-text>
      </v-card>

      <v-card elevation="0" outlined class="mb-5">
        <v-card-text v-for="(check, ind) in checks" :key="ind">
          <div class="d-flex align-center">
            <v-tabs class="mb-3" v-model="check.tabs" background-color="gray">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab
                v-model="check.tabs"
                v-for="(lang, la) in languages"
                :key="la"
              >
                {{ lang.native }}
              </v-tab>
            </v-tabs>
            <!--/tabs -->

            <div class="text-end ms-3">
              <v-btn
                @click="
                  (delete_dialog = true),
                    (delete_id = check.id),
                    (delete_index = ind)
                "
                elevation="1"
                fab
                small
                color="red"
                class="white--text"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
          </div>

          <v-tabs-items v-model="check.tabs">
            <v-tab-item v-for="(lang, la) in languages" :key="la">
              <v-row
                :dir="lang.direction"
                class="pt-4 pb-4"
                :class="[
                  { 'text-left': lang.direction == 'ltr' },
                  { 'text-right': lang.direction == 'rtl' },
                ]"
              >
                <v-col v-if="check.tabs == 0" md="4" cols="6">
                  <v-select
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                    :items="types"
                    item-text="text"
                    item-value="value"
                    hide-details
                    v-model="check.type"
                    :error="check.errors.type_err"
                    :prepend-icon="check.errors.type_err ? 'error_outline' : ''"
                  >
                    <template slot="label">
                      <strong class="error--text">*</strong>
                      {{ keys.new_check_page_type }}
                    </template>
                  </v-select>
                </v-col>
                <!--/ col -->
                <v-col md="4" cols="6">
                  <v-text-field
                    hide-details
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                    label="ملاحظات"
                    :value="check.notes[lang.locale]"
                    @blur="notes(ind, lang.locale, $event.target.value)"
                    :error="check.errors.notes_err && check.tabs == 0"
                    :prepend-icon="
                      check.errors.notes_err && check.tabs == 0
                        ? 'error_outline'
                        : ''
                    "
                  >
                    <template slot="label">
                      <strong v-if="check.tabs == 0" class="error--text"
                        >*</strong
                      >
                      {{ keys.new_check_page_check_notes }}
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col v-if="check.tabs == 0" cols="6" md="4">
                  <v-text-field
                    hide-details
                    @click="upload_dialog(ind)"
                    :class="[
                      { 'right-label': lang.direction == 'rtl' },
                      { 'left-label': lang.direction == 'ltr' },
                    ]"
                    :value="`${keys.new_check_page_check_files} ${check.files.length}`"
                  >
                    <template v-slot:prepend>
                      <v-btn
                        @click="upload_dialog(ind)"
                        elevation="1"
                        small
                        color="primary"
                        fab
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <!--/ col -->
                <v-col
                  cols="12"
                  class="pb-0"
                  v-for="(status, s) in check.statuses"
                  :key="s"
                >
                  <v-row class="align-end">
                    <v-col v-if="check.tabs == 0" md="3" cols="6">
                      <v-select
                        hide-details
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        v-model="status.status"
                        :items="statuses"
                        item-text="text"
                        item-value="value"
                        :error="status.status_err"
                        :prepend-icon="status.status_err ? 'error_outline' : ''"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.new_check_page_check_status }}
                        </template>
                      </v-select>
                    </v-col>
                    <!--/ col -->
                    <v-col v-if="check.tabs == 0" md="3" cols="6">
                      <v-text-field
                        :class="[
                          { 'right-label': lang.direction == 'rtl' },
                          { 'left-label': lang.direction == 'ltr' },
                        ]"
                        v-model="status.date"
                        append-icon="mdi-calendar"
                        readonly
                        @click="set_date(ind, s)"
                        hide-details
                        :error="status.date_err"
                        :prepend-icon="status.date_err ? 'error_outline' : ''"
                      >
                        <template slot="label">
                          <strong class="error--text">*</strong>
                          {{ keys.new_check_page_check_date }}
                        </template>
                      </v-text-field>
                    </v-col>
                    <!--/ col -->
                    <v-col v-if="check.tabs == 0 && s != 0">
                      <v-btn
                        @click="delete_status(ind, s, status.status_id)"
                        color="red"
                        text
                        icon
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                    <!--/ col -->
                  </v-row>
                </v-col>
                <!--/ col -->
                <v-col cols="12">
                  <v-btn @click="new_status(ind)" depressed color="primary">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
                <!--/ col -->
              </v-row>
              <!--/ row -->
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>

      <div class="d-flex justify-space-between">
        <v-btn @click="new_check" color="primary" outlined>
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn
          :disabled="save_loader"
          :loading="save_loader"
          @click="save()"
          color="primary"
          >{{ keys.new_check_page_check_save }}</v-btn
        >
      </div>
    </div>

    <!--=========== fiels dialog ================ -->
    <v-dialog
      transition="dialog-bottom-transition"
      hide-overlay
      fullscreen
      v-model="dialogFiles"
    >
      <v-card class="rounded-0">
        <v-card-title class="font-weight-bold">
          {{ keys.new_check_page_files_popup_title }}
          <v-spacer></v-spacer>
          <v-btn
            :disabled="progress != 0"
            @click="close_upload_dialog"
            icon
            x-large
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-file-input
            hide-details
            v-model="input_file"
            @change="upload_file()"
            :label="keys.new_check_page_files_popup_input"
            ref="upload_input"
          ></v-file-input>

          <v-progress-linear
            class="mt-2"
            striped
            color="light-blue"
            v-model="progress"
            :active="progress != 0 && progress != 100"
            v-if="progress != 0 && progress != 100"
          ></v-progress-linear>

          <v-row class="mt-5">
            <v-col
              v-for="(file, ind) in checks.length != 0
                ? checks[data_index].files
                : []"
              :key="ind"
              cols="6"
              sm="2"
              class="position-relative text-center"
            >
              <v-card outlined>
                <v-card-text>
                  <img
                    v-if="files_types[ind] == 'pdf'"
                    src="@/assets/files_icon/pdf-file.png"
                    width="100"
                  />
                  <img
                    v-else-if="
                      files_types[ind] == 'docx' || files_types[ind] == 'doc'
                    "
                    width="100"
                    src="@/assets/files_icon/doc.png"
                  />
                  <img
                    v-else-if="
                      files_types[ind] == 'png' ||
                      files_types[ind] == 'jpg' ||
                      files_types[ind] == 'jpeg' ||
                      files_types[ind] == 'jfif' ||
                      files_types[ind] == 'WebP'
                    "
                    width="100"
                    src="@/assets/files_icon/image.png"
                  />
                  <img v-else width="100" src="@/assets/files_icon/file.png" />
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="rgba(0, 0, 0, 0.54)"
                    download
                    :href="file.path"
                    icon
                  >
                    <v-icon>file_download</v-icon>
                  </v-btn>
                  <v-btn
                    :href="file.path"
                    icon
                    v-if="
                      files_types[ind] == 'png' ||
                      files_types[ind] == 'jpg' ||
                      files_types[ind] == 'jpeg' ||
                      files_types[ind] == 'jfif' ||
                      files_types[ind] == 'WebP' ||
                      files_types[ind] == 'pdf'
                    "
                  >
                    <v-icon>visibility</v-icon>
                  </v-btn>
                  <v-btn @click="delete_file(file.file_id, ind)" icon>
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--=========== / End fiels dialog ================ -->

    <!--=========== / start  date dialog ================ -->
    <v-dialog
      ref="dialog"
      v-model="inputDate.modal"
      :return-value.sync="inputDate.date"
      persistent
      width="290px"
    >
      <!--/ template -->
      <v-date-picker v-model="inputDate.date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="inputDate.modal = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="
            $refs.dialog.save(
              (checks[data_index].statuses[status_index].date = inputDate.date)
            )
          "
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <!--=========== / End date dialog ================ -->

    <!--=========== / start  delete dialog ================ -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.checks_page_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            :disabled="delete_loader"
            @click="delete_check()"
            depressed
            color="error"
            >{{ keys.checks_page_delete_button }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.checks_page_delete_close
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
    <!--=========== / end  delete  dialog ================ -->
  </v-container>
</template>

<script>
export default {
  name: "new_check",
  data() {
    return {
      delete_index: null,
      status_index: null,
      delete_id: null,
      delete_dialog: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
      },
      projects: [
        {
          id: null,
          name: "عام",
        },
      ],
      delete_loader: false,
      page_loader: this.$route.query.check ? true : false,
      tabs: 0,
      arr: [0],
      items: [1, 2, 3, 4],
      dialogFiles: false,
      data_index: 0,
      projects_done: true,
      project_id_err: false,
      project_id: this.$route.query.check
        ? parseInt(this.$route.query.check)
        : null,
      progress: 0,
      input_file: null,
      page_problem: false,
      alert: false,
      save_loader: false,

      checks: [
        {
          id: null,
          tabs: 0,
          project_id: null,
          notes: {},
          type: null,
          files: [],
          is_general: true,
          statuses: [
            {
              status_err: false,
              date_err: false,
              date: null,
              status: null,
            },
          ],
          errors: {
            notes_err: false,
            type_err: false,
          },
        },
      ],
      files: [],
      inputDate: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        modal: false,
      },
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    languages() {
      return this.$store.getters.languages;
    },
    default_lang() {
      return this.languages.find((l) => l.is_default);
    },
    types() {
      return [
        {
          text: "0",
          value: 0,
        },

        {
          text: "1",
          value: 1,
        },

        {
          text: "2",
          value: 2,
        },
      ];
    },
    statuses() {
      return [
        {
          text: "لم يتم بعد",
          value: "0",
        },

        {
          text: "تم بنجاح",
          value: "1",
        },

        {
          text: "فشل الفحص",
          value: "2",
        },
      ];
    },
    files_types() {
      let files = this.checks[this.data_index].files;
      let types = [];
      files.forEach((e, i) => {
        types.push(e.path.slice(e.path.lastIndexOf(".") + 1));
      });
      return types;
    },
  },
  methods: {
    set_project_id(ev) {
      this.checks.forEach((e, i) => {
        if (ev == null) {
          this.$set(this.checks[i], "is_general", true);
        } else {
          this.$set(this.checks[i], "is_general", false);
        }
        this.$set(this.checks[i], "project_id", ev);
      });
    },
    get_projects() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: "company/projects",
          },
          get: "projects",
        })
        .then((res) => {
          let projects = res.data;

          projects.forEach((e, i) => {
            this.$set(this.projects, i + 1, e);
          });
          this.projects_done = false;
        });
    },
    upload_dialog(data_index) {
      this.checks[data_index].files.forEach((ev, i) => {
        this.$set(this.files, i, ev);
      });
      this.data_index = data_index;
      this.dialogFiles = true;
    },
    close_upload_dialog() {
      if (this.progress == 0) {
        this.dialogFiles = false;
        this.input_file = null;
      }
    },
    //-------- upload files
    upload_file() {
      const formData = new FormData();
      const vm = this;
      formData.append("file", vm.input_file);
      formData.append("path", "company");
      if (vm.input_file) {
        if (this.source) {
          this.source.cancel();
        }
        this.CancelToken = this.axios.CancelToken;
        this.source = this.CancelToken.source();
        var config = {
          method: "post",
          url: "https://app.kalko.cloud/api/file/upload",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.state.token}`,
          },
          cancelToken: this.source.token,
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            vm.progress = percentCompleted;
            if (vm.progress == 100) {
              vm.progress = 0;
            }
          },
          data: formData,
        };

        this.axios(config)
          .then((res) => {
            let file_index = vm.checks[vm.data_index].files.length;
            this.$set(vm.checks[vm.data_index].files, file_index, {
              path: res.data.path,
            });
          })
          .catch((thrown) => {
            if (this.axios.isCancel(thrown)) {
              vm.progress = 0;
            }
          });
      } else {
        this.source.cancel();
        vm.progress = 0;
      }
    },
    delete_file(file_id, file_index) {
      if (file_id) {
        this.$store
          .dispatch("public__request", {
            config: {
              method: "delete",
              url: `company/project/checks/file/${file_id}`,
            },
          })
          .then(console.log("deleted"));
      }

      this.checks[this.data_index].files.splice(file_index, 1);
    },
    set_date(data_index, status_index) {
      this.data_index = data_index;
      this.status_index = status_index;
      this.inputDate.modal = true;
    },
    notes(data_index, locale, value) {
      this.$set(this.checks[data_index].notes, locale, value);
    },
    new_check() {
      const check = {
        id: null,
        tabs: 0,
        project_id: null,
        notes: {},
        type: null,
        files: [],
        is_general: false,
        statuses: [
          {
            status_err: false,
            date_err: false,
            date: null,
            status: null,
          },
        ],
        errors: {
          notes_err: false,
          type_err: false,
        },
      };
      let check_index = this.checks.length;
      this.$set(this.checks, check_index, check);
    },
    delete_check() {
      if (this.delete_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              method: "delete",
              url: `company/project/checks/${this.delete_id}`,
            },
          })
          .then((res) => {
            this.checks.splice(this.delete_index, 1);
            this.delete_loader = false;
            this.delete_dialog = false;
          });
      } else {
        this.checks.splice(this.delete_index, 1);
        this.delete_dialog = false;
      }
    },
    new_status(check_index) {
      const status = {
        status_err: false,
        date_err: false,
        date: null,
        status: null,
      };
      let check = this.checks[check_index];
      this.$set(check.statuses, check.statuses.length, status);
    },
    delete_status(check_index, status_index, status_id) {
      if (status_id) {
        this.$store.dispatch("public__request", {
          config: {
            method: "delete",
            url: `company/project/checks/status/${this.status_id}`,
          },
        });
      }
      this.checks[check_index].statuses.splice(status_index, 1);
    },
    checks_validation() {
      const err = [];

      this.checks.forEach((check, i) => {
        // type
        if (check.type == null) {
          this.checks[i].errors.type_err = true;
          err.push(i);
        } else {
          this.checks[i].errors.type_err = false;
        }
        // date

        // notes
        if (!check.notes[this.default_lang.locale]) {
          this.checks[i].errors.notes_err = true;
          err.push(i);
        } else {
          this.checks[i].errors.notes_err = false;
        }
        check.statuses.forEach((status, s) => {
          if (!status.status) {
            this.checks[i].statuses[s].status_err = true;
          } else {
            this.checks[i].statuses[s].status_err = false;
          }
          if (!status.date) {
            this.checks[i].statuses[s].date_err = true;
            err.push(i);
          } else {
            this.checks[i].statuses[s].date_err = false;
          }
        });
      });

      if (err.length != 0) {
        this.alert = true;
        return false;
      } else {
        this.alert = false;
        return true;
      }
    },
    save() {
      if (this.checks_validation()) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `/company/project/checks`,
              method: "post",
            },
            data: {
              data: this.checks,
            },
          })
          .then((res) => {
            this.$router.push("/checkes");

          })
          .catch((err) => {
            console.log(err.response);
            this.save_loader = false;
            this.page_problem = true;
          });
      }
    },

    get_checks() {
      this.$store
        .dispatch("public__request", {
          config: {
            method: "get",
            url: `company/project/checks/${this.$route.query.check}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          res.data.forEach((e, i) => {
            this.$set(this.checks, i, e);
            this.page_loader = false;
          });
        });
    },
  },
  created() {
    this.get_projects();

    if (this.$route.query.check) {
      this.get_checks();
    }
  },
};
</script>
