<template>
  <v-container>
    <h3 class="mb-4 font-weight-regular">{{keys.c_requests_title}}</h3>
    <div v-if="!requests" class="row">
      <v-skeleton-loader
        v-for="(a, i) in new Array(6)"
        :key="i"
        elevation="0"
        outlined
        v-bind="attrs"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </div> 
    <v-alert dense class="text-center" v-if="empty" type="warning"
      >{{keys.c_requests_alert}}</v-alert
    >

    <v-row v-if="requests">
      <v-col v-for="(company, i) in requests" :key="i" cols="12" sm="6">
        <v-card class="mt-5" elevation="0" outlined>
          <v-card-text class="py-0 d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-avatar class="ma-3" size="60">
                <v-img :src="company.image"></v-img>
              </v-avatar>
              <div class="ms-3">
                <h2 class="black--text">{{ company.name }}</h2>
              </div>
            </div>
            <!--/ image & name & department  -->

            <div>
              <v-btn
                @click="accept_request(company.contact_id)"
                small
                depressed
                color="primary"
                class="me-2"
                rounded
                :loading="accept_loader"
                :disabled="accept_loader"
              >
                <v-icon>check</v-icon>
              </v-btn>
              <v-btn
                :loading="reject_loader"
                :disabled="reject_loader"
                rounded
                small
                depressed
                color="error"
                @click="rejct_request(company.contact_id)"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "requests",
  data() {
    return {
      empty: false,
      accept_loader: false,
      reject_loader: false,
      attrs: {
        class: "col-12 col-sm-6",
        boilerplate: true,
        elevation: 2,
      },
      data_contact: {
        contact_id: null,
      },
      put_config: {
        method: "put",
        url: "/company/contacts/accept",
      },
      get_config: {
        url: "/company/contacts/requests",
        method: "get",
      },
      delete_config: {
        method: "delete",
        url: "/company/contacts/reject",
      },
      requests: null,
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
  },
  methods: {
    get_requests() {
      this.$store
        .dispatch("public__request", {
          config: this.get_config,
        })
        .then((res) => {
          this.requests = res.data;
          res.data.length == 0 ? (this.empty = true) : (this.empty = false);
        });
    },
    accept_request(id) {
      this.accept_loader = true;
      this.$store
        .dispatch("public__request", {
          config: this.put_config,
          data: {
            contact_id: id,
          },
        })
        .then((res) => {
          this.accept_loader = false;
          this.get_requests();
          console.log(res);
        });
    },
    rejct_request(id) {
      this.reject_loader = true;
      this.$store
        .dispatch("public__request", {
          config: this.delete_config,
          data: {
            contact_id: id,
          },
        })
        .then((res) => {
          this.reject_loader = false;
          this.get_requests();
        });
    },
  },
  mounted() {
    this.get_requests();
  },
};
</script>
