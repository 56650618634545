<template>
  <div>
    <v-card
      v-for="(building, i) in buildings"
      :key="i"
      class="mb-5"
      elevation="0"
      outlined
    >
      <v-card-title class="d-flex justify-space-between">
        <span>
          {{ keys.project_page_details_building_title }} {{ i + 1 }}
        </span>

        <v-btn
          elevation="1"
          fab
          v-if="i != 0"
          small
          class="white--text"
          color="red"
          @click="
            $emit('delete_building', {
              building_index: i,
              building_id: building.id,
            })
          "
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-tabs class="mb-3" v-model="building.tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="building.tabs"
            v-for="(lang, la) in languages"
            :key="la"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="building.tabs">
          <v-tab-item v-for="(lang, la) in languages" :key="la">
            <v-row
              :dir="lang.direction"
              :class="[
                { 'text-left': lang.direction == 'ltr' },
                { 'text-right': lang.direction == 'rtl' },
              ]"
            >
              <v-col md="3" cols="6" v-if="building.tabs == 0">
                <v-text-field
                v-model="total_space[i]"
                  disabled
                  :label="keys.project_page_details_building_total_meter"
                ></v-text-field>
              </v-col>

              <v-col md="3" cols="6">
                <v-text-field
                  :value="building.description[lang.locale]"
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :label="keys.project_page_details_building_notes"
                  @keyup="
                    text_inputs(
                      $event.target.value,
                      i,
                      'description',
                      lang.locale
                    )
                  "
                ></v-text-field>
              </v-col>
              <!--/ col -->

              <v-col md="3" cols="6" v-if="building.tabs == 0">
                <v-text-field
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :value="building.meter_inside"
                  min="0"
                  type="number"
                  :label="keys.project_page_details_building_meter_inside"
                  @keyup="text_inputs($event.target.value, i, 'meter_inside')"
                ></v-text-field>
              </v-col>
              <!--/ col -->

              <v-col md="3" cols="6" v-if="building.tabs == 0">
                <v-text-field
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :value="building.meter_outside"
                  type="number"
                  :label="keys.project_page_details_building_meter_outside"
                  @keyup="text_inputs($event.target.value, i, 'meter_outside')"
                ></v-text-field>
              </v-col>
              <!--/ col -->

              <v-col md="3" cols="6" v-if="building.tabs == 0">
                <v-text-field
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :value="building.meter_stone_or_outside"
                  type="number"
                  :label="keys.project_page_details_building_meter_stone"
                  @keyup="
                    text_inputs(
                      $event.target.value,
                      i,
                      'meter_stone_or_outside'
                    )
                  "
                ></v-text-field>
              </v-col>
              <!--/ col -->

              <v-col v-if="building.tabs == 0" md="9" cols="12">
                <v-combobox
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :filter="filter"
                  @input="update_tags($event, i)"
                  :value="building.tags"
                  :hide-no-data="!search"
                  :items="building.tags"
                  :search-input.sync="search"
                  :label="keys.project_page_details_building_keywords"
                  multiple
                  small-chips
                  hide-selected
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <span class="subheading">اضافة </span>
                      <v-chip :color="`gray ighten-3`" label small>
                        {{ search }}
                      </v-chip>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ attrs, item, selected }">
                    <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      small
                    >
                      <span class="pr-2">
                        {{ item.text }}
                      </span>

                      <v-btn
                        small
                        icon
                        @click.stop.prevent="delete_tag(item, i, $event)"
                      >
                        <v-icon small>$delete</v-icon>
                      </v-btn>
                    </v-chip>
                  </template>

                  <template v-slot:item="{ index, item }">
                    <v-text-field
                      :class="[
                        { 'right-label': lang.direction == 'rtl' },
                        { 'left-label': lang.direction == 'ltr' },
                      ]"
                      v-if="editing === item"
                      v-model="editing.text"
                      autofocus
                      flat
                      background-color="transparent"
                      hide-details
                      solo
                      @keyup.enter="edit(index, item)"
                    ></v-text-field>
                    <v-chip
                      v-else
                      :color="`${item.color} lighten-3`"
                      dark
                      label
                      small
                    >
                      {{ item.text }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <!--/ col -->
            </v-row>
            <!--/ row -->
          </v-tab-item>
        </v-tabs-items>

        <v-row class="align-end" v-for="(floor, f) in building.floors" :key="f">
          <v-col cols="12" sm="3">
            <v-text-field
              type="number"
              min="0"
              :value="f + 1"
              disabled
              :label="keys.project_page_details_building_floor_no"
            ></v-text-field>
          </v-col>
          <!--/ col -->
          <v-col cols="12" sm="3">
            <v-text-field
              :value="floor.space"
              :prepend-icon="floor.space_err ? 'error_outline' : ''"
              :error="floor.space_err"
              type="number"
              min="0"
              @keyup="text_floors_inputs($event.target.value, i, f, 'space')"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                {{ keys.project_page_details_building_floor_space }}
              </template>
            </v-text-field>
          </v-col>
          <!--/ col -->

          <v-col cols="9" sm="3">
            <v-text-field
              type="number"
              min="0"
              :value="floor.roof_height"
              :label="keys.project_page_details_building_roof_hight"
              @keyup="
                text_floors_inputs($event.target.value, i, f, 'roof_height')
              "
            ></v-text-field>
          </v-col>
          <!--/ col -->

          <v-col cols="3" sm="3">
            <v-btn
              @click="
                $emit('delete_floor', {
                  building_index: i,
                  floor_index: f,
                  floor_id: floor.floor_id,
                })
              "
              v-if="f != 0"
              color="transparent"
              fab
              elevation="0"
            >
              <v-icon color="red">delete</v-icon>
            </v-btn>
          </v-col>
          <!--/ col -->
        </v-row>
        <!--/floors -->

        <v-btn @click="$emit('new_floor', i)" depressed color="primary">
          <v-icon color="white">add</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
    <!--/card -->

    <v-btn class="mb-2" depressed color="gray" @click="$emit('new_buidling')">{{
      keys.project_page_details_building_new_building
    }}</v-btn>
  </div>
</template>

<script>
export default {
  name: "buildings",
  props: ["buildings", "keys"],
  data() {
    return {
      colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],

      activator: null,
      attach: null,
      editing: null,
      editingIndex: -1,
      check_filter: false,
      items: [
        { text: "hassan", color: "red" },
        { text: "ahmed", color: "green" },
      ],
      nonce: 1,
      menu: false,
      model: [
        { text: "hassan", color: "red" },
        { text: "ahmed", color: "green" },
      ],
      x: 0,
      search: null,
      y: 0,
      search: null,
      builds: [
        {
          lines: [0],
        },
      ],
    };
  },
  computed: {
    languages() {
      return this.$store.getters.languages;
    },
    total_space() {
      let buidlings = this.buildings;
      let total = [];
      for (let i = 0; i < buidlings.length; i++) {
        total[i] = 0;
        for (let x = 0; x < buidlings[i].floors.length; x++) {
          total[i] += parseInt(buidlings[i].floors[x].space);
        }
      }
      return total;
    },
  },
  methods: {
    delete_tag(item, buidling_index, ev) {
      this.$emit("delete_tag", {
        item: item,
        buidling_index: buidling_index,
      });
    },
    filter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : "");
      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    //------------ text_inputs
    text_inputs(val, index, prop_name, locale = null) {
      this.$emit("text_inputs", {
        value: val,
        index: index,
        prop_name: prop_name,
        locale: locale,
      });
    },

    //------------ update tags
    update_tags(ev, index) {
      let tag = "";

      ev.forEach((el, i) => {
        if (typeof el == "string") {
          tag = el;
        }
      });

      this.$emit("update_tags", {
        value: tag,
        index: index,
      });
    },

    text_floors_inputs(val, b_index, f_index, prop_name) {
      this.$emit("text_floors_inputs", {
        value: val,
        b_index: b_index,
        f_index: f_index,
        prop_name: prop_name,
      });
    },
  },
};
</script>
