<template>
  <div>
    <v-container>
      <swiper class="swiper mb-5" :options="swiperOption">
        <swiper-slide v-for="(a, i) in new Array(8)" :key="i">
          <v-card>
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
              max-height="200"
              class="align-end"
            >
              <v-card-title class="white--text d-block owl-card-title">
                <div>
                  <h5>وصف الصورة</h5>
                  <p class="subtitle-2 font-weight-normal">5:12 . 2020</p>
                </div>
              </v-card-title>
              <!--/card title -->
            </v-img>
          </v-card>
        </swiper-slide>
        <!--swiper slide -->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <!--/swiper -->

      <v-card outlined elevation="0">
        <v-card-title>
          <v-text-field
            hide-details
            prepend-icon="mdi-magnify"
            single-line
            placeholder="بحث"
          ></v-text-field>
        </v-card-title>
        <!--/card title -->
        <v-card-title class="font-weight-bold"> البيم </v-card-title>
        <!--/card title -->
        <v-card-text>
          <v-row>
            <v-col md="3" cols="6" v-for="(v, i) in new Array(8)" :key="i">
              <v-card outlined elevation="0">
                <img
                  src="https://cdn.vuetifyjs.com/images/cards/house.jpg"
                  style="width: 100%"
                />
                <v-card-text class="pt-1 pb-20.">
                  <h4 class="text-h6">اسم الصورة</h4>
                  <p class="mb-0">47 صورة</p>
                </v-card-text>
                <!--/card text -->
              </v-card>
              <!--/card -->
            </v-col>
            <!--/col -->
          </v-row>
          <!--/ row -->
        </v-card-text>
        <!--/card text -->
      </v-card>
      <!--/card -->
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/main.scss";
::v-deep {
  .owl-card-overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      0deg,
      rgba(2, 0, 36, 0.885592) 0%,
      #020024 0%,
      rgba(2, 0, 36, 0.0844713) 72%
    );
    z-index: 1;
  }
  .owl-card-title {
    position: relative;
    background-image: linear-gradient(
      0deg,
      rgba(2, 0, 36, 0.885592) 0%,
      #020024 0%,
      rgba(2, 0, 36, 0.0844713) 72%
    );
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    background-color: #fff !important;
    @include radius(50%!important);
    @include shadow(menu);
    margin: 0 !important;
    overflow: hidden;
    top: calc(50% - 16px);
    color: #333;
    &::after {
      font-size: 22px;
    }
    @media (max-width: 767px) {
      display: none;
    }
    &.swiper-button-disabled {
      display: none;
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }

  .swiper-container-rtl {
    .swiper-button-next {
      right: auto;
      left: 0;
    }
    .swiper-button-prev {
      left: auto;
      right: 0;
    }
  }
}
</style>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "gallery",
  data() {
    return {
      dialog: false,
      swiperOption: {
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: "auto",
            spaceBetween: 20,
          },
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
