<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-title>{{ keys.project_file_page_files_title }}</v-card-title>

      <v-card-text v-for="(file, i) in files" :key="i">
        <v-tabs class="mb-3" v-model="file.tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="file.tabs"
            v-for="(lang, s) in  $store.getters.languages"
            :key="s"
          >
            {{ lang.native }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->
        <v-tabs-items v-model="file.tabs">
          <v-tab-item
            v-for="(lang, s) in  $store.getters.languages"
            :key="s"
          >
            <v-row
              :class="[
                { 'text-end': lang.direction == 'ltr' },
                { 'text-start': lang.direction == 'rtl' },
              ]"
              class="align-center"
            >
              <v-col v-if="file.tabs == 0" cols="12" class="text-end mt-3">
                <v-btn
                  @click="
                    $emit('delete_item', {
                      type: 'attachments',
                      item_index: i,
                      id: file.id,
                    })
                  "
                  fab
                  x-small
                  color="error"
                  depressed
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="6" sm="4">
                <v-text-field
                  :error="file.errors.title && file.tabs == 0"
                  :append-icon="
                    file.errors.title && file.tabs == 0 ? 'error_outline' : ''
                  "
                  @keyup="
                    $emit('files_component_inputs', {
                      value: $event.target.value,
                      locale: lang.locale,
                      prop: 'title',
                      index: i,
                    })
                  "
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                >
                  <template slot="label">
                    <strong v-if="file.tabs == 0" class="red--text">*</strong>
                    {{ keys.project_file_page_file_title }}
                  </template>
                </v-text-field>
              </v-col>
              <!--/ file title-->

              <v-col v-if="file.tabs == 0" cols="6" sm="4">
                <v-text-field
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  @click="
                    $emit('attach', {
                      comp: 'attachments',
                      index: i,
                      prop: 'files',
                    })
                  "
                  :value="`${file.files.length}${keys.project_file_page_files_count}`"
                >
                  <template slot="prepend">
                    <v-btn
                      x-small
                      fab
                      depressed
                      color="primary"
                      @click="
                        $emit('attach', {
                          comp: 'attachments',
                          index: i,
                          prop: 'files',
                        })
                      "
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <!--/ attachments count -->

              <v-col cols="6" sm="4">
                <v-text-field
                  @keyup="
                    $emit('files_component_inputs', {
                      value: $event.target.value,
                      locale: lang.locale,
                      prop: 'advisor_note',
                      index: i,
                    })
                  "
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :label="keys.project_file_page_file_consultant_description"
                ></v-text-field>
              </v-col>
              <!--/  advisor  description-->

              <v-col cols="6" sm="4">
                <v-text-field
                  @keyup="
                    $emit('files_component_inputs', {
                      value: $event.target.value,
                      locale: lang.locale,
                      prop: 'contractor_note',
                      index: i,
                    })
                  "
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  :label="keys.project_file_page_file_contractor_description"
                ></v-text-field>
              </v-col>
              <!--/ constructor  description -->

              <v-col cols="6" sm="4">
                <v-textarea
                  :class="[
                    { 'right-label': lang.direction == 'rtl' },
                    { 'left-label': lang.direction == 'ltr' },
                  ]"
                  @keyup="
                    $emit('files_component_inputs', {
                      value: $event.target.value,
                      locale: lang.locale,
                      prop: 'description',
                      index: i,
                    })
                  "
                  :label="keys.project_file_page_file_notes"
                  rows="1"
                ></v-textarea>
              </v-col>
              <!--/  notes -->
            </v-row>
            <!-- <v-divider v-if="i < 2"></v-divider> -->
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions class="d-block text-end pa-8">
        <v-btn
          @click="$emit('new_item', { type: 'attachments' })"
          color="primary"
          outlined
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["keys", "files"],
  name: "files",
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>
