<template>
  <div>
    <v-skeleton-loader
      v-if="!data_loaded"
      type="article,actions"
    ></v-skeleton-loader>

    <v-card v-if="data_loaded" elevation="0" outlined>
   
      <v-card-text>
        <v-row class="align-center">
          <v-col cols="3" class="font-weight-bold">
            {{ keys.times_page_employee_id }}</v-col
          >
          <v-col cols="3" class="font-weight-bold">{{
            keys.times_page_start_time
          }}</v-col>
          <v-col cols="3" class="font-weight-bold">{{
            keys.times_page_end_time
          }}</v-col>
          <v-col cols="2"></v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>

        <v-form ref="form">
          <v-row class="align-center" v-for="(t, i) in hours" :key="i">
            <v-col cols="3">
              <v-select
                :items="employees"
                item-value="id"
                v-model="t.employee_id"
              >
                <template slot="label">
                  <strong class="red--text">*</strong>
                  {{ keys.times_page_select_employee }}
                </template>
                <template v-slot:selection="{ item }">
                  <span>{{ item.name }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center">
                    <v-avatar size="45">
                      <img :src="item.image" alt="" />
                    </v-avatar>
                    <div class="ms-3">
                      <h4 style="font-size: 14px">
                        {{ item.name }}
                      </h4>
                      <div class="d-flex align-center justify-space-between">
                        <p style="font-size: 12px">
                          {{ item.phone }}
                        </p>
                        <span>|</span>
                        <p style="font-size: 12px">
                          {{ item.id_number }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </v-select>
            </v-col>
            <!--col (employee id )-->
            <v-col cols="3">
              <v-text-field
                @input="
                  check_time({ value: $event, prop: 'start_time', ind: i })
                "
                :disabled="t.full_day"
                :rules="t.full_day ? [] : rules.time"
                v-model="t.start_time"
                :label="keys.times_page_start_time"
              ></v-text-field>
            </v-col>
            <!--col (start time )-->
            <v-col cols="3">
              <v-text-field
                @input="check_time({ value: $event, prop: 'end_time', ind: i })"
                :disabled="t.full_day"
                v-model="t.end_time"
                :label="keys.times_page_end_time"
              ></v-text-field>
            </v-col>
            <!--col (end time )-->
            <v-col cols="2">
              <v-checkbox
                @change="toggle_times(i, t.full_day)"
                v-model="t.full_day"
                :label="keys.times_page_full_day"
              ></v-checkbox>
            </v-col>
            <!--col (full day) -->
            <v-col cols="1">
              <v-btn @click="delete_row(t.id, i)" icon color="error">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
            <!--col (delete)-->
          </v-row>
          <!-- row -->
        </v-form>
        <v-btn class="mt-10" @click="new_row()" color="primary" outlined>
          <v-icon>add</v-icon>
        </v-btn>
        <!-- add new row -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Times",
  props: ["hours", "keys", "data_loaded"],
  data() {
    return {
      employees: [],
      rules: {
        time: [
          (v) =>
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) ||
            'required'
        ],
      },
    };
  },
  methods: {
    get_employees() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/project/times/employees",
            method: "get",
          },
        })
        .then((res) => {
          if (!this.$route.query.time_id) {
            res.data.forEach((e, i) => {
              this.new_row(e.id);
            });
          }

          this.employees = Object.assign([], res.data);
        });
    },

    new_row(id) {
      const time = {
        employee_id: id ? id : "",
        start_time: "",
        end_time: "",
        full_day: false,
      };
      let time_index = this.hours.length; // get new index for row
      this.$set(this.hours, time_index, time); // set row
    },
    // toggle start time & end time disabled & null values
    toggle_times(index, full_day) {
      if (full_day) {
        this.$set(this.hours[index], "start_time", null);
        this.$set(this.hours[index], "end_time", null);
      }
    },
    check_time(ev) {
      if (ev.value != null && ev.value.length == 2) {
        this.hours[ev.ind][ev.prop] = this.hours[ev.ind][ev.prop] + ":";
      }
    },

    delete_row(id, ind) {
      if (id) {
        this.$store
          .dispatch("public__request", {
            config: {
              method: "delete",
              url: `company/project/times/hour/${id}`,
            },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err.response);
          });
      }

      this.hours.splice(ind, 1);
    },
  },
  created() {
    this.get_employees();
  },
};
</script>
