<template>
  <div>
    <v-navigation-drawer
      color="secondary"
      class="app-menu"
      v-model="$store.state.drawer"
      app
      :right="rtl"
    >
      <v-list
        nav
        dense
        class="pt-10 list ps-10 pe-10 d-done"
        v-if="$store.getters.user_role == 'admin'"
      >
        <!-- employees-->
        <v-list-item class="white--text" to="/employees" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text material-icons-outlined">people</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> الموظفين </v-list-item-title>
        </v-list-item>
        <!--/ employees-->

        <v-list-item class="white--text" to="/work_times" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">cases</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">  ساعات العمل</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/payments" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">payment</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">المدفوعات</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/external_project" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">payment</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">المشاريع الخارجية</v-list-item-title>
        </v-list-item>
        
        <v-list-item class="white--text" to="/companies" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">الشركات</v-list-item-title>
        </v-list-item>


        <v-list-item class="white--text" to="/branches" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">الفروع</v-list-item-title>
        </v-list-item>



        <v-list-item class="white--text" to="/services_data" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">الطلبات المرسلة</v-list-item-title>
        </v-list-item>


        <v-list-item class="white--text" to="/projects" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text material-icons-outlined">cases</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> المشاريع</v-list-item-title>
        </v-list-item>


        <v-list-item class="white--text" to="/checkes" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text material-icons-outlined">cases</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> الفحوصات</v-list-item-title>
        </v-list-item>


        <v-list-item class="white--text" to="/projects_files" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text material-icons-outlined">cases</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> ملفات المشاريع </v-list-item-title>
        </v-list-item>




        <v-list-item class="white--text" to="/chat" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text material-icons-outlined">cases</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> المحادثات</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/companies_requests" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">طلبات الشركات</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list
        nav
        dense
        class="pt-10 list ps-10 pe-10 d-done"
        v-else-if="$store.getters.user_role == 'employee'"
      >
        <v-list-item
          class="white--text"
          :to="{
            name: 'employee_profile_page',
            query: { employee: $store.getters.user_info.employee.id },
          }"
          link
        >
          <v-list-item-icon color="white">
            <v-icon class="white--text">person</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">התיק שלי</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/employee_requests" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">בקשות חברות</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list  nav dense class="pt-10 list ps-10 pe-10 d-block">
        <v-list-item class="white--text" to="/gantt" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">gantt</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/Home" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">home</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Home</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/Live" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">live_tv</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Live</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/invoices" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">receipt_long</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Invoices</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/add-invoice" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">border_color</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Add invoice</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/new-Supplier" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">person_add</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">new supplier</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/project-budget" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">point_of_sale</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">project budget</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/Supplier" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">person_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> supplier</v-list-item-title>
        </v-list-item>
        <v-list-item class="white--text" to="/tasks" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">format_list_numbered</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> tasks</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/set-prices" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">price_check</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> Set prices</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/gallery" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">image</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> Gallery</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/alboum" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">collections</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> Alboum</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/activity" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">toggle_on</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> activity</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/tel-book" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">contact_phone</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> Tel book</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/taskes-progress" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">task_alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Tasks Progress </v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/charts" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">signal_cellular_alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">charts</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/staffDays" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">groups</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">staff Days</v-list-item-title>
        </v-list-item>
        <v-list-item class="white--text" to="/projects" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">work_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">projects</v-list-item-title>
        </v-list-item>

        <v-list-item class="white--text" to="/messages" link>
          <v-list-item-icon color="white">
            <v-icon class="white--text">move_to_inbox</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">Messages</v-list-item-title>
        </v-list-item>
      </v-list>

      <!--/ company -->
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .app-menu {
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>
<script>
export default {
  name: "AsideMenu",
  data() {
    return {};
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
  },
};
</script>

<style></style>
