var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.date_done)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.date_done)?_c('v-card',{staticClass:"text-center",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',{staticClass:"d-flex"},[_c('span',{staticClass:"order-1"},[_vm._v(_vm._s(_vm.keys.checkes_page_table_page_name)+" ")]),_c('v-spacer',{staticClass:"order-2"}),_c('v-text-field',{staticClass:"order-4 order-sm-3",attrs:{"append-icon":"mdi-magnify","label":_vm.keys.checkes_page_table_search,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"order-4 d-none d-sm-block"}),_c('v-btn',{staticClass:"order-3 order-sm-5",attrs:{"depressed":"","color":"primary","to":{ name: 'new_check_page' }}},[_vm._v(" "+_vm._s(_vm.keys.checkes_page_table_add_btn))])],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"id","sort-by":"calories","no-data-text":"لا يوجد نتائج ","no-results-text":"لا يوجد نتائج بحث","footer-props":_vm.$store.state.dataTableFooterProps},scopedSlots:_vm._u([{key:"item.engPro",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"me-1",attrs:{"rounded":"","elevation":"0","small":""}},[_vm._v(" "+_vm._s(item.engPro[0])+" ")]),(item.engPro.length > 1)?_c('v-chip',{staticClass:"px-1",attrs:{"color":"success","x-small":""}},[_c('v-icon',{staticClass:"material-icon",attrs:{"x-small":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(item.engPro.length - 1)+" ")],1):_vm._e()]}},{key:"item.superPro",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"me-1",attrs:{"rounded":"","elevation":"0","small":""}},[_vm._v(" "+_vm._s(item.superPro[0])+" ")]),(item.superPro.length > 1)?_c('v-chip',{staticClass:"px-1",attrs:{"color":"success","x-small":""}},[_c('v-icon',{staticClass:"material-icon",attrs:{"x-small":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(item.superPro.length - 1)+" ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'new_check_page',
              query: { check: item.id },
            })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"fab":"","small":"","icon":""},on:{"click":function($event){(_vm.delete_dialog = true), (_vm.delete_id = item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center d-block"},[_vm._v(_vm._s(_vm.keys.checkes_page_delete_title)+" ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"loading":_vm.delete_loader,"disabled":_vm.delete_loader,"depressed":"","color":"error"},on:{"click":function($event){return _vm.delete_project()}}},[_vm._v(_vm._s(_vm.keys.checkes_page_delete_button))]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.keys.checkes_page_close_button))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }