<template>
  <v-container>
    <BasicInfo
      :keys="keys"
      ref="basic"
      :data_loaded="data_loaded"
      :project_times="project_times"
    ></BasicInfo>
    <Times
      :keys="keys"
      :data_loaded="data_loaded"
      ref="times"
      :hours="project_times.hours"
    ></Times>
    <div class="text-end mt-5">
      <v-btn :loading="save_loader" @click="save()" color="primary" depressed
        >حفظ</v-btn
      >
    </div>
  </v-container>
  <!--/ container -->
</template>

<script>
import BasicInfo from "@/components/employees/times/basic_info";
import Times from "@/components/employees/times/times";
export default {
  name: "times",
  data() {
    return {
      data_loaded: true,
      save_loader: false,
      employees: [],
      menu: false,
      project_times: {
        id: null,
        type: "extrenal",
        project_id: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        title: {},
        notes: {},
        hours: [],
      },
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
  },
  components: {
    Times,
    BasicInfo,
  },
  methods: {
    save() {
      if (
        this.$refs.times.$refs.form.validate() &&
        this.$refs.basic.$refs.form.validate()
      ) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "company/project/times",
              method: "post",
            },
            data: this.project_times,
          })
          .then((res) => {
            this.$router.push("/work_times");
            this.save_loader = false;
          })
          .catch((err) => {
            console.log(err.response);
            this.save_loader = false;
          });
      }
    },
  },
  created() {
    if (this.$route.query.time_id) {
      this.data_loaded = false;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/project/times/${this.$route.query.time_id}`,
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.project_times = Object.assign({}, res.data);
        });
    }
  },
};
</script>
