var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.keys.project_file_page_files_title))]),_vm._l((_vm.files),function(file,i){return _c('v-card-text',{key:i},[_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"gray"},model:{value:(file.tabs),callback:function ($$v) {_vm.$set(file, "tabs", $$v)},expression:"file.tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.$store.getters.languages),function(lang,s){return _c('v-tab',{key:s,model:{value:(file.tabs),callback:function ($$v) {_vm.$set(file, "tabs", $$v)},expression:"file.tabs"}},[_vm._v(" "+_vm._s(lang.native)+" ")])})],2),_c('v-tabs-items',{model:{value:(file.tabs),callback:function ($$v) {_vm.$set(file, "tabs", $$v)},expression:"file.tabs"}},_vm._l((_vm.$store.getters.languages),function(lang,s){return _c('v-tab-item',{key:s},[_c('v-row',{staticClass:"align-center",class:[
              { 'text-end': lang.direction == 'ltr' },
              { 'text-start': lang.direction == 'rtl' } ]},[(file.tabs == 0)?_c('v-col',{staticClass:"text-end mt-3",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"error","depressed":""},on:{"click":function($event){return _vm.$emit('delete_item', {
                    type: 'attachments',
                    item_index: i,
                    id: file.id,
                  })}}},[_c('v-icon',[_vm._v("delete")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"error":file.errors.title && file.tabs == 0,"append-icon":file.errors.title && file.tabs == 0 ? 'error_outline' : ''},on:{"keyup":function($event){return _vm.$emit('files_component_inputs', {
                    value: $event.target.value,
                    locale: lang.locale,
                    prop: 'title',
                    index: i,
                  })}}},[_c('template',{slot:"label"},[(file.tabs == 0)?_c('strong',{staticClass:"red--text"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.keys.project_file_page_file_title)+" ")])],2)],1),(file.tabs == 0)?_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"value":("" + (file.files.length) + (_vm.keys.project_file_page_files_count))},on:{"click":function($event){return _vm.$emit('attach', {
                    comp: 'attachments',
                    index: i,
                    prop: 'files',
                  })}}},[_c('template',{slot:"prepend"},[_c('v-btn',{attrs:{"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('attach', {
                        comp: 'attachments',
                        index: i,
                        prop: 'files',
                      })}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"label":_vm.keys.project_file_page_file_consultant_description},on:{"keyup":function($event){return _vm.$emit('files_component_inputs', {
                    value: $event.target.value,
                    locale: lang.locale,
                    prop: 'advisor_note',
                    index: i,
                  })}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"label":_vm.keys.project_file_page_file_contractor_description},on:{"keyup":function($event){return _vm.$emit('files_component_inputs', {
                    value: $event.target.value,
                    locale: lang.locale,
                    prop: 'contractor_note',
                    index: i,
                  })}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-textarea',{class:[
                  { 'right-label': lang.direction == 'rtl' },
                  { 'left-label': lang.direction == 'ltr' } ],attrs:{"label":_vm.keys.project_file_page_file_notes,"rows":"1"},on:{"keyup":function($event){return _vm.$emit('files_component_inputs', {
                    value: $event.target.value,
                    locale: lang.locale,
                    prop: 'description',
                    index: i,
                  })}}})],1)],1)],1)}),1)],1)}),_c('v-card-actions',{staticClass:"d-block text-end pa-8"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('new_item', { type: 'attachments' })}}},[_c('v-icon',[_vm._v("add")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }