<template>
  <div>
    <v-skeleton-loader type="table" v-if="!data_loaded"> </v-skeleton-loader>
    <v-data-table v-if="data_loaded" :items="tbody" :headers="headers">
      <template v-slot:[`item.invoice`]="{ item }">
        <v-chip
          color="primary"
          x-small
          class="px-2 white--text"
          v-if="item.invoice_path && item.type != 1"
        >
          {{ keys.payments_page_invoice_status_exist }}
        </v-chip>
        <v-chip
          color="red lighten-2"
          x-small
          class="px-2 white--text"
          v-else-if="!item.invoice_path && item.type != 1"
        >
          {{ keys.payments_page_invoice__status_not_exist }}
        </v-chip>
        <span


          v-else-if="item.type == 1"
        >
          ___
        </span>
      </template>
      <!--/ template  invoice --> 

      <template v-slot:[`item.created_at`]="{ item }">
        {{
          item.created_at.slice(
            0,
            item.created_at.lastIndexOf("T")
          )
        }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-chip
          color="blue"
          x-small
          class="px-2 white--text"
          v-if="item.type == 1"
        >
          {{ keys.payments_page_type_employee }}
        </v-chip>
        <v-chip
          color="primary"
          x-small
          class="px-2 white--text"
          v-else-if="item.type == 2"
        >
          {{ keys.payments_page_type_company }}
        </v-chip>
        <v-chip color="gray" x-small class="px-2" v-else>
          {{ keys.payments_page_type_public }}
        </v-chip>
      </template>
      <!--/ template (type ) -->

      <template v-slot:[`item.reciver`]="{ item }">
        <v-chip
          :color="item.type == 1 ? 'blue' : 'primary'"
          x-small
          class="px-2 white--text"
          v-if="item.type != 3"
        >
          {{ item.reciver.name }}
        </v-chip>
        <span v-else>___</span>
      </template>
      <!--/ template (reciver name  ) -->

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="$emit('edit_payment', JSON.parse(JSON.stringify(item)))"
          icon
          small
        >
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn @click="get_delete_id(item.id)" icon small>
          <v-icon small>delete</v-icon>
        </v-btn>
      </template>
      <!--/ template (actions ) -->
    </v-data-table>

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block">
          {{ keys.payments_page_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_payment()"
            depressed
            color="error"
            small
          >
            {{ keys.payments_page_delete_save }}
          </v-btn>
          <v-btn small @click="delete_dialog = false" depressed>
            {{ keys.payments_page_delete_close }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </div>
</template>

<script>
export default {
  name: "Table",
  props: ["keys"],
  data() {
    return {
      delete_id: false,
      delete_dialog: false,
      delete_loader: false,
      data_loaded: false,
      tbody: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          align: "center",
          text: this.keys.payments_page_dagte,
          value: "created_at",
        },

        {
          align: "center",
          text: this.keys.payments_page_type,
          value: "type",
        },
        {
          align: "center",
          text: this.keys.payments_page_receiver,
          value: "reciver",
        },
        {
          align: "center",
          text: this.keys.payments_page_payment_method,
          value: "payment_method",
        },
        {
          align: "center",
          text: this.keys.payments_page_amount,
          value: "amount",
        },
        {
          align: "center",
          text: this.keys.payments_page_invoice,
          value: "invoice",
        },
        {
          align: "center",
          text: this.keys.payments_page_actions,
          value: "actions",
        },
      ];
    },
  },
  methods: {
    get_payments() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/payments",
            method: "get",
          },
        })
        .then((res) => {
          this.tbody = Object.assign([], res.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => console.log(err.response));
    },

    get_delete_id(id) {
      this.delete_dialog = true;
      this.delete_id = id;
    },

    //delete payment
    delete_payment() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/payments/${this.delete_id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.delete_dialog = false;
          this.delete_loader = false;
          this.get_payments();
        });
    },
  },
  created() {
    this.get_payments();
  },
};
</script>
