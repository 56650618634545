<template>
  <v-container>
    <h4 class="mb-3">{{ keys.project_file_page_name }}</h4>
    <pre>
      {{ file }}
    </pre>
    <basicInfo
      :keys="keys"
      :project_type="project_type"
      :project_type_value="file.project_type"
      :project_selected="file.project_id"
      :contacts_ids="file.companies_ids"
      :project_name="file.project_name"
      :errors="file.errors"
      @set_proejct_name="set_proejct_name($event)"
      @selects="selects($event)"
      :tabs="file.tabs"
      @cheng_tabs="change_tabs($event)"
    ></basicInfo>
    <!--/ basic Info -->

    <products
      :products="file.products"
      :keys="keys"
      @products_inputs="products_component_inputs($event)"
      @attach="open_attach_dialog($event)"
      @new_item="new_item($event)"
      @delete_item="delete_item($event)"
    ></products>
    <!--/ products -->

    <files
      @files_component_inputs="files_component_inputs($event)"
      @attach="open_attach_dialog($event)"
      :files="file.attachments"
      :keys="keys"
      @new_item="new_item($event)"
      @delete_item="delete_item($event)"
    ></files>
    <!--/ files -->
    <v-btn @click="save" color="primary" class="mb-10">حفظ</v-btn>

    <attachments
      :attachments="attachments_component"
      @close_attach_dialog="
        (attach_dialog = false), (attachments_component = [])
      "
      :attach_dialog="attach_dialog"
      :keys="keys"
      :comp="comp_name"
      @set_uploaded="set_uploaded($event)"
    ></attachments>

    <v-dialog max-width="500" v-model="dialog_company">
      <v-card>
        <v-card-title>
          {{ keys.project_file_page_dialog_company_title }}
        </v-card-title>
        <!--/ card title -->
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-tabs class="mb-3" v-model="tabs" background-color="gray">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              v-model="tabs"
              v-for="(lang, i) in $store.getters.languages"
              :key="i"
            >
              {{ lang.native }}
            </v-tab>
          </v-tabs>
          <!--/tabs -->

          <v-form ref="form">
            <v-tabs-items v-model="tabs">
              <v-tab-item
                v-for="(lang, i) in $store.getters.languages"
                :key="i"
              >
                <v-text-field>
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.project_file_page_dialog_company_name }}
                  </template>
                </v-text-field>
                <!--/ company name -->
                <v-text-field v-if="tabs == 0">
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    {{ keys.project_file_page_dialog_company_phone }}
                  </template>
                </v-text-field>
                <!--/ company phone number -->

                <v-text-field
                  :label="keys.project_file_page_dialog_company_address"
                >
                </v-text-field>
                <!--/ company address -->
              </v-tab-item>
            </v-tabs-items>
          </v-form>
          <!--/ v- form -->
        </v-card-text>
        <!--/ card text -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="dialog = false" small text color="red">{{
            keys.project_file_page_dialog_close_btn
          }}</v-btn>
          <v-btn small color="primary">{{
            keys.project_file_page_dialog_add_btn
          }}</v-btn>
        </v-card-actions>
        <!--/card actions -->
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import basicInfo from "@/components/project_file/basic_info";
import products from "@/components/project_file/products";
import files from "@/components/project_file/files";
import attachments from "@/components/project_file/attachments.vue";
export default {
  name: "project_file",
  data() {
    return {
      tabs: 0,
      dialog_company: false,
      attach_dialog: false,
      comp_name: "",
      attachments_component: [],
      open_dialog_index: -1,
      file: {
        tabs: 0,
        project_id: null,
        project_type: 0,
        companies_ids: [],
        project_name: {},
        errors: {
          project_id: false,
          project_name: false,
          project_type: false,
          companies_ids: false,
        },
        products: [
          {
            tabs: 0,
            product_company_id: null,
            name: {},
            images: [],
            date: null,
            barcode: null,
            description: {},
            errors: {
              company: false,
              product_name: false,
              date: false,
              barcode: false,
              description: false,
            },
          },
        ],
        attachments: [
          {
            tabs: 0,
            title: {},
            files: [],
            contractor_note: {},
            advisor_note: {},
            description: {},
            errors: {
              title: false,
            },
          },
        ],
        files: [],
      },
      popup_files: [],
    };
  },
  components: {
    basicInfo,
    products,
    files,
    attachments,
  },
  computed: {
    project_type() {
      return [
        {
          text: this.keys.project_file_page_name_project_type_external,
          value: 0,
        },
        {
          text: this.keys.project_file_page_name_project_type_current,
          value: 1,
        },
      ];
    },
    // translations
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    // all languages
    languages() {
      return this.$store.getters.languages;
    },
    // get default lang for check if the input text like (title - description) with default languages values
    default_lang() {
      return this.languages.find((l) => l.is_default);
    },
  },
  methods: {
    //basic info  Component selects
    selects(ev) {
      if (ev.type != "companies_id") {
        this.$set(this.file, ev.type, ev.value); //  if type is project_id or project_type
      } else {
        this.$set(this.file, "companies_ids", []);
        ev.value.forEach((e, i) => {
          this.$set(this.file.companies_ids, i, e); // set companies_id
        });
      }
    },
    //change basic information tabs
    change_tabs(ev) {
      this.file.tabs = ev;
    },
    set_proejct_name(ev) {
      this.$set(this.file.project_name, ev.locale, ev.value);
    },
    // products  Component inputs
    products_component_inputs(ev) {
      if (ev.locale) {
        this.$set(this.file.products[ev.index][ev.type], ev.locale, ev.value);
      } else {
        this.$set(this.file.products[ev.index], ev.type, ev.value);
      }
    },
    // files    // files component inputs
    files_component_inputs(ev) {
      this.$set(this.file.attachments[ev.index][ev.prop], ev.locale, ev.value);
    },

    // open attachments dialog
    open_attach_dialog(ev) {
      this.attach_dialog = true; // open attachment dialog
      this.comp_name = ev.comp;
      this.open_dialog_index = ev.index; // set index of object for  useing to set new objects
      this.file[ev.comp][ev.index][ev.prop].forEach((e, i) => {
        // set files attachments_component to send  to attachments component
        this.$set(this.attachments_component, i, e);
      });
      // console.log(this.attachments_component);
    },

    // push uploaded files to (files.product.images or file.attachments.files)
    set_uploaded(ev) {
      if (ev.comp == "products") {
        let img_index =
          this.file.products[this.open_dialog_index].images.length; //get new  image index
        this.$set(
          this.file.products[this.open_dialog_index].images, // set the new object for images
          img_index,
          { path: ev.path }
        );
        this.open_attach_dialog({
          // call function to  make images realtime  inside attachments component
          comp: "products",
          index: this.open_dialog_index,
          prop: "images",
        });
      } else {
        let file_index =
          this.file.attachments[this.open_dialog_index].files.length; // get new file index
        this.$set(
          this.file.attachments[this.open_dialog_index].files, // set the new object for files
          file_index,
          { path: ev.path }
        );
        // call function to  make files realtime  inside attachments component
        this.open_attach_dialog({
          comp: "attachments",
          index: this.open_dialog_index,
          prop: "files",
        });
      }
    },
    // set new item for products or files
    new_item(ev) {
      let item_index = this.file[ev.type].length;
      let product = {
        tabs: 0,
        product_company_id: null,
        name: {},
        images: [],
        date: null,
        barcode: null,
        description: {},
        errors: {
          company: false,
          product_name: false,
          date: false,
          barcode: false,
          description: false,
        },
      };
      let attach = {
        tabs: 0,
        title: {},
        files: [],
        contractor_note: {},
        advisor_note: {},
        description: {},
        errors: {
          title: false,
        },
      };

      if (ev.type == "products") {
        this.$set(this.file[ev.type], item_index, product);
      } else {
        this.$set(this.file[ev.type], item_index, attach);
      }
    },

    //delete items
    delete_item(ev) {
      this.file[ev.type].splice(ev.item_index, 1);
      if (ev.id) {
        if (ev.type == "products") {
          console.log("products");
        } else {
          console.log("attachments");
        }
      } else {
        console.log("undefined");
      }
    },
    //validation
    validation() {
      let errors = [];
      // if project type empty
      if (this.file.project_type == null) {
        errors.push(0);
        this.$set(this.file.errors, "project_type", true);
      } else {
        this.$set(this.file.errors, "project_type", false);
      }

      if (this.file.project_type == 1) {
        // if project id empty
        if (!this.file.project_id) {
          errors.push(0);
          this.$set(this.file.errors, "project_id", true);
        } else {
          this.$set(this.file.errors, "project_id", false);
        }
      } else {
        if (!this.file.project_name[this.default_lang.locale]) {
          console.log(this.file.project_name)
          errors.push(0);
          this.$set(this.file.errors, "project_name", true);
        } else {
  
          this.$set(this.file.errors, "project_name", false);
        }
      }

      // if project type empty
      if (this.file.companies_ids.length == 0) {
        errors.push(0);
        this.$set(this.file.errors, "companies_ids", true);
      } else {
        this.$set(this.file.errors, "companies_ids", false);
      }

      /*
        1- check required inputs text if has the default languegs values
        2-check if date or barcode empty
      */

      this.file.products.forEach((e, i) => {
        // products component
        if (!e.product_company_id) {
          errors.push(i);
          this.$set(this.file.products[i].errors, "company", true);
        } else {
          this.$set(this.file.products[i].errors, "company", false);
        }

        if (!this.file.products[i].name[this.default_lang.locale]) {
          errors.push(i);
          this.$set(this.file.products[i].errors, "product_name", true);
        } else {
          this.$set(this.file.products[i].errors, "product_name", false);
        }

        if (!e.date) {
          errors.push(i);
          this.$set(this.file.products[i].errors, "date", true);
        } else {
          this.$set(this.file.products[i].errors, "date", false);
        }

        if (!e.barcode) {
          errors.push(i);
          this.$set(this.file.products[i].errors, "barcode", true);
        } else {
          this.$set(this.file.products[i].errors, "barcode", false);
        }

        if (!e.description[this.default_lang.locale]) {
          errors.push(i);
          this.$set(this.file.products[i].errors, "description", true);
        } else {
          this.$set(this.file.products[i].errors, "description", false);
        }
      });

      this.file.attachments.forEach((e, i) => {
        if (!e.title[this.default_lang.locale]) {
          errors.push(i);
          this.$set(this.file.attachments[i].errors, "title", true);
        } else {
          this.$set(this.file.attachments[i].errors, "title", false);
        }
      });

      // checking if there are no any errors to execute save function
      if (errors.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    //save file
    save() {
      console.log(this.validation());
      if (this.validation()) {
        this.$store
          .dispatch("public__request", {
            config: {
              method: "post",
              url: "company/projectFile",
            },
            data: this.file,
          })
          .then((res) => {
            console.log(res);
          }).catch(err => {
            console.log(err.response)
          })
      }
    },
  },
  mounted() {},
};
</script>
