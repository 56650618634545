<template>
  <v-dialog
    transition="dialog-bottom-transition"
    hide-overlay
    fullscreen
    v-model="dialog"
    @keydown="close_upload_dialog"
  >
    <v-card class="rounded-0">
      <v-card-title class="font-weight-bold">
        {{
          comp == "products"
            ? keys.project_file_page_add_images_title
            : keys.project_file_page_add_file_title
        }}

        <v-spacer></v-spacer>
        <v-btn
          :disabled="progress != 0"
          @click="close_upload_dialog"
          icon
          x-large
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-file-input
          hide-details
          v-model="input_file"
          @change="upload_file()"
          :loading="progress > 0"
          :label="
            comp == 'products'
              ? keys.project_file_page_add_images_input
              : keys.project_file_page_add_file_input
          "
          ref="upload_input"
        ></v-file-input>

        <v-progress-linear
          class="mt-2"
          striped
          color="light-blue"
          v-model="progress"
          :active="progress != 0 && progress != 100"
          v-if="progress != 0 && progress != 100"
        ></v-progress-linear>

        <v-row class="mt-5">
          <v-col
            v-for="(file, ind) in attachments"
            :key="ind"
            cols="6"
            sm="2"
            class="position-relative text-center"
          >
            <v-card outlined>
              <v-card-text>
                <img
                  v-if="files_types[ind] == 'pdf'"
                  src="@/assets/files_icon/pdf-file.png"
                  width="100"
                />
                <img
                  v-else-if="
                    files_types[ind] == 'docx' || files_types[ind] == 'doc'
                  "
                  width="100"
                  src="@/assets/files_icon/doc.png"
                />
                <img
                  v-else-if="
                    files_types[ind] == 'png' ||
                    files_types[ind] == 'jpg' ||
                    files_types[ind] == 'jpeg' ||
                    files_types[ind] == 'jfif' ||
                    files_types[ind] == 'WebP'
                  "
                  width="100"
                  src="@/assets/files_icon/image.png"
                />
                <img v-else width="100" src="@/assets/files_icon/file.png" />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  color="rgba(0, 0, 0, 0.54)"
                  download
                  :href="file.path"
                  icon
                >
                  <v-icon>file_download</v-icon>
                </v-btn>
                <v-btn
                  :href="file.path"
                  icon
                  v-if="
                    files_types[ind] == 'png' ||
                    files_types[ind] == 'jpg' ||
                    files_types[ind] == 'jpeg' ||
                    files_types[ind] == 'jfif' ||
                    files_types[ind] == 'WebP' ||
                    files_types[ind] == 'pdf'
                  "
                >
                  <v-icon>visibility</v-icon>
                </v-btn>
                <v-btn @click="delete_file(file.file_id, ind)" icon>
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["keys", "attach_dialog", "attachments", "comp"],
  name: "attachments",
  data() {
    return {
      dialogFiles: false,
      progress: 0,
      input_file: null,
      types: "",
      attach_index: null,
      files: [],
    };
  },
  computed: {
    files_types() {
      let files = this.attachments;
      let types = [];
      files.forEach((e, i) => {
        types.push(e.path.slice(e.path.lastIndexOf(".") + 1));
      });
      return types;
    },
    dialog() {
      return this.attach_dialog ? true : false;
    },
  },
  methods: {
    upload_file() {
      const formData = new FormData();
      const vm = this;
      formData.append("file", vm.input_file);
      formData.append("path", "company");
      if (vm.input_file) {
        if (this.source) {
          this.source.cancel();
        }
        this.CancelToken = this.axios.CancelToken;
        this.source = this.CancelToken.source();
        var config = {
          method: "post",
          url: "https://app.kalko.cloud/api/file/upload",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.state.token}`,
          },
          cancelToken: this.source.token,
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            vm.progress = percentCompleted;
            if (vm.progress == 100) {
              vm.progress = 0;
            }
          },
          data: formData,
        };

        this.axios(config)
          .then((res) => {
            this.$emit("set_uploaded", {
              comp: this.comp,
              path: res.data.path,
            });
          })
          .catch((thrown) => {
            if (this.axios.isCancel(thrown)) {
              vm.progress = 0;
            }
          });
      } else {
        this.source.cancel();
        vm.progress = 0;
      }
    },
    close_upload_dialog() {
      if (this.progress == 0) {
        this.$emit("close_attach_dialog");
        this.input_file = null;
        this.files = [];
      }
    },
  },
};
</script>
