<template>
  <v-container>
    <v-alert
      v-if="!$store.getters.company_id"
      dense
      class="text-center"
      type="warning"
      >لايوجد اي شركات مضافة</v-alert
    >
    <ProfileFilter
      @date_changed="date_changed($event)"
      @employee_changed="employee_changed($event)"
      @get_profile="get_profile()"
      :keys="keys"
      :data_loaded="data_loaded"
      v-if="$store.getters.company_id"
      
    ></ProfileFilter>
    <!--/ profileFilter -->

    <Totals
      v-if="$store.getters.company_id"
      :data_loaded="data_loaded"
      :totals="totals"
      :wallet="profile.wallet ? this.profile.wallet : 0"
      :conditions="conditions"
      :keys="keys"
    ></Totals>
    <!--/ totals -->

    <DaysTable
      :tbody="days_table"
      :keys="keys"
      v-if="$store.getters.company_id"
      :data_loaded="data_loaded"
    ></DaysTable>

    <HoursTable
      :data_loaded="data_loaded"
      :tbody="hours_table"
      :keys="keys"
      v-if="$store.getters.company_id"
    ></HoursTable>
    <!-- / HoursTable -->

    <PaymentTable
      :data_loaded="data_loaded"
      :keys="keys"
      :tbody="payments_table"
    ></PaymentTable>
    <!--/ payment table -->
  </v-container>
</template>

<script>
import HoursTable from "@/components/employees/profile/hours_table";
import Totals from "@/components/employees/profile/totals";
import ProfileFilter from "@/components/employees/profile/filter";
import PaymentTable from "@/components/employees/profile/payment_table";
import DaysTable from "@/components/employees/profile/days_table";

export default {
  name: "Employee_profile",
  data() {
    return {
      data_loaded: false,
      search: "",
      employee_id: this.$route.query.employee,
      company_id: this.$store.getters.company_id,
      filter_date: "",
      profile: {},
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    conditions() {
      return this.profile ? this.profile.conditions : [];
    },
    totals() {
      return this.profile ? this.profile.totals : [];
    },
    days_table() {
      return this.profile.days || [];
    },
    hours_table() {
      return this.profile.hours || [];
    },
    payments_table() {
      return this.profile.payments || [];
    },
  },
  components: {
    HoursTable,
    Totals,
    ProfileFilter,
    PaymentTable,
    DaysTable,
  },
  methods: {
    get_profile() {
      this.data_loaded = false;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/profile/statistic?employee_id=${this.employee_id}&company_id=${this.company_id}&date=${this.filter_date}`,
            method: "get",
          },
        })
        .then((res) => {
          this.profile = Object.assign({}, res.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    date_changed(ev) {
      this.filter_date = `${ev}-01`;
      this.data_loaded = false;
      this.get_profile();
    },
    employee_changed(ev) {
      this.$route.query.employee = ev;
      this.employee_id = ev;
      this.data_loaded = false;
      this.get_profile();
    },
  },

  beforeMount() {
    if (this.$route.query.employee) {
      this.get_profile();
    } else {
      if (this.$store.getters.user_role == "admin") {
        this.$router.push("/employees");
      } else {
        let emp_id = this.$store.getters.user_info.employee.id;
        this.$router.push({
          name: "employee_profile_page",
          query: {
            employee: emp_id,
          },
        });
        this.employee_id = emp_id;
        this.get_profile();
      }
    }
  },
};
</script>
