var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',{staticClass:"mb-10",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v(" جميل التحليلات "),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"-5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"primary"}},'v-btn',attrs,false),Object.assign({}, dialog,tooltip)),[_vm._v("اضافة")])]}}],null,true)},[_c('span',[_vm._v("اضافة")])])]}}])},[_c('v-card',[_c('v-card-title',[_vm._v(" اضافة تحليل ")]),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"اسم التحليل"}}),_c('v-select',{attrs:{"label":"شكل التحليل","items":['pie_chart', 'signal_cellular_alt']},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item))])]}}])}),_c('v-combobox',{attrs:{"label":"مقارنة بين","multiple":"","items":['one', 'two', 'three'],"chips":""}}),_c('v-text-field',{attrs:{"label":"وصف التحليل"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"}},[_vm._v("الفاء")]),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("حفظ")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.table.headers,"items":_vm.table.body,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.shape",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.shape))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":item}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":item}},[_vm._v("delete")])],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('zingchart',{attrs:{"data":_vm.chartData,"height":300,"width":600}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('zingchart',{attrs:{"data":_vm.chartData2,"series":_vm.mySeries,"height":300,"width":600}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('zingchart',{attrs:{"data":_vm.chartData3,"height":300,"width":600}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }