<template>
  <div class="header mb-5">
    <v-app-bar app color="transparent" flat height="75">
      <v-container class="py-0 fill-height">
        <div class="d-flex align-center">
          <v-tooltip bottom nudge-bottom="-5">
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon
                height="20"
                width="20"
                color="primary"
                v-on="on"
                v-bind="attrs"
                class="me-4"
                @click="$store.commit('toggleAsideMenu')"
              ></v-app-bar-nav-icon>
            </template>
            <span>Menu</span>
          </v-tooltip>
        </div>
        <v-spacer> </v-spacer>
        <v-img class="logo" src="@/assets/logo.png"></v-img>
        <v-spacer> </v-spacer>
        <div class="d-flex align-center">
          <v-btn
            small
            color="white"
            class="me-2"
            fab
            elevation="1"
            @click="toggleDarkMode()"
          >
            <v-icon> dark_mode </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                class="rounded-lg header-menu btn-drop-down black--text align-center d-block justify-start"
                elevation="1"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <v-avatar size="40">
                  <img
                    class="rounded-circle"
                    :src="$store.getters.user_info.employee.image"
                  />
                </v-avatar>
                <div class="d-none d-md-block">
                  <h5 class="black--text ms-2">
                    {{ $store.getters.user_info.employee.name }}
                  </h5>
                </div>
                <v-icon class="d-none d-md-block" right>
                  keyboard_arrow_down
                </v-icon>
              </v-btn>
            </template>
            <v-list dense class="menu-no-active">
              <v-list-item-group color="primary">
                <v-list-item
                  v-on="i == 1 ? { click: () => logout() } : {}"
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <!--/menu -->
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .language {
    width: 50px;
    font-size: 13px;
    .v-select__selections {
      font-weight: bold;
      font-size: 14px;
      padding: 0;
    }
    fieldset {
      border: none !important;
    }
    .v-input__icon {
      display: none;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0;
    }
  }
  //---- language

  .lang-item {
    min-height: 28px !important;
    font-size: 12px !important;
  }
}
</style>
<script>
export default {
  name: "AppHeader",
  data: () => ({
    lang: {
      val: {
        name: "Arbic",
        shortCut: "Ar",
        icon: "ar.png",
      },
      langs: [
        {
          id: 1,
          name: "Arbic",
          shortCut: "Ar",
          icon: "ar.png",
        },
        {
          id: 2,
          name: "English",
          shortCut: "En",
          icon: "en.png",
        },
      ],
    },
    items: [
      { text: "البروفيل", icon: "mdi-account" },
      { text: "تسجيل الخروج", icon: "logout" },
    ],
  }),
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logout() {
      let vm = this;
      this.$store.commit("logout");
      vm.$router.push({ name: "login" });
    },
  },
  computed: {},
};
</script>
