<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!tbody"> </v-skeleton-loader>

    <v-card v-if="tbody" elevation="0" outlined>
      <v-card-title>
        {{ keys.services_data_page_name }}
        <v-spacer></v-spacer>
        <v-btn
          :to="{ name: 'send_service_data_page' }"
          color="primary"
          depressed
          >{{ keys.services_data_page_btn_add }}</v-btn
        >
      </v-card-title>
      <!--/ title -->
      <v-divider></v-divider>

      <v-tabs class="mb-3" v-model="tabs" background-color="gray">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-model="tabs">المستلم</v-tab>
        <v-tab v-model="tabs">المفروض</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-data-table :items="tbody" mobile-breakpoint="0" :headers="headers">
            <template v-slot:[`item.company`]="{ item }">
              {{ item.company.name }}
            </template>
            <template v-slot:[`item.project`]="{ item }">
              {{ item.project.name }}
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{
                item.created_at.slice(
                  item.created_at.indexOf("T") + 1,
                  item.created_at.indexOf(".")
                )
              }}
              {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                small
                v-if="item.status == 0"
                v-text="keys.services_data_page_status_price_suggestion"
              ></v-chip>
              <!--/ chip -->
              <v-chip
                small
                color="warning"
                v-if="item.status == 1"
                v-text="keys.services_data_page_status_pending"
              ></v-chip>
              <!--/ chip -->
              <v-chip
                color="blue lighten-2"
                class="white--text"
                small
                v-if="item.status == 2"
                v-text="keys.services_data_page_status_on_way"
              ></v-chip>
              <v-chip
                color="success"
                small
                v-if="item.status == 3"
                v-text="keys.services_data_page_status_done"
              ></v-chip>
              <!--/ chip -->
              <v-chip
                color="error"
                small
                v-if="item.status == 4"
                v-text="keys.services_data_page_status_canceled"
              ></v-chip>
              <!--/ chip -->
            </template>
            <!--/ services -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                :to="{
                  name: 'send_service_data_page',
                  query: {
                    id: item.order_id,
                    project: item.project.id,
                    contact: item.company.id,
                  },
                }"
                small
                icon
              >
                <v-icon small>edit</v-icon>
              </v-btn>
              <!--/ block company  -->
              <v-btn @click="get_deletd_id(item.order_id)" small icon>
                <v-icon small>delete</v-icon>
              </v-btn>
              <!--/ delete company -->
            </template>
          </v-data-table>
          <!--/card text -->
        </v-tab-item>
        <v-tab-item>
          table
        </v-tab-item>
      </v-tabs-items>
      <!--/tabs -->
    </v-card>
    <!--/card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"
          >{{ keys.services_data_page_delete_title }}
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            :disabled="delete_loader"
            @click="delete_company()"
            depressed
            color="error"
            >{{ keys.services_data_page_delete_btn }}</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>{{
            keys.services_data_page_delete_close
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
  <!--/container -->
</template>

<script>
export default {
  name: "services_data",
  data() {
    return {
      tabs: 0,
      delete_id: null,
      delete_dialog: false,
      delete_loader: false,
      post_contacts_config: {
        url: `/company/serviceData`,
        method: "get",
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.keys.services_data_page_order_no,
          value: "order_id",
          align: "center",
        },
        {
          text: this.keys.services_data_page_copmay_name,
          value: "company",
          align: "center",
        },
        {
          text: this.keys.services_data_page_project_name,
          value: "project",
          align: "center",
        },
        {
          text: this.keys.services_data_page_creation_date,
          value: "created_at",
          align: "center",
        },
        {
          text: this.keys.services_data_page_status,
          value: "status",
          align: "center",
        },
        {
          text: this.keys.services_data_page_actions,
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
    tbody() {
      return this.$store.state.pages_data.send_data || null;
    },
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: this.post_contacts_config,
          get: "send_data",
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    delete_company() {
      this.delete_loader = true;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "delete",
            url: `company/serviceData/order/${this.delete_id}`,
          },
        })
        .then((res) => {
          this.delete_dialog = false;
          this.delete_loader = false;
          this.get_data();
        })
        .catch((res) => {
          console.log(res.response);
        });
    },
    get_deletd_id(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
  },
  mounted() {
    // if (!this.$store.state.companies) {

    // }
    this.get_data();
  },
  created() {
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter") {
        vm.delete_company();
      }
    };
  },
};
</script>
