<template>
  <div>
    <v-row v-if="data_loaded">
      <v-col cols="12" sm="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="keys.employee_profile_page_date"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            type="month"
            @input="(menu = false), $emit('date_changed', $event)"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!--/ col (select date) -->
      <v-col
        cols="12"
        sm="4"
        v-if="this.$store.getters.user_role == 'employee'"
      >
        <v-select
          item-value="company_id"
          item-text="name"
          :loading="companies.length == 0"
          hide-details
          :items="companies"
          @input="switch_company($event)"
          :label="keys.employee_profile_page_company"
          :value="$store.getters.company_id"
        ></v-select>
      </v-col>
      <!--/ col (select company) -->
      <v-col cols="12" sm="4" v-if="this.$store.getters.user_role == 'admin'">
        <v-select
          @input="$emit('employee_changed', $event)"
          :items="employees"
          item-value="id"
          v-model="employee_obj"
        >
          <template slot="label">
            <strong class="red--text">*</strong>
            اختيار الموظف
          </template>
          <template v-slot:selection="{ item }">
            <span>{{ item.name }}</span>
          </template>
          <template v-slot:item="{ item }">
            <div class="d-flex align-center">
              <v-avatar size="45">
                <img :src="item.image" alt="" />
              </v-avatar>
              <div class="ms-3">
                <h4 style="font-size: 14px">
                  {{ item.name }}
                </h4>
                <div class="d-flex align-center justify-space-between">
                  <p style="font-size: 12px">
                    {{ item.phone }}
                  </p>
                  <span>|</span>
                  <p style="font-size: 12px">
                    {{ item.id_number }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </v-select>
      </v-col>
      <!--/ col (select employee) -->
    </v-row>
    <!--/ row -->
  </div>
</template>

<script>
export default {
  name: "ProfileFilter",
  props: ["data_loaded", "keys"],
  data() {
    return {
      company: null,
      employee_obj: {},
      employee_id: null,
      employees: [],
      menu: false,
      date: new Date().toISOString().substr(0, 7),
      companies: [],
      company: null,
    };
  },
  methods: {
    get_employees() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/project/times/employees",
            method: "get",
          },
        })
        .then((res) => {
          let employees = res.data;
          let find_emp = employees.find(
            (e) => (e.id = this.$route.query.employee)
          );
          this.employees = Object.assign([], employees);
          this.employee_obj = find_emp;
        });
    },
    company_list() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "company/profile/company-lists",
            method: "get",
          },
        })
        .then((res) => {
          this.companies = Object.assign([], res.data);
        });
    },
    switch_company(ev) {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `company/auth/switch/${ev}`,
            method: "get",
          },
        })
        .then((res) => {
          const user = JSON.stringify(res.data);
          this.$store.commit("user_obj", user);
          this.$emit('get_profile');
        });
    },
  },
  beforeMount() {
    if (this.$store.getters.user_role == "employee") {
      this.company_list();
    } else if (this.$store.getters.user_role == "admin") {
      this.get_employees();
    }
  },
};
</script>
