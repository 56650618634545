<template>
  <v-container>
    <section class="mb-5">
      <v-card elevation="0">
        <v-card-text>
          <v-row class="align-center justify-center justify-md-start">
            <v-col md="6" cols="12">
              <h2 class="red--text live-title">
                <v-icon class="point" color="red">circle</v-icon>
                <span> تكاليف ودفعات حتي هذه الدقيقة في بث مباشر</span>
              </h2>
            </v-col>

            <v-col
              md="6"
              cols="12"
              class="justify-center justify-md-end d-flex"
            >
              <ul class="list-unstyled chart-list chart">
                <li class="red" v-for="(a, i) in new Array(16)" :key="i"></li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>

    <section>
      <v-row>
        <v-col
          lg="3"
          md="6"
          cols="6"
          v-for="(a, v) in analayze"
          :key="v"
        >
          <v-card  elevation="0" class="analayze rounded-lg">
            <v-card-text>
              <div class="d-flex justify-space-between mb-5">
                <h2 class="h3 analayze__title" :style="{color: a.color}"><span class='analayze__currency'>₪</span>{{a.money}}</h2>
                <v-icon class="analayze__icon" :style="{color: a.color}"> circle</v-icon>
              </div>
              <div class="d-flex justify-space-between">
                <span class="analayze__percent">{{ a.percent }}%</span>
                <h2 class="analayze__title analayze__title--text">{{ a.text }}</h2>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <!--/end analazye carousel -->
    <v-card loading class="mb-10 mt-5" outlined elevation="0">
      <v-card-title>
        تكاليف البناء حتي هذه الدقيقة
        <v-spacer></v-spacer>
      </v-card-title>
      <v-simple-table class="pb-10">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" v-for="(item, i) in table.headers" :key="i">
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in table.tbody" :key="i">
              <th class="gray fixedColumns">{{ item.name }}</th>
              <td v-for="(data, a) in item.data" :key="a">
                {{ data }}
              </td>
            </tr>
            <tr>
              <th class="gray fixedColumns">
                {{ table.tfoot.name }}
              </th>
              <td v-for="(data, a) in table.tfoot.data" :key="a">
                <span
                  class="font-weight-bold"
                  :class="[
                    { 'success--text': data.plus },
                    { 'red--text': !data.plus },
                  ]"
                >
                  {{ data.plus ? "" : "-" }}{{ data.num }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
  <!-- <div class='page-wrap'>

    </div> -->
</template>

<style lang="scss" scoped>

tr:last-of-type td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.chart {
  position: relative;
  height: 40px;
  width: 170px;
  list-style: none;
  li {
    border-radius: 5px;
    width: 6px;
    height: 40px;
    position: absolute;
    animation: animation, toggleHeight 0.6s ease infinite normal none;
    bottom: 0;
  }
}

.point {
  opacity: 0.2;
  animation: fadeToggle 0.6s ease 0s infinite normal none;
}

@for $i from 1 through 16 {
  .chart li:nth-child(#{$i}) {
    left: ($i * 10px);
  }
}

@for $i from 1 through 16 {
  .chart li:nth-child(3n + #{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@keyframes toggleHeight {
  0%,
  100% {
    height: 5px;
  }
  50% {
    height: 40px;
  }
}

@keyframes fadeToggle {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}
.live-title {
  @media (max-width: 991px) {
    font-size: 15px;
  }
}
</style>
<script>
export default {
  name: "Live",
  data() {
    return {
      color: "transparent",
      selectedItem: 2,
      analayze: [
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#00ffe7",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#ffa500",

          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#188320",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "red",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#0997b7",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#095200",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
          color: "#857c17",
          money: 77,
        },
        {
          text: "المشاريع المفتوحة",
          percent: 30,
         color: "#188320",
          money: 77,
        },
      ],
      items: [
        { text: "اجمالي حسب ستاتوس", val: "20" },
        { text: "اجمالي حسب ستاتوس", val: "20" },
        { text: "الأجمالي", val: "20" },
      ],
      // search: "",
      table: {
        headers: [
          { text: " نوع	" },
          { text: "مبني 1	" },
          { text: "مبني 2	" },
          { text: "مبني 3	" },
          { text: "مبني 4	" },
          { text: "مبني 5	" },
        ],
        tbody: [
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "البناء حسب العقد	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "البناء حسب العقد	",
            data: [100, 100, 100, 100, 100],
          },
          {
            name: "متر مربع للمبني	",
            data: [100, 100, 100, 100, 100],
          },
        ],
        tfoot: {
          name: "البناء حسب العقد	",
          data: [
            { plus: true, num: 100 },
            { plus: false, num: 100 },
            { plus: true, num: 100 },
            { plus: false, num: 100 },
            { plus: true, num: 100 },
          ],
        },
      },
    };
  },
  methods: {
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },
  },
};
</script>
