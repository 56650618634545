<template>
  <div class="supplier">
    <v-container>
      <!-- <Stats></Stats> -->
      <v-card class="mb-10 mt-5" outlined elevation="0">
        <v-card-title>
          {{ keys.payments_page_title }}
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed small @click="addDialog = true">
            {{ keys.payments_page_add_btn }}
          </v-btn>
          <From
            :addDialog="addDialog"
            ref="pay_form"
            :payment_edit_item="payment_edit_item"
            @close_form="close_form($event)"
            :attachments="attachments"
            :keys="keys"
            @open_attachments="dialog = true"
          ></From>
          <!--/ form -->

          <!--/ dialog -->
        </v-card-title>
        <!--/card title -->
        <Table
          @edit_payment="edit_payment($event)"
          ref="payment_table"
          :keys="keys"
        ></Table>
      </v-card>
      <!--/card -->
      <Attachments
        :keys="keys"
        @attachments_saved="attachments_saved($event)"
        @close_attachments="dialog = false"
        :dialog="dialog"
        :files="files"
      ></Attachments>

      <v-snackbar type="success" left v-model="snackbar">
        <p>{{ keys.payments_page_success_msg }}</p>
        <template v-slot:action>
          <v-icon color="success" class="ms-3">check_circle</v-icon>
        </template>
      </v-snackbar>
    </v-container>
    <!--/contaioner -->
  </div>
</template>

<style lang="scss" scoped></style>
<script>
import From from "@/components/payments/form";
// import Stats from "@/components/payments/stats";
import Table from "@/components/payments/table";
import Attachments from "@/components/payments/attachments";
export default {
  name: "payments",
  data() {
    return {
      files:[],
      payment_edit_item: null,
      attachments: [],
      addDialog: false,
      dialog: false,
      snackbar:false,
      table: {
        headers: [
          { text: " الوقت	" },
          { text: "المبلغ	" },
          { text: "رقم الدفعة" },
          { text: "طريق الدفع" },
          { text: "اثبات" },
          { text: "مبني ملاحظات		" },
          { text: "حالة الدفعة	" },
          { text: "حالة الفاتورة	" },
          { text: "اجراءات" },
        ],
        tbody: [
          {
            date: "04:11:2021 - 15:34",
            money: 100,
            payNumber: 132,
            payMethod: "paypal",
            proof: {
              prop: true,
              link: "",
              pdf: "",
            },
            note: "lorem",
            status: 0,
            invoiceStatus: 0,
          },
          {
            date: "04:11:2021 - 15:34",
            money: 42,
            payNumber: 236,
            payMethod: "paypal",
            proof: {
              prop: false,
              link: "",
              pdf: "",
            },
            note: "lorem",
            status: 1,
            invoiceStatus: 1,
          },
        ],
      },
    };
  },
  computed: {
    keys() {
      return this.$store.getters.page_trans_keys(this.$route.name).keys || [];
    },
  },
  components: {
    From,
    // Stats,
    Attachments,
    Table,
  },
  methods: {
    attachments_saved(ev) {
      this.attachments = Object.assign([], ev);
    },
    edit_payment(ev) {
      this.files = Object.assign(ev.files);
      console.log(ev.files)
      this.payment_edit_item = Object.assign(ev);
      this.addDialog = true;
    },
    close_form(ev) {
      this.addDialog = false;
      this.payment_edit_item = Object.assign(
        {},
        {
          type: 1,
          reciver_id: null,
          amount: 0,
          payment_method: "",
          invoice: null,
          notes: {},
          attachments: [],
          files:[]
        }
      );
      this.attachments = [];
      if (ev == "store") {
        this.snackbar = true;
        this.$refs.payment_table.get_payments();
      }
    },
  },
};
</script>
